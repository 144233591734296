export const EMAIL_VERIFICATION_START = "EMAIL_VERIFICATION_START";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAILURE = "EMAIL_VERIFICATION_FAILURE";

export const SIGN_IN_START = "SIGN_IN_START";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const LOAD_USER_START = "LOAD_USER_START";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILURE = "LOAD_USER_FAILURE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const RESET_PASSWORD_CONFIRMATION_START =
  "RESET_PASSWORD_CONFIRMATION_START";
export const RESET_PASSWORD_CONFIRMATION_SUCCESS =
  "RESET_PASSWORD_CONFIRMATION_SUCCESS";
export const RESET_PASSWORD_CONFIRMATION_FAILURE =
  "RESET_PASSWORD_CONFIRMATION_FAILURE";

export const VERIFY_OTP_START = "VERIFY_OTP_START";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const RESEND_VERIFY_OTP_START = "RESEND_VERIFY_OTP_START";
export const RESEND_VERIFY_OTP_SUCCESS = "RESEND_VERIFY_OTP_SUCCESS";
export const RESEND_VERIFY_OTP_FAILURE = "RESEND_VERIFY_OTP_FAILURE";
