// project imports
import * as LicenseType from "./license.types";

const INITIAL_STATE = {
  error: null,
  loading: false,
  licenseInfo: null,
};

const licenseReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LicenseType.FETCH_LICENSE_INFO_START:
    case LicenseType.EXTEND_SERVER_LICENSE_START:
    case LicenseType.UPLOAD_LICENSE_START:
      return {
        ...state,
        loading: true,
      };

    case LicenseType.FETCH_LICENSE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        licenseInfo: payload,
      };

    case LicenseType.EXTEND_SERVER_LICENSE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case LicenseType.UPLOAD_LICENSE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case LicenseType.FETCH_LICENSE_INFO_FAILURE:
    case LicenseType.UPLOAD_LICENSE_FAILURE:
    case LicenseType.EXTEND_SERVER_LICENSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default licenseReducer;
