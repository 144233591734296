export const CREATE_JOB_START = "CREATE_JOB_START";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAILURE = "CREATE_JOB_FAILURE";

export const FETCH_JOBS_START = "FETCH_JOBS_START";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";

export const DELETE_JOB_START = "DELETE_JOB_START";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILURE = "DELETE_JOB_FAILURE";

export const UPDATE_JOB_START = "UPDATE_JOB_START";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAILURE = "UPDATE_JOB_FAILURE";

export const RUN_JOB_START = "RUN_JOB_START";
export const RUN_JOB_SUCCESS = "RUN_JOB_SUCCESS";
export const RUN_JOB_FAILURE = "RUN_JOB_FAILURE";

export const FETCH_ALL_JOB_RUNS_START = "FETCH_ALL_JOB_RUNS_START";
export const FETCH_ALL_JOB_RUNS_SUCCESS = "FETCH_ALL_JOB_RUNS_SUCCESS";
export const FETCH_ALL_JOB_RUNS_FAILURE = "FETCH_ALL_JOB_RUNS_FAILURE";

export const FETCH_JOBS_BY_JOB_ID_START = "FETCH_JOBS_BY_JOB_ID_START";
export const FETCH_JOBS_BY_JOB_ID_SUCCESS = "FETCH_JOBS_BY_JOB_ID_SUCCESS";
export const FETCH_JOBS_BY_JOB_ID_FAILURE = "FETCH_JOBS_BY_JOB_ID_FAILURE";
