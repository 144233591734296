// project imports
import * as QueryEditorTypes from "./queryeditor.types";

import axiosConfig from "../../config/axiosConfig";

import { openAlert } from "../alert/alert.actions";
import _ from "lodash";

export const pushQueryToDatabricks =
  (id, tabId, query, dialect, selectedDatabase, cb) =>
  async (dispatch, getState) => {
    const {
      queryEditor: { limit, activeTab },
    } = getState();

    const currentTab = getState().queryEditor.queryResults[activeTab];
    const offset = currentTab.page * limit;
    try {
      dispatch({
        type: QueryEditorTypes.PUSH_QUERY_TO_DATABRICKS_START,
      });

      let url = "/queryeditor/push/";
      const queryParams = [];

      if (limit) {
        queryParams.push(`limit=${limit}`);
      }

      if (offset) {
        queryParams.push(`offset=${offset}`);
      }

      if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
      }

      const { data } = await axiosConfig.post(url, {
        connection_profile_id: id,
        tab_id: tabId,
        sql_query: query,
        dialect,
        database_name: selectedDatabase,
      });
      console.log("connection succes", data);

      dispatch({
        type: QueryEditorTypes.PUSH_QUERY_TO_DATABRICKS_SUCCESS,
        payload: data,
      });

      dispatch(
        onChangeApplyQueryTabValue(activeTab, [
          { key: "runId", value: data?.databricks_response?.run_id },
        ])
      );

      if (cb) {
        cb(data?.databricks_response?.run_id);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: QueryEditorTypes.PUSH_QUERY_TO_DATABRICKS_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      const errors = error.response?.data?.error || {};

      const errorMessages =
        Object.keys(errors)
          .map((key) => `${key}: ${errors[key]}`)
          .join(", ") || "An unexpected error occurred";

      dispatch(openAlert(`${errorMessages}`, "error"));
    }
  };

export const getCompletedSaveQuery =
  (id, index, cb) => async (dispatch, getState) => {
    const {
      queryEditor: { limit, activeTab },
    } = getState();

    const currentTab = getState().queryEditor.queryResults[activeTab];
    const offset = currentTab.page * limit;
    try {
      dispatch({
        type: QueryEditorTypes.GET_COMPLETED_SAVE_QUERY_START,
      });

      let url = "/queryeditor/get-saved-query-result/";
      const queryParams = [];

      queryParams.push(`id=${id}`);

      if (limit) {
        queryParams.push(`limit=${limit}`);
      }

      if (offset) {
        queryParams.push(`offset=${offset}`);
      }

      const { data } = await axiosConfig.post(url, {
        connection_profile_id: id,
        id: id,
      });
      console.log("completed saved query", data);

      // dispatch({
      //   type: QueryEditorTypes.GET_COMPLETED_SAVE_QUERY_SUCCESS,
      //   payload: { data, index },
      // });

      dispatch(
        onChangeApplyQueryTabValue(activeTab, [
          { key: "runId", value: data?.run_id },
        ])
      );

      if (cb) {
        cb(data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: QueryEditorTypes.GET_COMPLETED_SAVE_QUERY_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      const errors = error.response?.data?.error || {};

      const errorMessages =
        Object.keys(errors)
          .map((key) => `${key}: ${errors[key]}`)
          .join(", ") || "An unexpected error occurred";

      dispatch(openAlert(`${errorMessages}`, "error"));
    }
  };

export const queryEditorCancelExecution = (RunId, cb) => async (dispatch) => {
  try {
    dispatch({
      type: QueryEditorTypes.QUERY_EDITOR_CANCEL_EXECUTION_START,
    });

    const { data } = await axiosConfig.post(`/queryeditor/cancel-execution/`, {
      run_id: RunId,
    });
    dispatch({
      type: QueryEditorTypes.QUERY_EDITOR_CANCEL_EXECUTION_SUCCESS,
      payload: data,
    });
    if (cb) {
      cb();
    }
    dispatch(openAlert("Query execution cancelled.", "success"));
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.QUERY_EDITOR_CANCEL_EXECUTION_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};

export const fetchAllQueryConnectionList = () => async (dispatch) => {
  try {
    dispatch({
      type: QueryEditorTypes.FETCH_ALL_QUERY_CONNECTION_LIST_START,
    });

    const { data } = await axiosConfig.get(
      `/connections/connection/query-connection-list/`
    );
    dispatch({
      type: QueryEditorTypes.FETCH_ALL_QUERY_CONNECTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.FETCH_ALL_QUERY_CONNECTION_LIST_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const saveQueryOnDatabricks = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: QueryEditorTypes.SAVE_QUERY_ON_DATABRICKS_START,
    });

    const { data } = await axiosConfig.post(
      `/queryeditor/save-query/`,
      formData
    );
    dispatch({
      type: QueryEditorTypes.SAVE_QUERY_ON_DATABRICKS_SUCCESS,
      payload: data,
    });
    dispatch(openAlert("Query saved successfully", "success"));
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.SAVE_QUERY_ON_DATABRICKS_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};

export const getSavedQueryById = (id, cb) => async (dispatch) => {
  try {
    dispatch({
      type: QueryEditorTypes.GET_SAVED_QUERY_BY_ID_START,
    });

    const { data } = await axiosConfig.post(
      `/queryeditor/get-savedquery/${id}`
    );
    dispatch({
      type: QueryEditorTypes.GET_SAVED_QUERY_BY_ID_SUCCESS,
      payload: data,
    });
    console.log("individual saved query", data);
    if (cb) {
      cb(data);
    }
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.GET_SAVED_QUERY_BY_ID_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};

export const updateDateFormatTogglerQuery = (column) => ({
  type: QueryEditorTypes.UPDATE_DATE_FORMAT_TOGGLER_QUERY,
  payload: { column1: column },
});

export const onChangeApplyRecentQueriesFilter = (key, value) => ({
  type: QueryEditorTypes.ON_CHANGE_APPLY_RECENT_QUERY_FILTER,
  payload: { key, value },
});

export const fetchRecentQueries = () => async (dispatch, getState) => {
  const {
    queryEditor: {
      recentQueries: { searchParams, limit, offset },
    },
  } = getState();

  try {
    dispatch({
      type: QueryEditorTypes.FETCH_RECENT_QUERIES_START,
    });

    const queryParams = new URLSearchParams();

    if (searchParams) {
      queryParams.append("search", searchParams);
    }

    if (limit) {
      queryParams.append("limit", limit);
    }
    if (offset) {
      queryParams.append("offset", offset);
    }

    const url = `/queryeditor/recent-queries/?${queryParams.toString()}`;

    let method = "get";
    const { data } = await axiosConfig.request({
      method,
      url,
    });

    dispatch({
      type: QueryEditorTypes.FETCH_RECENT_QUERIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.FETCH_RECENT_QUERIES_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const deleteRecentQueries = (formData, cb) => async (dispatch) => {
  try {
    dispatch({
      type: QueryEditorTypes.DELETE_RECENT_QUERIES_START,
    });

    const { data } = await axiosConfig.post(
      `/queryeditor/delete-recentqueries/`,
      {
        ids: formData,
      }
    );
    dispatch({
      type: QueryEditorTypes.DELETE_RECENT_QUERIES_SUCCESS,
      payload: data,
    });
    dispatch(openAlert("Recent queries deleted successfully", "success"));
    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.DELETE_RECENT_QUERIES_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(openAlert("Failed to delete recent queries", "error"));
  }
};

export const onChangeApplySavedQueriesFilter = (keys, values) => ({
  type: QueryEditorTypes.ON_CHANGE_APPLY_SAVED_QUERY_FILTER,
  payload: { keys, values },
});

export const updateQueryTableColumn = (tableType, columns) => ({
  type: QueryEditorTypes.UPDATE_QUERY_TABLE_COLUMN,
  payload: {
    tableType,
    columns,
  },
});

export const updateSelectedQueryTableRow = (selctedTable, rows) => ({
  type: QueryEditorTypes.UPDATE_QUERY_TABLE_SELECTED_ROW,
  payload: {
    selctedTable,
    rows,
  },
});

export const fetchSavedQueries = () => async (dispatch, getState) => {
  const {
    queryEditor: {
      savedQueries: { searchParams, limit, offset },
    },
  } = getState();

  try {
    dispatch({
      type: QueryEditorTypes.FETCH_SAVED_QUERIES_START,
    });

    const queryParams = new URLSearchParams();

    if (searchParams) {
      queryParams.append("search", searchParams);
    }

    if (limit) {
      queryParams.append("limit", limit);
    }
    if (offset) {
      queryParams.append("offset", offset);
    }

    const url = `/queryeditor/saved-queries/?${queryParams.toString()}`;

    let method = "get";
    const { data } = await axiosConfig.request({
      method,
      url,
    });

    dispatch({
      type: QueryEditorTypes.FETCH_SAVED_QUERIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.FETCH_SAVED_QUERIES_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const deleteSavedQueries = (formData, cb) => async (dispatch) => {
  try {
    dispatch({
      type: QueryEditorTypes.DELETE_SAVED_QUERIES_START,
    });

    const { data } = await axiosConfig.post(
      `/queryeditor/delete-savedqueries/`,
      {
        ids: formData,
      }
    );
    dispatch({
      type: QueryEditorTypes.DELETE_SAVED_QUERIES_SUCCESS,
      payload: data,
    });
    dispatch(openAlert("Saved queries deleted successfully", "success"));
    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.DELETE_SAVED_QUERIES_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(openAlert("Failed to delete saved queries", "error"));
  }
};

export const updateSavedQueries = (id, formData, cb) => async (dispatch) => {
  try {
    dispatch({
      type: QueryEditorTypes.UPDATE_SAVED_QUERIES_START,
    });

    const { data } = await axiosConfig.patch(
      `/queryeditor/update-savedquery/${id}`,

      formData
    );
    dispatch({
      type: QueryEditorTypes.UPDATE_SAVED_QUERIES_SUCCESS,
      payload: data,
    });

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.UPDATE_SAVED_QUERIES_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(openAlert("Failed to update saved queries", "error"));
  }
};

export const addQueryEditorTab = (payload) => ({
  type: QueryEditorTypes.ADD_QUERY_EDITOR_TAB,
  payload: payload,
});

export const deleteQueryEditorTab = (payload) => ({
  type: QueryEditorTypes.REMOVE_QUERY_EDITOR_TAB,
  payload: payload,
});

export const onChangeApplyQueryTabValue = (index, updates) => ({
  type: QueryEditorTypes.ON_CHANGE_APPLY_QUERY_TAB_VALUE,
  payload: { index, updates },
});

// export const onChangeQueryTabActive = (payload) => ({
//   type: QueryEditorTypes.ON_CHANGE_QUERY_TAB_ACTIVE,
//   payload: payload,
// });
export const onChangeQueryTabActive = (payload, cb) => {
  return (dispatch) => {
    dispatch({
      type: QueryEditorTypes.ON_CHANGE_QUERY_TAB_ACTIVE,
      payload: payload,
    });

    if (cb) {
      cb();
    }
  };
};

export const onChangeApplyQueryFilter = (key3, value3) => ({
  type: QueryEditorTypes.ON_CHANGE_APPLY_QUERY_FILTER,
  payload: { key3, value3 },
});

export const fetchDatabaseOrSchemaFromConnectionProfile =
  (id) => async (dispatch) => {
    try {
      dispatch({
        type: QueryEditorTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START,
      });

      const { data } = await axiosConfig.get(`/queryeditor/${id}/databases/`);

      dispatch({
        type: QueryEditorTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: QueryEditorTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const fetchResultsFromDatabaseOrSchema =
  (id, name) => async (dispatch) => {
    try {
      dispatch({
        type: QueryEditorTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START,
      });

      const { data } = await axiosConfig.get(
        `/queryeditor/${id}/database/${name}`
      );

      dispatch({
        type: QueryEditorTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: QueryEditorTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const fetchTableColumnFromTableView =
  (id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_VIEW_START,
      });

      const { data } = await axiosConfig.post(
        `/queryeditor/${id}/column-from-table/`,
        formData
      );

      dispatch({
        type: QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_VIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_VIEW_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const fetchTableColumnFromTable = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_START,
    });

    const { data } = await axiosConfig.post(
      `/queryeditor/${id}/column-from-table/`,
      formData
    );

    dispatch({
      type: QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};

export const fetchTableViewFromSchema = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: QueryEditorTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_START,
    });

    const { data } = await axiosConfig.post(
      `/queryeditor/${id}/table-views/`,
      formData
    );

    dispatch({
      type: QueryEditorTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QueryEditorTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};
