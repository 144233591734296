// project imports
import { addEdge, applyEdgeChanges, applyNodeChanges } from "reactflow";
import * as PipelineTypes from "./pipeline.types";
import _ from "lodash";

const INITIAL_STATE = {
  piplineInfo: {
    name: "",
    folderId: null,
  },
  sources: {
    loading: false,
    error: null,
    list: [],
  },
  transformations: {
    loading: false,
    error: null,
    list: [],

    join: {
      nodes: [],
      edges: [],
    },
  },
  sink: {
    loading: false,
    error: null,
    list: [],
  },
  additional: {
    loading: false,
    error: null,
    list: [],
  },
  nodes: [],
  edges: [],
  pipRes: {
    limit: 20,
    offset: 0,
    page: 0,
    isSessionIdFetching: false,
    pipelineSessionId: null,
    panelDisabled: false,
    count: 0,
    isPipelineFetching: false,
    pipelines: {},
    loading: false,
    error: null,
    pipelineId: null,
    engineId: null,
    title: "",
    folderPath: null,
    folderId: null,
    openAddNew: false,
    recent: {},
    isRecentFetching: false,
    selectedPipeline: [],
    runId: null,
    pipelineByDate: [],
    executionBySession: [],
    isPipelineByDateFetching: false,
    isExecutionBySessionFetching: false,
    log: [],
    isLogFetching: false,
    isProfilingResultFetching: false,
    profilingResult: {},
    isProfilingLineChartFetching: false,
    isProfilingBarChartFetching: false,
    profilingBarChart: {},
    profilingLineChart: {},
    sharedList: [],
    isSharedListFetching: false,
  },
  pipDataRetention: {
    id: null,
    connectionProfile: {},
    queryConnectionList: { loading: false, error: null, list: [] },
    schemas: [],
    selectedSchema: "",
    isDataFetching: false,
    selectedDatabase: "",
    database: [],
    selectedFetchedSchema: "",
    fetchedSchema: [],
    loading: false,
    error: null,
    data: {},
    tempData: {},
    nextType: "",
  },
  socketResponse: [],
  nodeState: [],

  uuid: null,

  pipeLineResults: {
    loading: false,
    error: null,
    tables: [{ uuid: null, name: "", columns: [], rows: [] }],
  },
};

const pipelineReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PipelineTypes.GET_SHARED_LIST_START:
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isSharedListFetching: true,
        },
      };
    case PipelineTypes.GET_SHARED_LIST_SUCCESS:
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          sharedList: payload,
          isSharedListFetching: false,
        },
      };
    case PipelineTypes.GET_SHARED_LIST_FAILURE:
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          error: payload,
          isSharedListFetching: false,
        },
      };

    case PipelineTypes.GET_PIPELINE_SESSION_ID_START:
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isSessionIdFetching: true,
        },
      };
    case PipelineTypes.GET_PIPELINE_SESSION_ID_SUCCESS:
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          pipelineSessionId: payload.pipeline_uuid,
          isSessionIdFetching: false,
        },
      };
    case PipelineTypes.GET_PIPELINE_SESSION_ID_FAILURE:
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          error: payload,
          isSessionIdFetching: false,
        },
      };

    case PipelineTypes.FETCH_PROFILING_LINE_CHART_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isProfilingLineChartFetching: true,
        },
      };
    }
    case PipelineTypes.FETCH_PROFILING_LINE_CHART_SUCCESS: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isProfilingLineChartFetching: false,
          profilingLineChart: payload,
        },
      };
    }
    case PipelineTypes.FETCH_PROFILING_LINE_CHART_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isProfilingLineChartFetching: false,
          error: payload,
        },
      };
    }
    case PipelineTypes.FETCH_PROFILING_RESULT_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isProfilingResultFetching: true,
        },
      };
    }
    case PipelineTypes.FETCH_PROFILING_RESULT_SUCCESS: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isProfilingResultFetching: false,
          profilingResult: payload,
        },
      };
    }

    case PipelineTypes.FETCH_PROFILING_RESULT_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isProfilingResultFetching: false,
          error: payload,
        },
      };
    }
    case PipelineTypes.FETCH_ALL_PIPELINE_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isPipelineFetching: true,
        },
      };
    }
    case PipelineTypes.FETCH_ALL_PIPELINE_SUCCESS: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isPipelineFetching: false,
          pipelines: payload,
        },
      };
    }
    case PipelineTypes.FETCH_ALL_PIPELINE_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isPipelineFetching: false,
          error: payload,
        },
      };
    }

    case PipelineTypes.GET_PIPELINE_RESULTS_START: {
      return {
        ...state,
        pipeLineResults: {
          ...state.pipeLineResults,
          loading: true,
        },
      };
    }
    case PipelineTypes.GET_PIPELINE_RESULTS_SUCCESS: {
      const data = action.payload;
      const tablesTemp = Object.entries(data).map(([key, value]) => ({
        name: key,
        columns: value.column_name.map((col, index) => ({
          field: col,
          id: index + 1,
        })),
        rows: value.data.map((row) =>
          Object.fromEntries(
            value.column_name.map((col, index) => [col, row[index]])
          )
        ),
      }));
      return {
        ...state,
        pipeLineResults: {
          ...state.pipeLineResults,
          tables: tablesTemp,
          loading: false,
        },
      };
    }

    case PipelineTypes.SET_PIPEPINE_RESULTS_VALUE:
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };

    case PipelineTypes.GET_PIPELINE_RESULTS_FAILURE: {
      return {
        ...state,
        pipeLineResults: {
          ...state.pipeLineResults,
          loading: false,
          error: payload,
        },
      };
    }

    case PipelineTypes.FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isLogFetching: true,
        },
      };
    }
    case PipelineTypes.FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_SUCCESS: {
      return {
        ...state,
        socketResponse: payload.execution_log,
        pipRes: {
          ...state.pipRes,
          log: payload,
          isLogFetching: false,
        },
      };
    }
    case PipelineTypes.FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isLogFetching: false,
          error: payload,
        },
      };
    }
    case PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_SESSION_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isExecutionBySessionFetching: true,
        },
      };
    }
    case PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_SESSION_SUCCESS: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          executionBySession: payload,
          isExecutionBySessionFetching: false,
        },
      };
    }
    case PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_SESSION_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isExecutionBySessionFetching: false,
          error: payload,
        },
      };
    }
    case PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_DATE_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isPipelineByDateFetching: true,
        },
      };
    }
    case PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_DATE_SUCCESS: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          pipelineByDate: payload,
          isPipelineByDateFetching: false,
        },
      };
    }
    case PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_DATE_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isPipelineByDateFetching: false,
          error: payload,
        },
      };
    }
    case PipelineTypes.CANCEL_PIPELINE_RUN_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isCanceling: true,
        },
      };
    }
    case PipelineTypes.CANCEL_PIPELINE_RUN_SUCCESS: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isCanceling: false,
          panelDisabled: false,
        },
      };
    }
    case PipelineTypes.CANCEL_PIPELINE_RUN_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isCanceling: false,
        },
      };
    }
    case PipelineTypes.FETCH_SELECTED_PIPELINE_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isSinglePipelineFetching: true,
        },
      };
    }
    case PipelineTypes.FETCH_SELECTED_PIPELINE_SUCCESS: {
      const cleanNodeData =
        payload?.node_data?.filter((nd) => nd !== null) ?? [];
      const nextEdges =
        cleanNodeData[cleanNodeData.length - 1]?.data?.prevState?.edges ?? [];
      const nextNodes = cleanNodeData ?? [];
      return {
        ...state,
        edges: nextEdges,
        nodes: nextNodes,

        pipRes: {
          ...state.pipRes,
          pipelineId: payload.id,
          title: payload.title,
          folderPath: payload.folder_path,
          folderId: payload.folder,
          isSinglePipelineFetching: false,
        },
      };
    }
    case PipelineTypes.FETCH_SELECTED_PIPELINE_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isSinglePipelineFetching: false,
          error: payload,
        },
      };
    }
    case PipelineTypes.FETCH_RECENT_PIPELINE_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          isRecentFetching: true,
        },
      };
    }

    case PipelineTypes.FETCH_RECENT_PIPELINE_SUCCESS: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          recent: payload,
          isRecentFetching: false,
        },
      };
    }
    case PipelineTypes.FETCH_RECENT_PIPELINE_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          error: payload,
          isRecentFetching: false,
        },
      };
    }
    case PipelineTypes.CLEAN_SOCKET_RESPONSE: {
      return {
        ...state,
        socketResponse: [],
      };
    }
    case PipelineTypes.UPDATE_SOCKET_RESPONSE: {
      const { data } = payload;
      return {
        ...state,
        socketResponse: [...state.socketResponse, data],
      };
    }
    case PipelineTypes.ON_CHANGE_APPLY_PIPELINE_RESPONSE: {
      const { updates } = payload;
      const newPipRes = { ...state.pipRes };

      updates.forEach(({ key, value }) => {
        newPipRes[key] = value;
      });

      return {
        ...state,
        pipRes: newPipRes,
      };
    }
    case PipelineTypes.CREATE_PIPELINE_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          loading: true,
        },
      };
    }
    case PipelineTypes.CREATE_PIPELINE_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          loading: false,
          pipelineId: data.id,
          title: data.title,
          folderPath: data.folder_path,
        },
      };
    }
    case PipelineTypes.CREATE_PIPELINE_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          loading: false,
          error: payload,
        },
      };
    }
    case PipelineTypes.UPDATE_PIPELINE_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          loading: true,
        },
      };
    }
    case PipelineTypes.UPDATE_PIPELINE_SUCCESS: {
      // const { data } = payload;
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          loading: false,
        },
      };
    }
    case PipelineTypes.UPDATE_PIPELINE_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          loading: false,
          error: payload,
        },
      };
    }
    case PipelineTypes.RUN_PIPELINE_START: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          loading: true,
          panelDisabled: true,
        },
      };
    }
    case PipelineTypes.RUN_PIPELINE_SUCCESS: {
      console.log("pipResponse", payload);
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          loading: false,
          runId: payload.run_id,
          pipeline_json_uuid: payload.pipeline_json_uuid,
        },
      };
    }
    case PipelineTypes.RUN_PIPELINE_FAILURE: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          loading: false,
          panelDisabled: false,
          error: payload,
        },
      };
    }

    case PipelineTypes.FETCH_COLUMN_FROM_TABLE_START_PIPELINE: {
      const { nodeId } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);

      if (node) {
        // Deep copy the nested structures
        const updatedFetchedTables = node.data.prevState.fetchedTables.map(
          (t) => ({
            ...t,
            ...(t.table_name === node.data.prevState.selectedTable && {
              loading: true,
            }),
          })
        );

        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              fetchedTables: updatedFetchedTables,
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((n) => (n.id === nodeId ? updatedNode : n)),
        };
      }

      return state;
    }

    case PipelineTypes.FETCH_COLUMN_FROM_TABLE_SUCCESS_PIPELINE: {
      const { nodeId, data } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);

      if (node) {
        const ftvIndex = node.data?.prevState?.fetchedTables?.findIndex(
          (t) => t.table_name === node.data?.prevState?.selectedTable
        );
        if (ftvIndex !== -1) {
          const updatedFtv = {
            ...node.data.prevState?.fetchedTables[ftvIndex],
          };

          updatedFtv.loading = false;
          updatedFtv.columns = data;

          const updatedNode = {
            ...node,
            data: {
              ...node.data,
              prevState: {
                ...node.data.prevState,
                fetchedTables: [
                  ...node.data.prevState?.fetchedTables.slice(0, ftvIndex),
                  updatedFtv,
                  ...node.data.prevState?.fetchedTables.slice(ftvIndex + 1),
                ],
              },
            },
          };
          return {
            ...state,
            nodes: state.nodes.map((node) =>
              node.id === nodeId ? updatedNode : node
            ),
          };
        }
      }

      return state;
    }
    case PipelineTypes.FETCH_COLUMN_FROM_TABLE_FAILURE_PIPELINE: {
      const { nodeId } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);

      if (node) {
        // Deep copy the nested structures
        const updatedFetchedTables = node.data.prevState.fetchedTables.map(
          (t) => ({
            ...t,
            ...(t.table_name === node.data.prevState.selectedTable && {
              loading: false,
            }),
          })
        );

        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              fetchedTables: updatedFetchedTables,
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((n) => (n.id === nodeId ? updatedNode : n)),
        };
      }

      return state;
    }

    case PipelineTypes.FETCH_COLUMN_FROM_TABLE_VIEW_START_PIPELINE: {
      const { nodeId } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);

      if (node) {
        // Deep copy the fetchedTablesView array
        const updatedFetchedTablesView =
          node.data.prevState.fetchedTablesView.map((v) => ({
            ...v,
            ...(v.view_name === node.data.prevState.selectedTableView && {
              loading: true,
            }),
          }));

        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              fetchedTablesView: updatedFetchedTablesView,
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((n) => (n.id === nodeId ? updatedNode : n)),
        };
      }

      return state;
    }

    case PipelineTypes.FETCH_COLUMN_FROM_TABLE_VIEW_SUCCESS_PIPELINE: {
      const { nodeId, data } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);

      if (node) {
        const ftvIndex = node.data.prevState.fetchedTablesView.findIndex(
          (v) => v.view_name === node.data.prevState.selectedTableView
        );

        if (ftvIndex !== -1) {
          const updatedFtv = {
            ...node.data.prevState.fetchedTablesView[ftvIndex],
            loading: false,
            columns: data,
          };

          const updatedNode = {
            ...node,
            data: {
              ...node.data,
              prevState: {
                ...node.data.prevState,
                fetchedTablesView: [
                  ...node.data.prevState.fetchedTablesView.slice(0, ftvIndex),
                  updatedFtv,
                  ...node.data.prevState.fetchedTablesView.slice(ftvIndex + 1),
                ],
              },
            },
          };

          return {
            ...state,
            nodes: state.nodes.map((n) => (n.id === nodeId ? updatedNode : n)),
          };
        }
      }

      return state;
    }

    case PipelineTypes.FETCH_COLUMN_FROM_TABLE_VIEW_FAILURE_PIPELINE: {
      const { nodeId } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);

      if (node) {
        // Deep copy the fetchedTablesView array
        const updatedFetchedTablesView =
          node.data.prevState.fetchedTablesView.map((v) => ({
            ...v,
            ...(v.view_name === node.data.prevState.selectedTableView && {
              loading: false,
            }),
          }));

        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              fetchedTablesView: updatedFetchedTablesView,
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((n) => (n.id === nodeId ? updatedNode : n)),
        };
      }

      return state;
    }

    case PipelineTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START_PIPELINE:
    case PipelineTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_START_PIPELINE: {
      const { nodeId } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);
      if (node) {
        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              isResultsFetching: true,
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((node) =>
            node.id === nodeId ? updatedNode : node
          ),
        };
      }

      return state;
    }

    case PipelineTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS_PIPELINE:
    case PipelineTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS_PIPELINE: {
      const { nodeId, data } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);
      if (node) {
        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              ...(data?.type === "table_view"
                ? {
                    fetchedTables: !_.isEmpty(data?.table)
                      ? data?.table.map((tableName) => ({
                          table_name: tableName,
                          columns: [],
                        }))
                      : [],
                    fetchedTablesView: !_.isEmpty(data?.view)
                      ? data?.view.map((viewName) => ({
                          view_name: viewName,
                          columns: [],
                        }))
                      : [],
                    isResultsFetching: false,
                  }
                : data?.type === "table"
                ? {
                    fetchedTables: !_.isEmpty(data?.table)
                      ? data?.table.map((tableName) => ({
                          table_name: tableName,
                          columns: [],
                        }))
                      : [],
                    fetchedTablesView: [],
                    fetchedSchemas: [],
                    isResultsFetching: false,
                  }
                : {
                    fetchedSchemas: data?.schema,
                    fetchedTables: [],
                    fetchedTablesView: [],
                    isResultsFetching: false,
                    nextType: data?.next_type ?? "",
                  }),
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((node) =>
            node.id === nodeId ? updatedNode : node
          ),
        };
      }

      return state;
    }
    case PipelineTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE_PIPELINE:
    case PipelineTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE_PIPELINE: {
      const { nodeId, error } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);
      if (node) {
        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              fetchedTables: [],
              fetchedTablesView: [],
              fetchedSchemas: [],
              isResultsFetching: false,
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((node) =>
            node.id === nodeId ? updatedNode : node
          ),
        };
      }

      return state;
    }
    case PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START_PIPELINE: {
      const { nodeId } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);
      if (node) {
        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              isDatabaseFetching: true,
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((node) =>
            node.id === nodeId ? updatedNode : node
          ),
        };
      }
      return state;
    }
    case PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS_PIPELINE: {
      const { nodeId, data } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);
      if (node) {
        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              ...(data?.type === "schema"
                ? {
                    schemas: data?.schema,
                    database: [],
                    isDatabaseFetching: false,
                  }
                : {
                    database: data?.database,
                    schemas: [],
                    isDatabaseFetching: false,
                  }),
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((node) =>
            node.id === nodeId ? updatedNode : node
          ),
        };
      }
      return state;
    }
    case PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE_PIPELINE: {
      const { nodeId, error } = payload;
      const node = state.nodes.find((n) => n.id === nodeId);
      if (node) {
        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
              isDatabaseFetching: false,
              database: [],
              schemas: [],
              error: error,
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((node) =>
            node.id === nodeId ? updatedNode : node
          ),
        };
      }

      return state;
    }
    case PipelineTypes.FETCH_CONNECTION_PROFILE_BY_ID_SUCCESS_PIPELINE: {
      const { nodeId, data } = payload;

      const node = state.nodes.find((node) => node.id === nodeId);
      if (node) {
        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              // Copy the previous state to prevent mutation
              // We're only updating specific keys here, so we can safely shallow clone the object
              // If we were updating entire state, we would need to deep clone it
              ...node.data.prevState,
              soloConnectionProfile: data,
            },
          },
        };

        return {
          ...state,
          nodes: state.nodes.map((node) =>
            node.id === nodeId ? updatedNode : node
          ),
        };
      }
      return state;
    }
    case PipelineTypes.ON_CHANGE_APPLY_QUERY_TAB_VALUE_PIPELINE: {
      const { nodeId, updates } = action.payload;
      const node = state.nodes.find((node) => node.id === nodeId);
      if (node) {
        const updatedNode = {
          ...node,
          data: {
            ...node.data,
            prevState: {
              ...node.data.prevState,
            },
          },
        };

        updates.forEach(({ key, value }) => {
          updatedNode.data.prevState[key] = value;
        });

        return {
          ...state,
          nodes: state.nodes.map((node) =>
            node.id === nodeId ? updatedNode : node
          ),
        };
      }
      return state;
    }
    case PipelineTypes.FETCH_PIPELINE_SOURCE_START:
      return {
        ...state,
        sources: {
          ...state.sources,
          loading: true,
        },
      };

    case PipelineTypes.FETCH_PIPELINE_TRANSFORMATIONS_START:
      return {
        ...state,
        transformations: {
          ...state.transformations,
          loading: true,
        },
      };

    case PipelineTypes.FETCH_PIPELINE_SINK_START:
      return {
        ...state,
        sink: {
          ...state.sink,
          loading: true,
        },
      };
    case PipelineTypes.FETCH_PIPELINE_ADDITIONAL_START:
      return {
        ...state,
        additional: {
          ...state.additional,
          loading: true,
        },
      };

    case PipelineTypes.FETCH_PIPELINE_SOURCE_SUCCESS:
      return {
        ...state,
        sources: {
          ...state.sources,
          loading: false,
          list: payload,
        },
      };

    case PipelineTypes.FETCH_PIPELINE_TRANSFORMATIONS_SUCCESS:
      return {
        ...state,
        transformations: {
          ...state.transformations,
          loading: false,
          list: payload,
        },
      };

    case PipelineTypes.FETCH_PIPELINE_SINK_SUCCESS:
      return {
        ...state,
        sink: {
          ...state.sink,
          loading: false,
          list: payload,
        },
      };
    case PipelineTypes.FETCH_PIPELINE_ADDITIONAL_SUCCESS:
      return {
        ...state,
        additional: {
          ...state.additional,
          loading: false,
          list: payload,
        },
      };

    case PipelineTypes.FETCH_PIPELINE_SOURCE_FAILURE:
      return {
        ...state,
        sources: {
          ...state.sources,
          loading: false,
          error: payload,
        },
      };

    case PipelineTypes.FETCH_PIPELINE_TRANSFORMATIONS_FAILURE:
      return {
        ...state,
        transformations: {
          ...state.transformations,
          loading: false,
          error: payload,
        },
      };

    case PipelineTypes.FETCH_PIPELINE_SINK_FAILURE:
      return {
        ...state,
        sink: {
          ...state.sink,
          loading: false,
          error: payload,
        },
      };
    case PipelineTypes.FETCH_PIPELINE_ADDITIONAL_FAILURE:
      return {
        ...state,
        additional: {
          ...state.additional,
          loading: false,
          error: payload,
        },
      };

    case PipelineTypes.SET_NODES:
      return {
        ...state,
        nodes: action.payload,
      };
    case PipelineTypes.SET_NODES_JOIN:
      return {
        ...state,
        transformations: {
          ...state.transformations,
          join: {
            ...state.transformations.join,
            nodes: action.payload,
          },
        },
      };

    case PipelineTypes.SET_EDGES:
      return {
        ...state,
        edges: action.payload,
      };

    case PipelineTypes.ON_NODES_CHANGE:
      console.log("matched", action.payload);
      return {
        ...state,
        nodes: applyNodeChanges(action.payload, state.nodes),
      };

    case PipelineTypes.ON_EDGES_CHANGE:
      return {
        ...state,
        edges: applyEdgeChanges(action.payload, state.edges),
      };
    case PipelineTypes.ON_NODES_CHANGE_JOIN:
      const { changes, orderId } = action.payload;

      return {
        ...state,
        nodes: state.nodes.map((el) => {
          if (Number(el.orderId) === Number(orderId)) {
            return {
              ...el,
              data: {
                ...el.data,
                subFlow: {
                  ...el.data.subFlow,
                  nodes: applyNodeChanges(changes, el.data.subFlow.nodes),
                },
              },
            };
          }
          return el;
        }),
      };

    case PipelineTypes.ON_EDGES_CHANGE_JOIN: {
      const { changes, orderId } = action.payload;

      console.log(
        "onEdgesChange",
        changes,
        orderId,
        state.nodes.map((el) => el.orderId === orderId)
      );
      return {
        ...state,
        nodes: state.nodes.map((el) => {
          if (Number(el.orderId) === Number(orderId)) {
            return {
              ...el,
              data: {
                ...el.data,
                subFlow: {
                  ...el.data.subFlow,
                  edges: applyEdgeChanges(changes, el.data.subFlow.edges),
                },
              },
            };
          }
          return el;
        }),
      };
    }
    case PipelineTypes.ON_CONNECT: {
      console.log("edge", action.payload);
      const connectedEdge = action.payload;
      const sourceNode = state.nodes.filter(
        (n) => n.id === connectedEdge?.source
      );
      const targetNode = state.nodes.filter(
        (n) => n.id === connectedEdge?.target
      );
      console.log("edge", sourceNode, targetNode);
      return {
        ...state,
        edges: addEdge(action.payload, state.edges),
        nodes: state.nodes.map((s) => {
          if (s.type === "SOURCE") {
            if (s.id === connectedEdge?.source) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,
                        opDtls: [
                          ...(s.data.details.info.data.opDtls || []),
                          {
                            sID: targetNode[0]?.orderId,
                            opId: connectedEdge?.sourceHandle,
                          },
                        ],
                      },
                    },
                  },
                },
              };
            }
            return s;
          } else if (s.type === "TRANSFORMATION") {
            if (s.id === connectedEdge?.source) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,
                        opDtls: [
                          ...(s.data.details.info.data.opDtls || []),
                          {
                            sID: targetNode[0]?.orderId,
                            opId: connectedEdge?.sourceHandle,
                          },
                        ],
                      },
                    },
                  },
                },
              };
            }
            if (s.id === connectedEdge?.target) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,
                        ipDtls: [
                          ...(s.data.details.info.data.ipDtls || []),
                          {
                            sID: sourceNode[0]?.orderId,
                            ipId: connectedEdge?.sourceHandle,
                          },
                        ],
                      },
                    },
                  },
                },
              };
            }
            return s;
          } else if (s.type === "SINK") {
            if (s.id === connectedEdge?.source) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,

                        opDtls: [
                          ...(s.data.details.info.data.opDtls || []),
                          {
                            sID: targetNode[0]?.orderId,
                            opId: connectedEdge?.sourceHandle,
                          },
                        ],
                      },
                    },
                  },
                },
              };
            }
            if (s.id === connectedEdge?.target) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,
                        ipDtls: [
                          ...(s.data.details.info.data.ipDtls || []),
                          {
                            sID: sourceNode[0]?.orderId,
                            ipId: connectedEdge?.sourceHandle,
                          },
                        ],
                      },
                    },
                  },
                },
              };
            }
          } else {
            if (s.id === connectedEdge?.target) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,
                        ipDtls: [
                          ...(s.data.details.info.data.ipDtls || []),
                          {
                            sID: sourceNode[0]?.orderId,
                            ipId: connectedEdge?.sourceHandle,
                          },
                        ],
                        opDtls: [],
                      },
                    },
                  },
                },
              };
            }
            return s;
          }
        }),
      };
    }
    case PipelineTypes.ON_CONNECT_JOIN: {
      const connectedEdge = action.payload;
      const sourceNode = state.transformations.join.nodes.filter(
        (n) => n.id === connectedEdge?.source
      );
      const targetNode = state.transformations.join.nodes.filter(
        (n) => n.id === connectedEdge?.target
      );
      console.log("source-target-node", connectedEdge, sourceNode, targetNode);
      return {
        ...state,
        transformations: {
          ...state.transformations,
          join: {
            ...state.transformations.join,
            edges: addEdge(action.payload, state.transformations.join.edges),
          },
        },
      };
    }
    case PipelineTypes.ON_EDGES_DELETE_JOIN: {
      console.log("edgess", action.payload);
      return state;
    }
    case PipelineTypes.ON_EDGES_DELETE: {
      console.log("edges", action.payload);
      const deletedEdges = action.payload;

      const updatedNodes = state.nodes.map((node) => {
        if (node.type === "SOURCE" || node.type === "TRANSFORMATION") {
          // Iterate over each deleted edge
          deletedEdges.forEach((edge) => {
            const sourceNode = state.nodes.find((n) => n.id === edge.source);
            const targetNode = state.nodes.find((n) => n.id === edge.target);

            if (node.id === edge.source) {
              // Handle SOURCE and TRANSFORMATION (opDtls) updates
              node = {
                ...node,
                data: {
                  ...node.data,
                  details: {
                    ...node.data.details,
                    info: {
                      ...node.data.details.info,
                      data: {
                        ...node.data.details.info.data,
                        opDtls: node.data.details.info.data.opDtls?.filter(
                          (el) => el.sID !== targetNode?.orderId
                        ),
                      },
                    },
                  },
                },
              };
            } else if (node.id === edge.target) {
              // Handle TRANSFORMATION (ipDtls) updates
              node = {
                ...node,
                data: {
                  ...node.data,
                  details: {
                    ...node.data.details,
                    info: {
                      ...node.data.details.info,
                      data: {
                        ...node.data.details.info.data,
                        ipDtls: node.data.details.info.data.ipDtls?.filter(
                          (el) => el.sID !== sourceNode?.orderId
                        ),
                      },
                    },
                  },
                },
              };
            }
          });
        } else {
          // Handle other node types
          deletedEdges.forEach((edge) => {
            const sourceNode = state.nodes.find((n) => n.id === edge.source);
            if (node.id === edge.target) {
              node = {
                ...node,
                data: {
                  ...node.data,
                  details: {
                    ...node.data.details,
                    info: {
                      ...node.data.details.info,
                      data: {
                        ...node.data.details.info.data,
                        ipDtls: node.data.details.info.data.ipDtls?.filter(
                          (el) => el.sID !== sourceNode?.orderId
                        ),
                        opDtls: [],
                      },
                    },
                  },
                },
              };
            }
          });
        }
        return node;
      });

      return {
        ...state,
        nodes: updatedNodes,
      };
    }

    case PipelineTypes.ADD_NODE:
      return {
        ...state,
        nodes: [...state.nodes, action.payload],
      };
    case PipelineTypes.ADD_NODE_JOIN:
      return {
        ...state,
        transformations: {
          ...state.transformations,
          join: {
            nodes: [...state.transformations.join.nodes, action.payload],
          },
        },
      };

    case PipelineTypes.UPDATE_NODES_BASED_ON_EDGES:
      const { edges, nodes } = action.payload;
      const connectedEdge = edges[edges?.length - 1];
      const sourceNode = nodes.filter((n) => n.id === connectedEdge?.source);
      const targetNode = nodes.filter((n) => n.id === connectedEdge?.target);

      return {
        ...state,
        nodes: nodes.map((s) => {
          if (s.type === "SOURCE") {
            if (s.id === connectedEdge?.source) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,
                        opDtls: [
                          ...(s.data.details.info.data.opDtls || []),
                          {
                            sID: targetNode[0]?.orderId,
                            opId: connectedEdge?.sourceHandle,
                          },
                        ],
                      },
                    },
                  },
                },
              };
            }
            return s;
          } else if (s.type === "TRANSFORMATION") {
            if (s.id === connectedEdge?.source) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,
                        opDtls: [
                          ...(s.data.details.info.data.opDtls || []),
                          {
                            sID: targetNode[0]?.orderId,
                            opId: connectedEdge?.sourceHandle,
                          },
                        ],
                      },
                    },
                  },
                },
              };
            }
            if (s.id === connectedEdge?.target) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,
                        ipDtls: [
                          ...(s.data.details.info.data.ipDtls || []),
                          {
                            sID: sourceNode[0]?.orderId,
                            ipId: connectedEdge?.sourceHandle,
                          },
                        ],
                      },
                    },
                  },
                },
              };
            }
            return s;
          } else {
            if (s.id === connectedEdge?.target) {
              return {
                ...s,
                data: {
                  ...s.data,
                  details: {
                    ...s.data.details,
                    info: {
                      ...s.data.details.info,
                      data: {
                        ...s.data.details.info.data,
                        ipDtls: [
                          ...(s.data.details.info.data.ipDtls || []),
                          {
                            sID: sourceNode[0]?.orderId,
                            ipId: connectedEdge?.sourceHandle,
                          },
                        ],
                        opDtls: [],
                      },
                    },
                  },
                },
              };
            }
            return s;
          }
        }),
      };

    case PipelineTypes.PRESERVE_NODE_STATE: {
      const { nodeId } = action.payload;
      const nodeExistsIndex = state.nodeState.findIndex(
        (node) => node.nodeId === nodeId
      );

      if (nodeExistsIndex !== -1) {
        return {
          ...state,
          nodeState: [
            ...state.nodeState.slice(0, nodeExistsIndex),
            action.payload,
            ...state.nodeState.slice(nodeExistsIndex + 1),
          ],
        };
      } else {
        return {
          ...state,
          nodeState: [...state.nodeState, action.payload],
        };
      }
    }

    case PipelineTypes.FETCH_PIPELINE_DATA_RETENTION_START: {
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          loading: true,
        },
      };
    }

    case PipelineTypes.FETCH_PIPELINE_DATA_RETENTION_SUCCESS: {
      const { custom_database } = payload;

      let connectionProfile,
        selectedDatabase,
        selectedFetchedSchema,
        selectedSchema;

      if (custom_database && Object.keys(custom_database).length > 0) {
        connectionProfile = custom_database.connection_profile ?? null;
        selectedDatabase = custom_database.database ?? null;
        selectedFetchedSchema = custom_database.schema ?? null;
        selectedSchema = custom_database.oracle_schema ?? null;
      }

      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          loading: false,
          data: payload,
          tempData: payload,
          connectionProfile,
          selectedDatabase,
          selectedFetchedSchema,
          selectedSchema,
        },
      };
    }

    case PipelineTypes.CANCEL_DATA_RETENTION_UPDATE: {
      const { custom_database } = state.pipDataRetention.tempData;

      let connectionProfile,
        selectedDatabase,
        selectedFetchedSchema,
        selectedSchema;

      if (custom_database && Object.keys(custom_database).length > 0) {
        connectionProfile = custom_database.connection_profile ?? null;
        selectedDatabase = custom_database.database ?? null;
        selectedFetchedSchema = custom_database.schema ?? null;
        selectedSchema = custom_database.oracle_schema ?? null;
      }
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          data: state.pipDataRetention.tempData,
          connectionProfile,
          selectedDatabase,
          selectedFetchedSchema,
          selectedSchema,
        },
      };
    }

    case PipelineTypes.FETCH_PIPELINE_DATA_RETENTION_FAILURE: {
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          loading: false,
          error: payload,
        },
      };
    }

    case PipelineTypes.UPDATE_DATA_RETENTION_PARAMS:
      const { updates } = action.payload;
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          ...updates.reduce((acc, { key, value }) => {
            acc[key] = value;
            return acc;
          }, {}),
        },
      };

    case PipelineTypes.PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START:
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          isResultsFetching: true,
        },
      };

    case PipelineTypes.UPDATE_DATA_RETENTION_PARAMS_DATA:
      const { keys, values } = action.payload;
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          data: {
            ...state.pipDataRetention.data,
            [keys]: values,
          },
        },
      };

    case PipelineTypes.PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS:
      const fieldsToBeUpdated =
        payload?.data?.type === "table_view"
          ? {
              fetchedTables: !_.isEmpty(payload?.table)
                ? payload?.table.map((tableName) => ({
                    table_name: tableName,
                    columns: [],
                  }))
                : [],
              fetchedTablesView: !_.isEmpty(payload?.view)
                ? payload?.view.map((viewName) => ({
                    view_name: viewName,
                    columns: [],
                  }))
                : [],
              isResultsFetching: false,
            }
          : payload?.type === "table"
          ? {
              fetchedTables: !_.isEmpty(payload?.table)
                ? payload?.table.map((tableName) => ({
                    table_name: tableName,
                    columns: [],
                  }))
                : [],
              fetchedTablesView: [],
              fetchedSchemas: [],
              isResultsFetching: false,
            }
          : {
              fetchedSchemas: payload?.schema,
              fetchedTables: [],
              fetchedTablesView: [],
              isResultsFetching: false,
              nextType: payload?.next_type ?? "",
            };
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          ...fieldsToBeUpdated,
        },
      };

    case PipelineTypes.PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_START:
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          queryConnectionList: {
            ...state.pipDataRetention.queryConnectionList,
            loading: true,
          },
        },
      };

    case PipelineTypes.PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_FAILURE:
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          queryConnectionList: {
            ...state.pipDataRetention.queryConnectionList,
            loading: false,
            error: payload,
          },
        },
      };

    case PipelineTypes.PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_SUCCESS:
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          queryConnectionList: {
            ...state.pipDataRetention.queryConnectionList,
            loading: false,
            list: payload?.results,
          },
        },
      };

    case PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS_PIP:
      const successQueryToBeUpdated =
        payload?.data?.type === "schema"
          ? {
              schemas: payload?.data?.schema,
              database: [],
              isDatabaseFetching: false,
            }
          : {
              database: payload?.data?.database,
              schemas: [],
              isDatabaseFetching: false,
            };

      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          ...successQueryToBeUpdated,
        },
      };

    case PipelineTypes.UPDATE_DATA_RETENTION_DATA_SUCCESS: {
      return {
        ...state,
        pipRes: {
          ...state.pipRes,
          title: payload?.pipeline?.title || state.pipRes.title,
        },
        pipDataRetention: {
          ...state.pipDataRetention,
          loading: false,
          data: payload,
          tempData: payload,
        },
      };
    }

    case PipelineTypes.UPDATE_DATA_RETENTION_DATA_START: {
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          loading: true,
        },
      };
    }

    case PipelineTypes.UPDATE_DATA_RETENTION_DATA_FAILURE: {
      return {
        ...state,
        pipDataRetention: {
          ...state.pipDataRetention,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default pipelineReducer;
