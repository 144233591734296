import * as React from "react";

// third party libraries
import { Box, useTheme } from "@mui/material";
import { AgGridReact } from "ag-grid-react";

// project imports
import { CustomLoadingOverlay, getRowClass } from "components/CustomTable";
import { useSelector } from "react-redux";

// ==============================|| CONNECTION PROFILE TABLE ||============================== //
export default function AuditLogMainTable({
  columnDefs,
  defaultColDef,
  icons,
  gridRef,

  onSelectionChanged,
}) {
  const { auditLogs } = useSelector((state) => state.auditLog);
  const theme = useTheme();

  const onSaveGridColumnState = () => {
    localStorage?.setItem(
      "auditLogColumn",
      JSON.stringify(gridRef.current.getColumnState())
    );
  };

  const onGridReady = (params) => {
    gridRef.current = params.api;

    // Apply column state from localStorage
    if (localStorage.getItem("auditLogColumn")) {
      gridRef.current.applyColumnState({
        state: JSON.parse(localStorage.getItem("auditLogColumn")),
        applyOrder: true,
      });
    }

    // Set selected rows from localStorage
    const savedSelectedRows = JSON.parse(localStorage.getItem("selectedRows"));
    if (savedSelectedRows) {
      params.api.forEachNode((node) => {
        if (savedSelectedRows.some((row) => row.id === node.data.id)) {
          node.setSelected(true);
        }
      });
    }

    // Show or hide loading overlay based on loading state
    if (auditLogs?.loading) {
      params.api.showLoadingOverlay();
    } else {
      params.api.hideOverlay();
    }
  };

  if (auditLogs?.loading) {
    gridRef?.current?.api?.showLoadingOverlay();
  }

  console.log("auditLog", auditLogs);

  return (
    <Box
      className="ag-theme-custom"
      sx={{
        width: "100%",
        height: "100%",
        marginBottom: 3,
        [`& .strippedRows-effect`]: {
          backgroundColor: theme.palette.grey[200],
        },
        " .ag-checkbox-input-wrapper.ag-checked::after": {
          color: "#fff !important",
        },
        " .ag-checkbox-input-wrapper.ag-indeterminate::after": {
          color: "#fff !important",
        },
        "& .ag-header-cell-resize::after": {
          background: "#0000004D",
          width: 0.1,
          height: "57%",
          margin: "-5px 0px",
        },

        "& .ag-row-even": {
          backgroundColor: !auditLogs?.strippedRows
            ? "white !important"
            : theme.palette.grey[200],
        },

        "& .ag-header-cell": {
          paddingLeft: 1,
        },
        "& .ag-cell": {
          paddingLeft: 1,
        },
        "& .ag-header-cell-label": {
          gap: 0,
        },
        "& .ag-header-cell-label img": {
          marginTop: "4px",
        },
      }}
    >
      <AgGridReact
        lockVisible={true}
        suppressDragLeaveHidesColumns
        key={JSON.stringify(auditLogs?.list)}
        ref={gridRef}
        rowData={auditLogs?.list}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        columnMenu={"new"}
        //  getRowClass={(params) => getRowClass(params, auditLog?.strippedRows)}
        animateRows={false}
        onSelectionChanged={onSelectionChanged}
        rowHeight={28}
        headerHeight={28}
        overlayNoRowsTemplate="Audit log is empty."
        icons={icons}
        suppressColumnMoveAnimation={true}
        suppressRowClickSelection={false}
        rowSelection="single"
        loadingOverlayComponent={CustomLoadingOverlay}
        loadingOverlayComponentParams={{
          loadingMessage: "Loading...",
        }}
        onColumnResized={onSaveGridColumnState}
        onGridReady={onGridReady}
        getRowId={(params) => params.data.id}
        onFirstDataRendered={(params) => {
          params.api.hideOverlay();
        }}
      />
    </Box>
  );
}
