// project imports
import * as ConnectionType from "./connection.types";
import {
  formatDateAndTime,
  generateRandom,
  getFormattedTime,
} from "../../utils/helper";

const INITIAL_STATE = {
  error: null,
  loading: false,
  soloConnectionProfileTest: {
    isTesting: false,
    testSuccessful: null,
  },
  soloConnectionProfile: null,
  connectionUsesLoading: false,
  connectionUses: {
    updateDateToggler: 0,
    searchParams: "",
    connection_check5: null,
    status: null,
    limit: 30,
    strippedRows: false,
    rowClickSelection: true,
    wrapLines: false,
    hasMore: false,
    loading: false,
    error: null,
    count: null,
    list: null,
    image: "",
  },
  connectionProfile: {
    searchParams: "",
    updateCreatedToggler: 0,
    updateModifiedToggler: 0,
    connection_check5: null,
    status: null,
    limit: 30,
    strippedRows: false,
    rowClickSelection: true,
    wrapLines: false,
    hasMore: false,
    loading: false,
    error: null,
    count: null,
    list: null,
  },
};

const connectionReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ConnectionType.FETCH_ALL_CONNECTION_PROFILE_START:
      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          loading: true,
        },
      };

    case ConnectionType.FETCH_CONNECTION_PROFILE_BY_ID_START:
      return {
        ...state,
        loading: true,
      };

    case ConnectionType.CREATE_CONNECTION_PROFILE_START:
      return {
        ...state,
        loading: true,
      };

    case ConnectionType.UPDATE_CONNECTION_PROFILE_START:
      return {
        ...state,
        loading: true,
      };

    case ConnectionType.CHANGE_CONNECTION_PROFILE_STATUS_START:
      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          loading: true,
        },
      };

    case ConnectionType.TEST_CONNECTION_PROFILE_START:
      return {
        ...state,
        soloConnectionProfileTest: {
          ...state.soloConnectionProfileTest,
          isTesting: true,
          testSuccessful: null,
        },
      };

    case ConnectionType.TEST_CONNECTION_AFTER_CONNECTION_PROFILE_START:
      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          list: state.connectionProfile.list.map((item) => {
            if (payload.includes(item.id)) {
              return {
                ...item,
                connection_check: [
                  ...item.connection_check.slice(1),
                  "processing",
                ],
              };
            }
            return item;
          }),
        },
        loading: true,
      };

    case ConnectionType.BULK_DELETE_CONNECTION_PROFILE_START:
      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          loading: true,
        },
      };

    case ConnectionType.RESET_CONNECTION_USES:
      return {
        ...state,
        connectionUses: {
          ...INITIAL_STATE.connectionUses,
          limit: state.connectionUses.limit,
          strippedRows: state.connectionUses.strippedRows,
          wrapLines: state.connectionUses.wrapLines,
          rowClickSelection: state.connectionUses.rowClickSelection,
          displayColumns: state.connectionUses.displayColumns,
        },
      };

    case ConnectionType.UPDATE_DATE_FORMAT_TOGGLER:
      const { columnName } = action.payload;
      return {
        ...state,
        connectionUses: {
          ...state.connectionUses,
          [columnName]: (state.connectionUses[columnName] + 1) % 3,
        },
      };

    case ConnectionType.UPDATE_DATE_FORMAT_TOGGLER_CONNECTION:
      const { column } = action.payload;
      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          [column]: (state.connectionProfile[column] + 1) % 3,
        },
      };

    case ConnectionType.FETCH_ALL_CONNECTION_PROFILE_SUCCESS:
      const formattedList = payload?.results?.map((r) => ({
        ...r,
        created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
        updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        source_type: r.source_type ? r.source_type : "-",
        created_by: r.created_by ? r.created_by : "-",
        updated_by: r.updated_by ? r.updated_by : "-",
      }));

      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          loading: false,
          count: payload?.count,
          hasMore: payload?.next ? true : false,
          list: formattedList,
        },
      };

    case ConnectionType.FETCH_CONNECTION_PROFILE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        soloConnectionProfile: payload,
      };

    case ConnectionType.CREATE_CONNECTION_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ConnectionType.UPDATE_CONNECTION_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ConnectionType.CHANGE_CONNECTION_PROFILE_STATUS_SUCCESS:
      const updatedList = state.connectionProfile.list.map((profile) => {
        if (profile.id === payload?.id) {
          return {
            ...profile,
            status: payload?.data?.status,
          };
        }
        return profile;
      });

      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          loading: false,
          list: updatedList,
        },
      };

    case ConnectionType.FETCH_CONNECTION_USES_START:
      return {
        ...state,
        connectionUses: {
          ...state.connectionUses,
          loading: true,
        },
      };

    case ConnectionType.FETCH_CONNECTION_USES_SUCCESS:
      const formattedListUses = payload?.results?.map((r) => ({
        ...r,
        updated_at: r.updated_at ? getFormattedTime(r.updated_at) : " ",
        id: r.run_at ? r.run_at : generateRandom(),
      }));
      return {
        ...state,
        connectionUses: {
          ...state.connectionUses,
          loading: false,
          count: payload?.count,
          hasMore: payload?.next ? true : false,
          list: formattedListUses,
          image: payload?.source_image,
        },
      };

    case ConnectionType.TEST_CONNECTION_PROFILE_SUCCESS:
      return {
        ...state,
        soloConnectionProfileTest: {
          ...state.soloConnectionProfileTest,
          isTesting: false,
          testSuccessful: true,
        },
      };

    case ConnectionType.TEST_CONNECTION_AFTER_CONNECTION_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        connectionProfile: {
          ...state.connectionProfile,
          list: state.connectionProfile.list.map((obj) => {
            const testedObj = payload.find((p) => p.id === obj.id);
            if (testedObj) {
              const statusStr = testedObj.status ? "success" : "failed";
              return {
                ...obj,
                connection_check: [
                  ...obj.connection_check.slice(
                    0,
                    obj.connection_check.length - 1
                  ),
                  statusStr,
                ],
              };
            }
            return obj;
          }),
        },
      };

    case ConnectionType.BULK_DELETE_CONNECTION_PROFILE_SUCCESS:
      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          loading: false,
        },
      };

    case ConnectionType.ON_CHANGE_APPLY_CONNECTION_PROFILE_FILTER:
      const { key, value } = action.payload;

      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          [key]: value,
        },
      };

    case ConnectionType.REFRESH_CONNECTION_PROFILE:
      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          list: null,
        },
      };

    case ConnectionType.ON_CHANGE_CONNECTION_USES_FILTER:
      const { keys, values } = action.payload;

      return {
        ...state,
        connectionUses: {
          ...state.connectionUses,
          loading: false,
          [keys]: values,
        },
      };

    case ConnectionType.FETCH_ALL_CONNECTION_PROFILE_FAILURE:
    case ConnectionType.CHANGE_CONNECTION_PROFILE_STATUS_FAILURE:
    case ConnectionType.BULK_DELETE_CONNECTION_PROFILE_FAILURE:
      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          loading: false,
          error: payload,
        },
      };

    case ConnectionType.FETCH_CONNECTION_PROFILE_BY_ID_FAILURE:
    case ConnectionType.CREATE_CONNECTION_PROFILE_FAILURE:
    case ConnectionType.UPDATE_CONNECTION_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case ConnectionType.TEST_CONNECTION_AFTER_CONNECTION_PROFILE_FAILURE:
      return {
        ...state,
        connectionProfile: {
          ...state.connectionProfile,
          list: state.connectionProfile.list.map((item) => {
            if (payload?.formData?.includes(item.id)) {
              return {
                ...item,
                connection_check: [...item.connection_check.slice(1), "failed"],
              };
            }
            return item;
          }),
        },
        loading: false,
      };

    case ConnectionType.TEST_CONNECTION_PROFILE_FAILURE:
      return {
        ...state,
        soloConnectionProfileTest: {
          ...state.soloConnectionProfileTest,
          error: payload,
          isTesting: false,
          testSuccessful: false,
        },
      };

    default:
      return state;
  }
};

export default connectionReducer;
