import * as React from "react";

// third party libraries
import { Box, useTheme } from "@mui/material";
import { AgGridReact } from "ag-grid-react";

// project imports
import { CustomLoadingOverlay, getRowClass } from "components/CustomTable";

// ==============================|| CONNECTION PROFILE TABLE ||============================== //
export default function AuditLogDetailsTable({
  columnDefs,
  defaultColDef,
  icons,
  auditLog,
  gridRef,
  onRowClicked,
}) {
  const theme = useTheme();

  const onSaveGridColumnState = () => {
    localStorage?.setItem(
      "auditLogColumn",
      JSON.stringify(gridRef.current.getColumnState())
    );
  };

  const onGridReady = (params) => {
    gridRef.current = params.api;

    // Apply column state from localStorage
    if (localStorage.getItem("auditLogColumn")) {
      gridRef.current.applyColumnState({
        state: JSON.parse(localStorage.getItem("auditLogColumn")),
        applyOrder: true,
      });
    }

    // Set selected rows from localStorage
    const savedSelectedRows = JSON.parse(localStorage.getItem("selectedRows"));
    if (savedSelectedRows) {
      params.api.forEachNode((node) => {
        if (savedSelectedRows.some((row) => row.id === node.data.id)) {
          node.setSelected(true);
        }
      });
    }

    // Show or hide loading overlay based on loading state
    if (auditLog?.loading) {
      params.api.showLoadingOverlay();
    } else {
      params.api.hideOverlay();
    }
  };

  if (auditLog?.loading) {
    gridRef?.current?.api?.showLoadingOverlay();
  }

  console.log("auditLog", auditLog);

  return (
    <Box
      className="ag-theme-custom"
      sx={{
        width: "100%",
        height: "100%",

        marginBottom: 3,
        [`& .strippedRows-effect`]: {
          backgroundColor: theme.palette.grey[200],
        },
        " .ag-checkbox-input-wrapper.ag-checked::after": {
          color: "#fff !important",
        },
        " .ag-checkbox-input-wrapper.ag-indeterminate::after": {
          color: "#fff !important",
        },
        "& .ag-header-cell-resize::after": {
          background: "#0000004D",
          width: 0.1,
          height: "57%",
          margin: "-5px 0px",
        },

        "& .ag-row-selected": {
          backgroundColor: "#ADD8E6 !important",
        },

        "& .ag-row-even": {
          backgroundColor: auditLog?.strippedRows
            ? "white !important"
            : theme.palette.grey[200],
        },

        "& .ag-header-cell": {
          paddingLeft: 1,
        },
        "& .ag-cell": {
          paddingLeft: 1,
        },

        "& .ag-row": {
          backgroundColor: "#f0f0f0",
        },

        "& .ag-header-cell-label": {
          gap: 0,
        },
        "& .ag-header-cell-label img": {
          marginTop: "4px",
        },
        "& .ag-center-cols-viewport": { backgroundColor: "#f0f0f0" },
      }}
    >
      <AgGridReact
        lockVisible={true}
        suppressDragLeaveHidesColumns
        ref={gridRef}
        rowData={auditLog?.list}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        columnMenu={"new"}
        animateRows={false}
        onRowClicked={onRowClicked}
        rowHeight={28}
        headerHeight={28}
        overlayNoRowsTemplate="There are no logs to display."
        icons={icons}
        suppressColumnMoveAnimation={true}
        rowSelection="single"
        loadingOverlayComponent={CustomLoadingOverlay}
        loadingOverlayComponentParams={{
          loadingMessage: "Loading...",
        }}
        onColumnResized={onSaveGridColumnState}
        onGridReady={onGridReady}
        onFirstDataRendered={(params) => {
          params.api.hideOverlay();
        }}
      />
    </Box>
  );
}
