import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  Collapse,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

// project imports
import { navItems } from "./navItems";
import * as AuthActions from "../../../store/auth/auth.actions";
import AlertDialog from "components/AlertDialog";
import { Drawer, DrawerHeader } from "../index";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "7px 20px 7px 10px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#0B3558",
  },
}));

export default function Sidebar({ open, setSettingsToggler, settingsToggler }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isBigTab = useMediaQuery(theme.breakpoints.between(768, 1024));

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleSettingsToggler = () => {
    setSettingsToggler(!settingsToggler);
  };

  const openPipTab = Boolean(anchorEl);
  const id = openPipTab ? "simple-popover" : undefined;

  const handleTabClick = (selectedOption) => {
    navigate("/manage/pipeline", { state: { activeTab: selectedOption } });
  };

  const isNavItemActive = (pathname) => {
    return (
      location.pathname === pathname ||
      location.pathname.startsWith(pathname + "/")
    );
  };

  return (
    <Fragment>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            zIndex: 995,
            overflow: "visible !important",
            backgroundColor: "#1A2055",
          },
        }}
        sx={{ position: isBigTab ? "absolute" : "" }}
      >
        <DrawerHeader open={open}>
          <img
            src={
              open
                ? "/assets/images/icons/mainIcon.svg"
                : "/assets/images/icons/mainLogo.svg"
            }
            width={open ? "75%" : "30px"}
            alt="DF"
          />
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            padding: open ? "20px 12px !important" : "20px 4px !important",
          }}
        >
          {navItems?.map((nav, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                display: "block",
                borderRadius: "4px",
                position: "relative",
                backgroundColor: isNavItemActive(nav.pathname)
                  ? theme.palette.primary.main
                  : "transparent",
                "& :hover": {
                  borderRadius: "4px",
                },
              }}
              onMouseOver={(e) => {
                if (nav.name === "Pipeline") {
                  setAnchorEl(e.currentTarget);
                }
              }}
              onMouseLeave={() => setAnchorEl(null)}
            >
              <ListItemButton
                sx={{
                  minHeight: 43,
                  justifyContent: open ? "initial" : "center",
                  px: 1.5,
                  zIndex: 997,
                }}
                onClick={() => navigate(nav.pathname)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={nav?.icon} alt={nav?.name} />
                </ListItemIcon>
                <ListItemText
                  primary={open ? nav?.name : ""}
                  sx={{
                    color: theme.palette.common.white,
                  }}
                />
              </ListItemButton>

              {nav?.name === "Pipeline" && openPipTab && (
                <Box
                  id={id}
                  sx={{
                    marginLeft: "20px",
                    zIndex: 1304,
                    position: "absolute",
                    top: "-45%",
                    right: "-173px",
                  }}
                >
                  <Box
                    sx={{
                      marginLeft: 3,
                      backgroundColor: "#191f52",
                      width: "161px",
                      borderRadius: "4px",
                    }}
                    onClick={() => setAnchorEl(null)}
                  >
                    <StyledBox onClick={() => handleTabClick("recent")}>
                      <img src="/assets/images/icons/recent.svg" alt="recent" />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "13px",
                          marginLeft: "4px",
                        }}
                      >
                        Recent
                      </Typography>
                    </StyledBox>
                    <StyledBox onClick={() => handleTabClick("browse")}>
                      <img
                        src="/assets/images/icons/folderOutline.svg"
                        alt="browse"
                      />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "13px",
                          marginLeft: "4px",
                        }}
                      >
                        Browse
                      </Typography>
                    </StyledBox>
                    <StyledBox
                      onClick={() => {
                        // navigate("/manage/pipeline/query-chain/")

                        window
                          .open(`/manage/pipeline/query-chain`, "_blank")
                          .focus();
                      }}
                    >
                      <img src="/assets/images/icons/add.svg" alt="add" />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "13px",
                          marginLeft: "4px",
                        }}
                      >
                        Add New Pipeline
                      </Typography>
                    </StyledBox>
                  </Box>
                </Box>
              )}
            </ListItem>
          ))}
        </List>

        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <Divider sx={{ background: "#FEFEFE4D" }} />
          <List sx={{ padding: open ? "20px 12px" : "20px 4px", py: 2 }}>
            <ListItem
              disablePadding
              sx={{
                display: "block",
                backgroundColor: isNavItemActive("/manage/settings")
                  ? theme.palette.primary.main
                  : "transparent",
                borderRadius: "4px",
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 43,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={handleSettingsToggler}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="/assets/images/icons/settingIcon.svg"
                    alt="Setting"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={open ? "Settings" : ""}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: theme.palette.common.white,
                  }}
                />
                {open &&
                  (settingsToggler ? (
                    <ExpandLess sx={{ color: theme.palette.common.white }} />
                  ) : (
                    <ExpandMore sx={{ color: theme.palette.common.white }} />
                  ))}
              </ListItemButton>
              <Collapse in={settingsToggler} timeout={200} unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: open ? 4 : 1.5,
                      backgroundColor: isNavItemActive(
                        "/manage/settings/user-management"
                      )
                        ? theme.palette.primary.light
                        : "transparent",
                    }}
                    onClick={() => navigate("/manage/settings/user-management")}
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                      <img
                        src="/assets/images/icons/user_dash.svg"
                        alt="User"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={open ? "User" : ""}
                      sx={{
                        color: theme.palette.common.white,
                        //  opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: open ? 4 : 1.5,
                      backgroundColor: isNavItemActive(
                        "/manage/settings/license-management"
                      )
                        ? theme.palette.primary.light
                        : "transparent",
                    }}
                    onClick={() =>
                      navigate("/manage/settings/license-management")
                    }
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                      <img
                        src="/assets/images/icons/license_dash.svg"
                        alt="License"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={open ? "License" : ""}
                      sx={{
                        color: theme.palette.common.white,
                        // opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: open ? 4 : 1.5,
                      backgroundColor: isNavItemActive(
                        "/manage/settings/audit-log"
                      )
                        ? theme.palette.primary.light
                        : "transparent",
                    }}
                    onClick={() => navigate("/manage/settings/audit-log")}
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                      <img
                        src="/assets/images/icons/auditLogIcon.svg"
                        alt="Audit Log"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={open ? "Audit Log" : ""}
                      sx={{
                        color: theme.palette.common.white,
                        // opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>{" "}
                  <ListItemButton
                    sx={{
                      pl: open ? 4 : 1.5,
                      backgroundColor: isNavItemActive(
                        "/manage/settings/cluster"
                      )
                        ? theme.palette.primary.light
                        : "transparent",
                    }}
                    onClick={() => navigate("/manage/settings/cluster")}
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                      <img
                        src="/assets/images/icons/clusterIcon.svg"
                        alt="Cluster"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={open ? "Cluster" : ""}
                      sx={{
                        color: theme.palette.common.white,
                        // opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: open ? 4 : 1.5,
                      backgroundColor: isNavItemActive(
                        "/manage/settings/clusterAdmin"
                      )
                        ? theme.palette.primary.light
                        : "transparent",
                    }}
                    onClick={() => navigate("/manage/settings/clusterAdmin")}
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                      <img
                        src="/assets/images/icons/clusterAdmin.svg"
                        alt="Cluster"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={open ? "Databricks Config" : ""}
                      sx={{
                        color: theme.palette.common.white,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "inherit",
                        // opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => setOpenDialog(true)}
                sx={{
                  minHeight: 43,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/images/icons/logoutIcon.svg" alt="logout" />
                </ListItemIcon>
                <ListItemText
                  primary={open ? "Logout" : ""}
                  sx={{
                    // opacity: open ? 1 : 0,
                    color: theme.palette.common.white,
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <AlertDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        dialogTitle="Logout"
        btnName="Logout"
        handleAction={() => dispatch(AuthActions.logout())}
      >
        <Typography variant="h6" sx={{ color: "#212121" }}>
          Are you sure you want to log out?
        </Typography>
      </AlertDialog>
    </Fragment>
  );
}
