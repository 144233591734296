import * as React from "react";

// third party libraries
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";

// ==============================|| BLANK LAYOUT ||============================== //
export default function BlankLayout({ children }) {
  return (
    <Box height={"100vh"}>
      <main
        style={{
          height: "100%",
        }}
      >
        <Outlet />
      </main>
    </Box>
  );
}
