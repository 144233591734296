import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { alpha } from "@mui/material/styles";
import {
  Button,
  InputLabel,
  TextField,
  Accordion,
  AccordionSummary,
  FormControl,
} from "@mui/material";
import { Tooltip, tooltipClasses } from "@mui/material";

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "24px",
  color: theme.palette.common.black,
}));

const CustomInputBase = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #DCDCDC",
    fontSize: 15,
    padding: "0px 12px",
    height: "36px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputBase-inputAdornedStart": {
    paddingLeft: "12px",
  },
  "& .MuiInputBase-inputAdornedEnd": {
    paddingRight: "12px",
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#DCDCDC",
    },
    "&:hover fieldset": {
      borderColor: "#DCDCDC",
    },
    "&.Mui-focused fieldset": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      borderWidth: "1px",
    },
    "&.Mui-disabled fieldset": {
      borderColor: "#DCDCDC",
    },
    "&.Mui-disabled .MuiInputBase-input": {
      WebkitTextFillColor: "#131313",
    },
    "& > input": {
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 100px #fff inset",
        WebkitTextFillColor: "#212121",
      },
    },
  },
}));

const QueryCustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    height: "24px",
    "& input": {
      padding: "0px 40px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& fieldset": {
      borderColor: "#DCDCDC",
    },
    "&:hover fieldset": {
      borderColor: "#DCDCDC",
    },
    "&.Mui-focused fieldset": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      borderWidth: "1px",
    },
    "&.Mui-disabled fieldset": {
      borderColor: "#DCDCDC",
    },
    "&.Mui-disabled .MuiInputBase-input": {
      WebkitTextFillColor: "#131313",
    },
    "& > input": {
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 100px #fff inset",
        WebkitTextFillColor: "#212121",
      },
    },
    "&.MuiInputBase-sizeSmall": {
      padding: 0,
    },
  },
}));

const FilterCustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    height: "24px",
    "& input": {
      padding: "0px 6px",
    },
    "& fieldset": {
      borderColor: "#DCDCDC",
    },
    "&:hover fieldset": {
      borderColor: "#DCDCDC",
    },
    "&.Mui-focused fieldset": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      borderWidth: "1px",
    },
    "&.Mui-disabled fieldset": {
      borderColor: "#DCDCDC",
    },
    "&.Mui-disabled .MuiInputBase-input": {
      WebkitTextFillColor: "#131313",
    },
    "& > input": {
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 100px #fff inset",
        WebkitTextFillColor: "#212121",
      },
    },
    "&.MuiInputBase-sizeSmall": {
      padding: 0,
    },
  },
}));

const SearchBar = styled(TextField)({
  // minWidth: "350px",
  borderRadius: "4px",
  "& label.Mui-focused": {
    color: "#0000001A",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#0000001A",
  },
  "& .MuiOutlinedInput-root": {
    height: "26px",
    "& fieldset": {
      borderColor: "#0000001A",
    },

    "&:hover fieldset": {
      borderColor: "#0000001A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0000001A",
    },
  },
});

const CustomBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "4px",
  border: "1px solid #0000001A",
  color: theme.palette.common.black,
  "&: hover": {
    border: "1px solid #0000001A",
  },
}));

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  "&.Mui-expanded": {
    borderTop: "1px solid #dcdcdc",
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  maxHeight: "28px",
  minHeight: "28px",
  "&.Mui-expanded": {
    maxHeight: "28px",
    minHeight: "28px",
  },
}));

const QuerySearchBar = styled(TextField)({
  borderRadius: "4px",

  "& label.Mui-focused": {
    color: "#0000001A",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#0000001A",
  },
  "& .MuiOutlinedInput-root": {
    height: "24px",
    "& fieldset": {
      borderColor: "#0000001A",
    },

    "&:hover fieldset": {
      borderColor: "#0000001A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0000001A",
    },
  },
});

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const StyledToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: theme.palette.common.white,
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
    fontWeight: 400,
    fontSize: "14px",
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "20px",
  "& > p": {
    fontSize: "12px",
    minWidth: 100,
  },
  "& > .MuiAutocomplete-root": {
    width: "100%",
  },
}));

export {
  CustomInputBase,
  CustomInputLabel,
  CustomTextField,
  SearchBar,
  CustomBtn,
  CustomAccordion,
  CustomAccordionSummary,
  QueryCustomTextField,
  QuerySearchBar,
  CustomTooltip,
  FilterCustomTextField,
  CustomFormControl,
};
