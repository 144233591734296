import * as jobsTypes from "./jobs.types";

const INITIAL_STATE = {
  jobs: {},
  jobRuns: {},
  singleJobRuns: {},
  isSingleJobRunsFetching: false,
  isJobRunFetching: false,
  error: null,
  loading: false,
  limit: 20,
  offset: 0,
  isJobRunTriggering: false,
};

const jobsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case jobsTypes.FETCH_JOBS_BY_JOB_ID_START:
      return {
        ...state,
        error: null,
        isSingleJobRunsFetching: true,
      };
    case jobsTypes.FETCH_JOBS_BY_JOB_ID_SUCCESS:
      return {
        ...state,
        error: null,
        isSingleJobRunsFetching: false,
        singleJobRuns: payload,
      };
    case jobsTypes.FETCH_JOBS_BY_JOB_ID_FAILURE:
      return {
        ...state,
        error: payload,
        isSingleJobRunsFetching: false,
      };

    case jobsTypes.CREATE_JOB_START:
    case jobsTypes.FETCH_ALL_JOB_RUNS_START:
      return {
        ...state,
        isJobRunFetching: true,
      };
    case jobsTypes.FETCH_ALL_JOB_RUNS_SUCCESS:
      return {
        ...state,
        jobRuns: payload,
        isJobRunFetching: false,
      };
    case jobsTypes.FETCH_ALL_JOB_RUNS_FAILURE:
      return {
        ...state,
        error: payload,
        isJobRunFetching: false,
      };
    case jobsTypes.RUN_JOB_START:
      return {
        ...state,
        error: null,
        isJobRunTriggering: true,
        jobs: {
          ...state.jobs,
          results: state.jobs.results?.map((r) => {
            if (r.id === payload?.jobId)
              return {
                ...r,
                status: "processing",
                last_five_runs: [...r.last_five_runs.slice(1, 5), "processing"],
              };
            return r;
          }),
        },
      };
    case jobsTypes.RUN_JOB_SUCCESS:
      return {
        ...state,
        isJobRunTriggering: false,
        jobs: {
          ...state.jobs,
        },
      };
    case jobsTypes.RUN_JOB_FAILURE:
      return {
        ...state,
        isJobRunTriggering: false,
        error: payload,
      };

    case jobsTypes.DELETE_JOB_START:
    case jobsTypes.FETCH_JOBS_START:
      return {
        ...state,
        loading: true,
      };
    case jobsTypes.FETCH_JOBS_SUCCESS:
      return {
        ...state,
        jobs: payload,
        error: null,
        loading: false,
      };
    case jobsTypes.FETCH_JOBS_FAILURE:
      return {
        ...state,
        jobs: [],
        error: payload,
        loading: false,
      };
    case jobsTypes.CREATE_JOB_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case jobsTypes.CREATE_JOB_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case jobsTypes.CREATE_JOB_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default jobsReducer;
