import React, { forwardRef, useCallback } from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { PiWarningCircleBold } from "react-icons/pi";
import { Box, Card, CardActions, IconButton, Typography } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import {
  MaterialDesignContent,
  SnackbarContent,
  SnackbarProvider,
  useSnackbar,
} from "notistack";

// assets + icons
import CloseIcon from "@mui/icons-material/Close";
import "ag-grid-community/styles/ag-grid.css";
import "./ag-grid-theme-builder.css";
import "./index.css";

// project imports
import App from "./App";
import { persistor, store } from "./store";
import CustomAlert from "./components/CustomAlert";
import reportWebVitals from "./reportWebVitals";
import theme from "./themes";
import Loader from "components/Loader";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-warning": {
    backgroundColor: "#EC942C",
  },
}));

const DisabledSnackbarContent = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);
  return (
    <SnackbarContent ref={ref}>
      <Card
        sx={{
          width: "400px",
          backgroundColor: "#dddddd",
          color: "#111",
        }}
      >
        <CardActions
          sx={{
            padding: "8px 8px 8px 16px",
            justifyContent: "space-between",
          }}
        >
          <Box display={"flex"} gap={1}>
            <img
              src={"/assets/images/icons/disableDriverIcon.svg"}
              alt="driver"
            />
            <Typography variant="body2">{props.message}</Typography>
          </Box>
          <div>
            <IconButton size="small" onClick={handleDismiss}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={5}
            style={{ zIndex: 99999, width: "400px" }}
            preventDuplicate={false}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            iconVariant={{
              warning: <PiWarningCircleBold style={{ marginRight: "10px" }} />,
            }}
            Components={{
              warning: StyledMaterialDesignContent,
              disabled: DisabledSnackbarContent,
            }}
          >
            <CustomAlert />
            <CssBaseline />

            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
