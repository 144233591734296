import React, { Fragment } from "react";

// third party libraries
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputBase,
  Typography,
  useTheme,
  Divider as MuiDivider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// project imports
import CustomToggler from "components/CustomToggler";
import { useDispatch } from "react-redux";
import * as QueryEditorActions from "store/queryeditor/queryeditor.actions";

// styles
const Divider = styled(MuiDivider)(({ theme }) => ({
  border: "1px solid #0000001A",
  margin: "14px 0",
}));

const SearchbarWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& > p:first-of-type": {
    color: "#212121",
  },
  "& > p:last-of-type": {
    padding: "5px 0",
    fontSize: "12px",
    color: "#525252",
  },
  "& > div:first-of-type": {
    display: "flex",
    width: "80%",
    paddingBottom: "5px",
    "& > div:last-of-type": {
      background: "#367AFF3D",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0 4px 4px 0",
      minWidth: "42px",
    },
  },
  "& > div:last-of-type": {
    // maxHeight: "200px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "0px 0px 4px 4px",
  },
}));

const CheckBoxWrapper = styled(Box)(({ theme }) => ({
  "& > p:first-of-type": {
    color: "#212121",
    marginBottom: "5px",
  },
  "& > p:last-of-type": {
    fontSize: "12px",
    color: "#525252",
  },
}));

const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
  "& .MuiTypography-root": {
    color: "#212121",
  },
  "& .MuiButtonBase-root": {
    padding: "5px",
  },
}));

// ==============================|| PREFERENCE DIALOG ||============================== //
const PreferenceDialog = ({
  open,
  handleClose,
  columns,
  handleColumnChange,
  pageSize,
  setPageSize,
  wrapLinesToggle,
  setWrapLinesToggle,
  stripedRowsToggle,
  setStripedRowsToggle,
  rowClickToggle,
  setRowClickToggle,
  handleDialogSubmit,
  needsRowSelection,
  fromFileExplorer = false,
  pageSizeOptions = [5, 10, 15],
  selectedColumnNames,
}) => {
  console.log(columns, "colll");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    const visibleColumns = columns.filter((column) => !column.hide);
    console.log("Visible columns:", visibleColumns);

    if (selectedColumnNames) {
      dispatch(
        QueryEditorActions.updateQueryTableColumn(
          selectedColumnNames,
          visibleColumns
        )
      );
    }
  }, [columns, selectedColumnNames, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      maxWidth={"sm"}
      fullWidth
      aria-labelledby="preference-dialog"
    >
      <DialogTitle
        id="preference-dialog-title"
        sx={{
          padding: "20px",
          fontSize: "16px",
          fontWeight: 500,
        }}
      >
        Preferences
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers={true} className="tessst">
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          as="div"
        >
          {!fromFileExplorer && (
            <SearchbarWrapper>
              <Typography variant="body1">Attribute columns</Typography>
              <Typography variant="body1">
                Select visible attribute columns
              </Typography>
              <Box>
                <InputBase
                  sx={{
                    flex: 1,
                    "& .MuiInputBase-input": {
                      borderRadius: "4px 0 0 4px",
                      position: "relative",
                      backgroundColor: theme.palette.common.white,
                      border: "1px solid #DCDCDC",
                      fontSize: 15,
                      padding: "7px 12px",
                    },
                  }}
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  placeholder="Search for attribute columns"
                  inputProps={{ "aria-label": "Search for attribute columns" }}
                />
                <Box>
                  <img
                    src={"/assets/images/icons/searchIconAlt.svg"}
                    alt="search"
                  />
                </Box>
              </Box>

              <Box sx={{ overflowY: "scroll", height: "200px" }}>
                {columns
                  ?.filter(
                    (c) =>
                      c.headerName
                        ?.toLowerCase()
                        ?.includes(searchValue?.toLowerCase()) &&
                      c.headerName !== "Name" &&
                      c.headerName !== "Profile Name" &&
                      c.headerName !== "User" &&
                      c.headerName !== "Query Name" &&
                      c.headerName !== "Path" &&
                      c.headerName !== "Execution ID" &&
                      c.headerName !== "Query"
                  )
                  ?.map((column) => (
                    <FormControlLabel
                      key={column?.headerName}
                      control={
                        <CustomToggler
                          defaultChecked={!column?.hide}
                          onChange={(e) =>
                            handleColumnChange(e, column?.headerName)
                          }
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "13px" }}>
                          {column?.headerName}
                        </Typography>
                      }
                    />
                  ))}
              </Box>
            </SearchbarWrapper>
          )}

          {!fromFileExplorer && <Divider />}
          {!fromFileExplorer && (
            <Box>
              <FormControl>
                <FormLabel
                  id="page-size-radio-button-group"
                  sx={{
                    color: "#212121",
                  }}
                >
                  Page Size
                </FormLabel>
                <CustomRadioGroup
                  aria-labelledby="page-size-radio-button-group"
                  value={pageSize}
                  name="page-size-radio-buttons-group"
                  onChange={(event) => setPageSize(Number(event.target.value))}
                >
                  {pageSizeOptions.map((size) => (
                    <FormControlLabel
                      key={size}
                      value={size}
                      control={<Radio />}
                      label={`${size} resources`}
                    />
                  ))}
                </CustomRadioGroup>
              </FormControl>
            </Box>
          )}

          {!fromFileExplorer && <Divider />}
          <FormControlLabel
            control={
              <Checkbox
                checked={wrapLinesToggle}
                onChange={(e) => setWrapLinesToggle(e.target.checked)}
              />
            }
            label={
              <CheckBoxWrapper>
                <Typography variant="body1">Wrap lines</Typography>
                <Typography variant="body1">
                  Enable to wrap table cell content, disable to truncate text.
                </Typography>
              </CheckBoxWrapper>
            }
          />
          <Divider />
          <FormControlLabel
            control={
              <Checkbox
                checked={stripedRowsToggle}
                onChange={(e) => setStripedRowsToggle(e.target.checked)}
              />
            }
            label={
              <CheckBoxWrapper>
                <Typography variant="body1">Striped rows</Typography>
                <Typography variant="body1">
                  Select to add alternating shaded rows
                </Typography>
              </CheckBoxWrapper>
            }
          />
          {needsRowSelection && (
            <Fragment>
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rowClickToggle}
                    onChange={(e) => setRowClickToggle(e.target.checked)}
                  />
                }
                label={
                  <CheckBoxWrapper>
                    <Typography variant="body1">Row click selection</Typography>
                    <Typography variant="body1">
                      Enable to allow clicking anywhere on a row item to select
                      the item
                    </Typography>
                  </CheckBoxWrapper>
                }
              />
            </Fragment>
          )}
          {/* <Divider />
  
            <Box>
              <Typography
                variant="body1"
                sx={{
                  color: "#212121",
                  marginBottom: "5px",
                }}
              >
                First column
              </Typography>
              <FormControl>
                <FormLabel
                  id="first-column-radio-button-group"
                  sx={{
                    color: "#525252",
                    fontSize: "12px",
                  }}
                >
                  Keep the first column(s) visible while horizontally scrolling
                  the table content.
                </FormLabel>
                <CustomRadioGroup
                  aria-labelledby="first-column-radio-button-group"
                  defaultValue={"none"}
                  name="first-column-radio-buttons-group"
                >
                  <FormControlLabel
                    value={"none"}
                    control={<Radio />}
                    label="None"
                  />
                  <FormControlLabel
                    value={"first-column"}
                    control={<Radio />}
                    label="First Column"
                  />
                  <FormControlLabel
                    value={"first-two-column"}
                    control={<Radio />}
                    label="First two column"
                  />
                </CustomRadioGroup>
              </FormControl>
            </Box>
  
            <Divider />
  
            <Box>
              <Typography
                variant="body1"
                sx={{
                  color: "#212121",
                  marginBottom: "5px",
                }}
              >
                Last column
              </Typography>
              <FormControl>
                <FormLabel
                  id="last-column-radio-button-group"
                  sx={{
                    color: "#525252",
                    fontSize: "12px",
                  }}
                >
                  Keep the last column visible while horizontally scrolling the
                  table content.
                </FormLabel>
                <CustomRadioGroup
                  aria-labelledby="last-column-radio-button-group"
                  defaultValue={"none"}
                  name="last-column-radio-buttons-group"
                >
                  <FormControlLabel
                    value={"none"}
                    control={<Radio />}
                    label="None"
                  />
                  <FormControlLabel
                    value={"last-column"}
                    control={<Radio />}
                    label="Last Column"
                  />
                </CustomRadioGroup>
              </FormControl>
            </Box> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          "& > button": {
            textTransform: "none",
            radius: "4px",
            minWidth: "90px",
            margin: "6px 0",
          },
        }}
      >
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDialogSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreferenceDialog;
