export const PUSH_QUERY_TO_DATABRICKS_START = "PUSH_QUERY_TO_DATABRICKS_START";
export const PUSH_QUERY_TO_DATABRICKS_SUCCESS =
  "PUSH_QUERY_TO_DATABRICKS_SUCCESS";
export const PUSH_QUERY_TO_DATABRICKS_FAILURE =
  "PUSH_QUERY_TO_DATABRICKS_FAILURE";

export const FETCH_ALL_QUERY_CONNECTION_LIST_START =
  "FETCH_ALL_QUERY_CONNECTION_LIST_START";
export const FETCH_ALL_QUERY_CONNECTION_LIST_SUCCESS =
  "FETCH_ALL_QUERY_CONNECTION_LIST_SUCCESS";
export const FETCH_ALL_QUERY_CONNECTION_LIST_FAILURE =
  "FETCH_ALL_QUERY_CONNECTION_LIST_FAILURE";

export const SAVE_QUERY_ON_DATABRICKS_START = "SAVE_QUERY_ON_DATABRICKS_START";
export const SAVE_QUERY_ON_DATABRICKS_SUCCESS =
  "SAVE_QUERY_ON_DATABRICKS_SUCCESS";
export const SAVE_QUERY_ON_DATABRICKS_FAILURE =
  "SAVE_QUERY_ON_DATABRICKS_FAILURE";

export const QUERY_EDITOR_CANCEL_EXECUTION_START =
  "QUERY_EDITOR_CANCEL_EXECUTION_START";
export const QUERY_EDITOR_CANCEL_EXECUTION_SUCCESS =
  "QUERY_EDITOR_CANCEL_EXECUTION_SUCCESS";
export const QUERY_EDITOR_CANCEL_EXECUTION_FAILURE =
  "QUERY_EDITOR_CANCEL_EXECUTION_FAILURE";

export const FETCH_RECENT_QUERIES_START = "FETCH_RECENT_QUERIES_START";
export const FETCH_RECENT_QUERIES_SUCCESS = "FETCH_RECENT_QUERIES_SUCCESS";
export const FETCH_RECENT_QUERIES_FAILURE = "FETCH_RECENT_QUERIES_FAILURE";

export const DELETE_RECENT_QUERIES_START = "DELETE_RECENT_QUERIES_START";
export const DELETE_RECENT_QUERIES_SUCCESS = "DELETE_RECENT_QUERIES_SUCCESS";
export const DELETE_RECENT_QUERIES_FAILURE = "DELETE_RECENT_QUERIES_FAILURE";

export const ON_CHANGE_APPLY_RECENT_QUERY_FILTER =
  "ON_CHANGE_APPLY_RECENT_QUERY_FILTER";

export const FETCH_SAVED_QUERIES_START = "FETCH_SAVED_QUERIES_START";
export const FETCH_SAVED_QUERIES_SUCCESS = "FETCH_SAVED_QUERIES_SUCCESS";
export const FETCH_SAVED_QUERIES_FAILURE = "FETCH_SAVED_QUERIES_FAILURE";

export const DELETE_SAVED_QUERIES_START = "DELETE_SAVED_QUERIES_START";
export const DELETE_SAVED_QUERIES_SUCCESS = "DELETE_SAVED_QUERIES_SUCCESS";
export const DELETE_SAVED_QUERIES_FAILURE = "DELETE_SAVED_QUERIES_FAILURE";

export const UPDATE_SAVED_QUERIES_START = "UPDATE_SAVED_QUERIES_START";
export const UPDATE_SAVED_QUERIES_SUCCESS = "UPDATE_SAVED_QUERIES_SUCCESS";
export const UPDATE_SAVED_QUERIES_FAILURE = "UPDATE_SAVED_QUERIES_FAILURE";

export const ON_CHANGE_APPLY_SAVED_QUERY_FILTER =
  "ON_CHANGE_APPLY_SAVED_QUERY_FILTER";

export const ADD_QUERY_EDITOR_TAB = "ADD_QUERY_EDITOR_TAB";
export const REMOVE_QUERY_EDITOR_TAB = "REMOVE_QUERY_EDITOR_TAB";
export const ON_CHANGE_APPLY_QUERY_TAB_VALUE =
  "ON_CHANGE_APPLY_QUERY_TAB_VALUE";

export const ON_CHANGE_QUERY_TAB_ACTIVE = "ON_CHANGE_QUERY_TAB_ACTIVE";
export const ON_CHANGE_APPLY_QUERY_FILTER = "ON_CHANGE_APPLY_QUERY_FILTER";

export const FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START =
  "FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START";
export const FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS =
  "FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS";
export const FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE =
  "FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE";

export const FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START =
  "FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START";
export const FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS =
  "FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS";
export const FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE =
  "FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE";

export const FETCH_COLUMN_FROM_TABLE_VIEW_START =
  "FETCH_COLUMN_FROM_TABLE_VIEW_START";
export const FETCH_COLUMN_FROM_TABLE_VIEW_SUCCESS =
  "FETCH_COLUMN_FROM_TABLE_VIEW_SUCCESS";
export const FETCH_COLUMN_FROM_TABLE_VIEW_FAILURE =
  "FETCH_COLUMN_FROM_TABLE_VIEW_FAILURE";

export const FETCH_COLUMN_FROM_TABLE_START = "FETCH_COLUMN_FROM_TABLE_START";
export const FETCH_COLUMN_FROM_TABLE_SUCCESS =
  "FETCH_COLUMN_FROM_TABLE_SUCCESS";
export const FETCH_COLUMN_FROM_TABLE_FAILURE =
  "FETCH_COLUMN_FROM_TABLE_FAILURE";

export const FETCH_TABLE_VIEWS_FROM_SCHEMA_START =
  "FETCH_TABLE_VIEWS_FROM_SCHEMA_START";
export const FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS =
  "FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS";
export const FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE =
  "FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE";

export const UPDATE_DATE_FORMAT_TOGGLER_QUERY =
  "UPDATE_DATE_FORMAT_TOGGLER_QUERY";

export const UPDATE_QUERY_TABLE_COLUMN = "UPDATE_QUERY_TABLE_COLUMN";

export const UPDATE_QUERY_TABLE_SELECTED_ROW =
  "UPDATE_QUERY_TABLE_SELECTED_ROW";

export const GET_SAVED_QUERY_BY_ID_START = "GET_SAVED_QUERY_BY_ID_START";
export const GET_SAVED_QUERY_BY_ID_SUCCESS = "GET_SAVED_QUERY_BY_ID_SUCCESS";
export const GET_SAVED_QUERY_BY_ID_FAILURE = "GET_SAVED_QUERY_BY_ID_FAILURE";

export const GET_COMPLETED_SAVE_QUERY_START = "GET_COMPLETED_SAVE_QUERY_START";
export const GET_COMPLETED_SAVE_QUERY_SUCCESS =
  "GET_COMPLETED_SAVE_QUERY_SUCCESS";
export const GET_COMPLETED_SAVE_QUERY_FAILURE =
  "GET_COMPLETED_SAVE_QUERY_FAILURE";
