import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  InputBase,
  TablePagination,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Grid,
} from "@mui/material";

import { CustomInputBase } from "components/CustomInput";
import AlertDialog from "components/AlertDialog";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { CustomBtn } from "components/CustomInput";
import { LuSearch } from "react-icons/lu";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import { createPathToDisplay, formatDateAndTime } from "utils/helper";
import * as PipelineActions from "store/pipeline/pipeline.actions";
import * as AlertActions from "store/alert/alert.actions";
import { useLocation, useNavigate } from "react-router-dom";

const PipelineList = () => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [activeTab, setActiveTab] = useState("browse");
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [openDelDialog, setOpenDelDialog] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentAction, setCurrentAction] = useState([]);
  const [newPipelineName, setNewPipelineName] = useState("");
  // const [deletePipeline, setDeletePipeline] = React.useState("");

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "action-popover" : undefined;

  const {
    pipRes: { pipelines, limit, isPipelineFetching, recent },
  } = useSelector((state) => state.pipeline);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    if (gridRef.current) {
      gridRef.current.setQuickFilter(e.target.value);
    }
  };
  const handlePageChange = (event, newPage) => {
    dispatch(
      PipelineActions.onChangeApplyPipelineResponse([
        { key: "offset", value: newPage * limit },
      ])
    );
    setPage(newPage);
  };

  useEffect(() => {
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);
  useEffect(() => {
    dispatch(PipelineActions.fetchAllPipeline());
    dispatch(PipelineActions.fetchRecentPipeline());
  }, [page]);

  useEffect(() => {
    if (activeTab === "recent") {
      setRowData(recent.results);
    } else {
      setRowData(pipelines?.results);
    }
  }, [activeTab, pipelines, recent]);

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: "Pipeline Name",
        field: "title",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        resizable: true,

        minWidth: 250,
      },
      {
        headerName: "Path",
        field: "folder_path",
        resizable: true,
        minWidth: 300,
        cellRenderer: (params) => {
          return (
            <Button
              sx={{
                textTransform: "none",
                textDecoration: "underline",
                cursor: "pointer",
                color: "primary.light",
                display: "flex",
                justifyContent: "flex-start",
                padding: 0,
                paddingTop: "3px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                fontWeight: 400,
                lineHeight: "19px",
              }}
              onClick={() => {
                const navigationPath = params.data.folder_path
                  ?.map(
                    ({ id, name }) =>
                      `id=${id}__name=${encodeURIComponent(name)}`
                  )
                  ?.join("&");

                if (params?.data?.folder_path) {
                  return navigate(`/manage/file-explorer/?${navigationPath}`);
                }
                return "";
              }}
            >
              {createPathToDisplay(params.value)}
            </Button>
          );
        },
      },
      {
        headerName: "Created By",
        field: "created_by",
      },
      {
        headerName: "Created At",
        field: "created_at",

        cellRenderer: (params) => {
          return <Box>{formatDateAndTime(params.value)}</Box>;
        },
      },
      {
        headerName: "Last Modified By",
        field: "updated_by",
      },
      {
        headerName: "Last Modified On",
        field: "updated_at",
        cellRenderer: (params) => {
          return <Box>{formatDateAndTime(params.value)}</Box>;
        },
        resizable: false,
      },
    ];
  }, []);
  const icons = useMemo(
    () => ({
      sortAscending: '<img src="/assets/images/icons/arrow-up.svg" />',
      sortDescending: '<img src="/assets/images/icons/arrow-down.svg" />',
    }),
    []
  );
  const getRowClass = useCallback(() => "row-class", []);
  const onGridReady = (params) => {
    gridRef.current = params.api;
  };
  const defaultColDef = useMemo(
    () => ({
      width: "100%",
      minWidth: 160,
      flex: 1,
      headerClass: "header-class",
      cellStyle: (params) => {
        const value = params.value;
        if (typeof value === "string") {
          return {
            textAlign: "left",
          };
        } else if (typeof value === "number") {
          return {
            textAlign: "right",
          };
        } else {
          return { textAlign: "left" };
        }
      },
    }),
    []
  );

  const handleActionClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl1(null);
  };

  const onSelectionChanged = () => {
    const srs = gridRef.current.getSelectedRows();
    if (srs?.length > 0) {
      setSelectedRows(srs);
    } else {
      setSelectedRows([]);
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          margin: "0 40px",
        }}
      >
        <Box
          sx={{
            border: "1px solid #e1e1e1",

            borderRadius: "8px",
            backgroundColor: "white",
          }}
        >
          {/* utilities */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: ".5rem",
              padding: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",

                width: "100%",
              }}
            >
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "22px" }}
              >
                Pipeline
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: "6px",
                  marginLeft: "50px",
                  "& > button": {
                    fontWeight: 400,
                    color: "#000000CC",
                    textTransform: "capitalize",
                    fontSize: "14px",
                  },

                  "& img": {
                    width: "18px",
                    height: "18px",
                  },
                }}
              >
                <Button
                  startIcon={
                    <img
                      src="/assets/images/icons/openIcon.svg"
                      alt="save"
                      style={{ height: "14px" }}
                    />
                  }
                  onClick={() => {
                    setActiveTab("recent");
                  }}
                  sx={{
                    padding: "5px 15px",
                    backgroundColor:
                      activeTab === "recent" ? "#367AFF1A" : "none",
                  }}
                >
                  Recent
                </Button>{" "}
                <Button
                  startIcon={
                    <img
                      src="/assets/images/icons/browser.svg"
                      alt="save"
                      style={{ height: "16px" }}
                    />
                  }
                  onClick={() => {
                    setActiveTab("browse");
                  }}
                  sx={{
                    padding: "5px 15px",
                    backgroundColor:
                      activeTab === "browse" ? "#367AFF1A" : "none",
                  }}
                >
                  Browse
                </Button>
              </Box>

              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CustomBtn
                  variant="outlined"
                  sx={{
                    width: "26px",
                    height: "26px",
                    padding: 0,
                    minWidth: "26px",
                    background: "#367AFF0D",
                  }}
                  onClick={() => {
                    activeTab === "recent"
                      ? dispatch(PipelineActions.fetchRecentPipeline())
                      : dispatch(PipelineActions.fetchAllPipeline());
                  }}
                >
                  <img src={"/assets/images/icons/refresh.svg"} alt="refresh" />
                </CustomBtn>
                <CustomBtn
                  variant="outlined"
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleActionClick}
                  sx={{
                    height: "26px",
                    fontWeight: 400,
                  }}
                >
                  Action
                </CustomBtn>

                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<AddRoundedIcon />}
                  sx={{
                    width: "auto",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    borderRadius: "4px",
                    textTransform: "none",
                    height: "26px",
                    fontWeight: 400,
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    // navigate("/manage/pipeline/query-chain");
                    window
                      .open(`/manage/pipeline/query-chain`, "_blank")
                      .focus();
                  }}
                >
                  Add New Pipeline
                </Button>
              </Box>
            </Box>{" "}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Paper
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "235px",
                  maxHeight: "28px",
                  border: "1px solid #0000001A",
                  boxShadow: "none",
                  borderRadius: "4px",
                  position: "relative",
                  padding: "0rem ",
                }}
              >
                <IconButton
                  aria-label="search"
                  disabled
                  sx={{ padding: "0 .2rem" }}
                >
                  <LuSearch style={{ height: 15, color: "#636262" }} />
                </IconButton>
                <InputBase
                  placeholder="Search..."
                  inputProps={{
                    "aria-label": "search databases",
                    maxLength: 30,
                  }}
                  value={searchValue}
                  onChange={handleSearch}
                />
                {searchValue && (
                  <IconButton
                    type="button"
                    size="small"
                    sx={{ position: "absolute", right: "0" }}
                  >
                    <ClearRoundedIcon sx={{ fontSize: "18px" }} />
                  </IconButton>
                )}
              </Paper>
              {/* <CustomBtn
                variant="outlined"
                sx={{
                  height: "26px",
                  fontWeight: 400,
                  minWidth: "116px",
                  marginLeft: "10px",
                }}
                endIcon={
                  <ArrowDropDownIcon
                    sx={
                      {
                        //   transform: !open ? "rotate(0deg)" : "rotate(-180deg)",
                      }
                    }
                  />
                }
                // onClick={handleClick}
              >
                All States 
              </CustomBtn>*/}
              <Box
                sx={{
                  display: "flex",
                  marginLeft: "auto",
                  alignItems: "center",
                  justifyContent: "end",
                  height: "26px",
                  "& > button": {
                    maxHeight: "26px",
                    maxWidth: "26px",
                    border: "1px solid #0000001A",
                  },
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={
                    activeTab === "recent"
                      ? recent.count ?? 0
                      : pipelines?.count ?? 0
                  }
                  rowsPerPage={limit ?? 0}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={() => {}}
                />

                <CustomBtn
                  variant="outlined"
                  sx={{ padding: "4px 0px", minWidth: "26px" }}
                  //   onClick={() => setOpenPreferenceDialog(true)}
                >
                  <img
                    src={"/assets/images/icons/smallSettingIcon.svg"}
                    alt="settings"
                  />
                </CustomBtn>
              </Box>
            </Box>
          </Box>
          {/* table */}
          <Box
            className="ag-theme-custom"
            sx={{
              width: "100%",
              height: `${
                rowData?.length > 0 ? rowData.length * 27 + 85 : 150
              }px`,

              marginBottom: 3,
              [`& .strippedRows-effect`]: {
                backgroundColor: theme.palette.grey[200],
              },
              " .ag-checkbox-input-wrapper.ag-checked::after": {
                color: "#fff !important",
              },
              " .ag-checkbox-input-wrapper.ag-indeterminate::after": {
                color: "#fff !important",
              },
              "& .ag-header-cell-resize::after": {
                background: "#0000004D",
                width: 0.1,
                height: "57%",
                margin: "-5px 0px",
              },
              "& .ag-header-cell-label": {
                gap: 0,
              },
              "& .ag-header-cell-label img": {
                marginTop: "4px",
              },

              "& .row-class": {
                cursor: "pointer",
              },
            }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              icons={icons}
              rowHeight={28}
              headerHeight={30}
              suppressRowClickSelection={true}
              getRowClass={getRowClass}
              onGridReady={onGridReady}
              rowSelection="multiple"
              onSelectionChanged={onSelectionChanged}
              onRowDoubleClicked={(params) => {
                window.location.href = `/manage/pipeline/query-chain/${params.node.data.id}`;
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* ======================================= Additional Popups===================================== */}

      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            border: "1px solid #0000001A",
            boxShadow: "0px 0px 4px 0px #0000001F",
            borderRadius: "4px",
            minWidth: "160px",
            marginTop: "3px",
          },
        }}
      >
        <List sx={{ padding: "2px 0px", cursor: "pointer" }}>
          <ListItem
            disablePadding
            sx={{
              "&: hover": {
                background: "#367AFF14",
              },
            }}
          >
            <ListItemButton
              sx={{ py: 0, px: "10px" }}
              disabled={selectedRows?.length === 0}
              onClick={() => {
                setOpenDelDialog(true);
                setCurrentAction("delete");
              }}
            >
              <ListItemText primary="Delete" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              "&: hover": {
                background: "#367AFF14",
              },
            }}
          >
            <ListItemButton
              sx={{ py: 0, px: "10px" }}
              disabled={selectedRows?.length !== 1}
              onClick={() => {
                setCurrentAction("rename");
                setOpenDelDialog(true);
              }}
            >
              <ListItemText primary="Rename" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <AlertDialog
        dialogTitle={"Delete Pipeline"}
        open={openDelDialog}
        currentAction={currentAction}
        btnName={currentAction === "delete" ? "Delete" : "Rename"}
        handleClose={() => {
          setOpenDelDialog(false);
          setCurrentAction("");
        }}
        handleAction={() => {
          console.log("sdfsdfsdf", selectedRows);
          if (currentAction === "rename") {
            dispatch(
              PipelineActions.renamePipelineName(
                selectedRows[0]?.id,
                newPipelineName
              )
            );
          }

          if (currentAction === "delete") {
            dispatch(
              PipelineActions.deletePipeline(
                selectedRows.map((pipeline) => pipeline.id)
              )
            );
          }
          if (activeTab === "recent") {
            dispatch(PipelineActions.fetchRecentPipeline());
          }
          if (activeTab === "browse") {
            dispatch(PipelineActions.fetchAllPipeline());
          }

          setOpenDelDialog(false);
          setCurrentAction("");
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: "#212121",
            "& > span": {
              fontWeight: 500,
            },
          }}
        >
          {currentAction === "delete" &&
            `  Are you sure you want to delete the selected pipeline(s)?`}{" "}
          {currentAction === "rename" &&
            `Please Enter the new name for the selected pipeline.`}
        </Typography>
        {currentAction === "rename" && (
          <Grid item xs={9}>
            <CustomInputBase
              fullWidth
              id="rename"
              value={newPipelineName}
              onChange={(e) => setNewPipelineName(e.target.value)}
              size="small"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "0px 10px",
                  fontSize: "14px",
                  background: "transparent",
                  maxHeight: "28px",
                  borderRadius: "4px",
                  marginTop: "1.5rem",
                },
              }}
            />
          </Grid>
        )}
      </AlertDialog>
    </Fragment>
  );
};

export default PipelineList;
