import * as React from "react";

// third party libraries
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import { IoInformationOutline } from "react-icons/io5";
import Box from "@mui/material/Box";

// project imports
import Sidebar from "./components/Sidebar";
import MobileSidebar from "./components/MobileSidebar";
import MobileTopbar from "./components/MobileTopbar";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "./components/Footer";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import docsRoutingMapper from "utils/docsRoutingMapper";

// styles
export const drawerWidth = 230;

export const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6.25)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6.25)})`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  overflow: "hidden",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  padding: theme.spacing(0, 1),
  paddingLeft: open ? "15px" : "10px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const ArrowWrapper = styled("div")(({ theme, open }) => ({
  position: "absolute",
  top: theme.spacing(2.5),
  transition: "left 0.4s ease",
  left: `calc(${theme.spacing(open ? 27.5 : 5.25)})`,
  zIndex: 996,
  height: "24px",
  width: "24px",
  background: "#FAFAFA",
  boxShadow: "0px 1px 2px 0px #00000040",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "& > img": {
    transform: open ? "" : "rotate(180deg)",
  },
}));

export const FooterWrapper = styled("div")(({ theme, open }) => ({
  position: "fixed",
  bottom: 0,
  zIndex: theme.zIndex.drawer + 1,
  transition: "all 0.4s",
  width: `calc(100% - ${open ? drawerWidth : 50}px)`,
  [theme.breakpoints.down(768)]: {
    width: "100%",
  },
  [theme.breakpoints.between(768, 1024)]: {
    width: `calc(100% - 50px)`,
    zIndex: 990,
  },
}));

export const InfoWrapper = styled("div")(({ theme, open }) => ({
  borderLeft: "1px solid #0000001A",
  background: "#FBFBFB",
  minWidth: "44px",
  maxWidth: "44px",
  width: "44px",
  display: "flex",
  justifyContent: "center",
  paddingTop: "23px",
  "& > div": {
    border: "1px solid #0000001A",
    borderRadius: "4px",
    display: "flex",
    height: "32px",
    width: "32px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));

// ==============================|| ADMIN LAYOUT ||============================== //
export default function AdminLayout() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1024));
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isBigTab = useMediaQuery(theme.breakpoints.between(768, 1024));
  const [open, setOpen] = React.useState(true);
  const [settingsToggler, setSettingsToggler] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(!isTablet);
  }, [isTablet]);

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <CssBaseline />
      {/* <Topbar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
      /> */}
      {isMobile && <MobileTopbar handleDrawerOpen={handleDrawerOpen} />}
      {!isMobile ? (
        <>
          <Sidebar
            open={open}
            handleDrawerClose={handleDrawerClose}
            setSettingsToggler={setSettingsToggler}
            settingsToggler={settingsToggler}
          />
          <ArrowWrapper onClick={() => setOpen(!open)} open={open}>
            <img src={"/assets/images/icons/arrow.svg"} alt="arrow" />
          </ArrowWrapper>
        </>
      ) : (
        <MobileSidebar open={open} setOpen={setOpen} />
      )}
      <Box display={"flex"} width={"100%"} overflow={"hidden"}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#F1F2F7",

            overflowX: "hidden",
            overflowY: "auto",
            position: "relative",
            height: "100vh",
            display: "flex",
            paddingTop: isMobile ? "75px" : "40px",
            flexDirection: "column",
            paddingBottom: "22px",
            scrollBehavior: "smooth",
            paddingLeft: isBigTab ? "50px" : "",
          }}
        >
          {/* <DrawerHeader /> */}
          <Outlet />

          {!isMobile || !open ? <Footer open={open} /> : null}
        </Box>
        {!isMobile && (
          <InfoWrapper>
            <IconButton
              onClick={() => {
                console.log(location.pathname);
                const docsRoute = docsRoutingMapper(
                  location.pathname,
                  id ? true : false
                );
                if (docsRoute) {
                  // navigate(docsRoute)
                  window.open(docsRoute, "_blank");
                }
              }}
              sx={{
                height: "30px",
                width: "30px",
                borderRadius: "4px",
                border: "1px solid #0000001A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // zIndex: 9999,
              }}
            >
              <IoInformationOutline />
            </IconButton>
          </InfoWrapper>
        )}
      </Box>
    </Box>
  );
}
