import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { closeAlert } from "store/alert/alert.actions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} sx={{width:'400px'}} />;
});

const CustomAlert = () => {
  const { openAlert, severity, message, alertTitle } = useSelector(
    (state) => state.alert
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (openAlert) {
      enqueueSnackbar(
        <>
          {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
          {message}
        </>,
        {
          variant: severity,
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          onClose: () => dispatch(closeAlert()),
          content: (key, message) => (
            <Alert onClose={() => closeSnackbar(key)} severity={severity} >
              {message}
            </Alert>
          ),
        }
      );
    }
  }, [
    openAlert,
    severity,
    message,
    alertTitle,
    enqueueSnackbar,
    dispatch,
    closeSnackbar,
  ]);

  return null;
};

export default CustomAlert;
