const typography = {
  // fontFamily: ["Roboto", "sans-serif"].join(","),
  fontFamily: ["DM Sans", "sans-serif"].join(","),
  h6: {
    fontSize: "1rem", // 16px
    lineHeight: "12px",
    fontWeight: "normal",
  },
  h5: {
    fontSize: "1.125rem", // 18px
    lineHeight: "21.78px",
    fontWeight: 400,
  },
  h4: {
    fontSize: "1.25rem", // 20px
    fontWeight: "bold",
    lineHeight: "25px",
  },
  h3: {
    fontSize: "1.5rem", // 24px
    fontWeight: 600,
    lineHeight: "36px",
    color: "#212121",
  },
  h2: {
    fontSize: "1.875rem", // 30px
    lineHeight: "45px",
    fontWeight: "normal",
  },
  h1: {
    fontSize: "40px",
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  body1: {
    fontSize: "0.875rem", // 14px
    fontWeight: 400,
    lineHeight: "normal",
  },
  body2: {
    letterSpacing: "0em",
    fontWeight: 400,
    lineHeight: "1.5em",
  },
};
export default typography;
