// First we need to import axios.js
import axios from "axios";

const API_URL = `https://dev.app.datafuseai.com/api/v1`;
// const API_URL =
//   "https://73c2-2407-1400-aa0f-38b0-d083-7ab3-52d5-370a.ngrok-free.app/api/v1";

// const API_URL = `http://127.0.0.1:8000/api/v1`;
const instance = axios.create({
  // .. where we make our configurations
  baseURL: API_URL,
});

export const setAuthToken = (token) => {
  if (token) {
    instance.defaults.headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers["Authorization"];
  }
};

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refresh_token");
      if (refreshToken) {
        const response = await axios.post(`${API_URL}/users/get_accesstoken/`, {
          refresh_token: refreshToken,
        });
        if (response.status === 200) {
          localStorage.setItem("access_token", response.data.access_token);
          return instance(originalRequest);
        }
        if (response.status === 401) {
          redirectToLogin();
        }
      }
    }
    // if (error.response.status === 403) {
    //   redirectToLogin();
    // }
    return Promise.reject(error);
  }
);
const redirectToLogin = () => {
  console.log("unable to redirect to login");
  localStorage.clear();
  window.location.href = "/auth/login";
};
export default instance;
