import { getFormattedTime } from "utils/helper";
import * as ClusterType from "./cluster.types";

const INITIAL_STATE = {
  clusterList: {
    updateCreatedToggler: 0,
    updateModifiedToggler: 0,
    list: [],
    searchParams: "",
    loading: false,
    error: null,
    status: null,
    offset: 0,
    limit: 10,
    strippedRows: false,
    rowClickSelection: false,
    wrapLines: false,
    count: null,
    hasMore: false,
  },
  providerType: null,
  cluster: {
    loading: false,
    error: null,
    data: {},
    connectionStatus: null,
  },
  databricks: {
    clusterType: null,
    jobCluster: {
      loading: false,
      error: null,
      engineId: null,
      jarLibraries: [],
      data: {
        jobId: null,
        runId: null,
        clusterId: null,
        clusterName: "",
        provider: "databricks",
        credentials: {
          url: "",
          token: "",
        },
        libraries: {
          mavenLibraries: [],
          pypiLibraries: [],
          jarLibraries: [],
        },
      },
    },
    computeCluster: {},
    serverlessCluster: {},
  },
  soloEngine: {
    loading: false,
    error: null,
    data: null,
  },

  datafuseaiConfig: {
    loading: false,
    error: null,
    data: null,
  },
};

const clusterReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ClusterType.FETCH_CLUSTER_LIST_START:
      return {
        ...state,
        cluster: {
          ...state.cluster,
        },
      };
    case ClusterType.FETCH_CLUSTER_LIST_SUCCESS:
      return {
        ...state,
        cluster: {
          ...state.cluster,
          ...payload,
        },
      };

    case ClusterType.FETCH_CLUSTER_LIST_FAILURE:
      return {
        ...state,
        cluster: {
          ...state.cluster,
        },
      };

    case ClusterType.TEST_CONNECTION_START:
      return {
        ...state,
        cluster: {
          ...state.cluster,
          connectionStatus: "start",
        },
      };
    case ClusterType.TEST_CONNECTION_SUCCESS:
      return {
        ...state,
        cluster: {
          ...state.cluster,

          connectionStatus: "success",
        },
      };

    case ClusterType.TEST_CONNECTION_FAILURE:
      return {
        ...state,
        cluster: {
          ...state.cluster,
          connectionStatus: "failure",
        },
      };

    case ClusterType.CREATE_NEW_CLUSTER_START:
      return {
        ...state,
        cluster: {
          ...state.cluster,
          loading: true,
          error: null,
        },
      };

    case ClusterType.CREATE_NEW_CLUSTER_FAILURE:
      return {
        ...state,
        cluster: {
          ...state.cluster,
          loading: false,
          error: payload,
        },
      };

    case ClusterType.CREATE_NEW_CLUSTER_SUCCESS:
      return {
        ...state,
        cluster: {
          ...state.cluster,
          total_clusters: state.cluster.total_clusters + 1,
          clusters: [payload, ...state.cluster.clusters],
          loading: false,
          connectionStatus: "success",
        },
      };

    case ClusterType.DELETE_CLUSTER__COMPUTE_DATABRICKS_SUCCESS:
      const id = payload;
      return {
        ...state,
        cluster: {
          ...state.cluster,
          total_clusters: state.cluster.total_clusters - 1,
          clusters: state.cluster.clusters.filter((c) => c.cluster_id !== id),
        },
      };

    case ClusterType.UPDATE_SELECTED_PROVIDER_PARAMS:
      const { updates } = action.payload;
      return {
        ...state,
        cluster: {
          ...state.cluster,
          ...updates.reduce((acc, { key, value }) => {
            acc[key] = value;
            return acc;
          }, {}),
        },
      };

    case ClusterType.FETCH_ALL_CLUSTERS_START:
      return {
        ...state,
        clusterList: {
          ...state.clusterList,
          loading: true,
        },
      };

    case ClusterType.UPDATE_NEW_CLUSTER_SUCCESS:
      const { updatedCluster, clusterId } = action.payload;
      return {
        ...state,
        cluster: {
          ...state.cluster,
          loading: false,
          connectionStatus: "success",
          clusters: state.cluster.clusters.map((item) =>
            item?.cluster_id === clusterId
              ? {
                  ...item,
                  cluster_name: updatedCluster.name,
                  min_workers: updatedCluster.min_worker,
                  max_workers: updatedCluster.max_worker,
                  node_type_id: updatedCluster.node_type_id,
                }
              : item
          ),
        },
      };

    case ClusterType.UPDATE_CLUSTER_STATUS_SUCCESS:
      const { status, statusId } = action.payload;
      return {
        ...state,
        cluster: {
          ...state.cluster,
          clusters: state.cluster.clusters.map((item) =>
            item?.cluster_id === statusId
              ? {
                  ...item,
                  state: "PENDING",
                }
              : item
          ),
        },
      };

    case ClusterType.ON_CHANGE_ENGINE_FILTER:
      const { keys, values } = action.payload;

      return {
        ...state,
        clusterList: {
          ...state.clusterList,
          [keys]: values,
          loading: false,
        },
      };

    case ClusterType.FETCH_ALL_CLUSTERS_SUCCESS:
      const formattedList = payload?.results?.map((r) => ({
        ...r,
        created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
        updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
      }));
      return {
        ...state,
        clusterList: {
          ...state.clusterList,
          list: formattedList,
          loading: false,
          count: payload?.count,
          hasMore: payload?.next ? true : false,
        },
      };

    case ClusterType.FETCH_ALL_CLUSTERS_FAILURE:
      return {
        ...state,
        clusterList: {
          ...state.clusterList,
          error: payload,
          loading: false,
        },
      };

    case ClusterType.GET_JAR_LIBRARIES_SUCCESS:
      return {
        ...state,
        databricks: {
          ...state.databricks,
          jobCluster: {
            ...state.databricks.jobCluster,
            jarLibraries: payload,
          },
        },
      };

    case ClusterType.DATE_FORMAT_TOGGLER:
      const { column } = action.payload;
      return {
        ...state,
        clusterList: {
          ...state.clusterList,
          [column]: (state.clusterList[column] + 1) % 3,
        },
      };

    case ClusterType.GET_ENGINE_BY_ID_START:
      return {
        ...state,
        soloEngine: {
          ...state.soloEngine,
          loading: true,
        },
      };

    case ClusterType.GET_ENGINE_BY_ID_SUCCESS:
      return {
        ...state,
        soloEngine: {
          ...state.soloEngine,
          data: payload,
          loading: false,
        },
      };

    case ClusterType.GET_ENGINE_BY_ID_FAILURE:
      return {
        ...state,
        soloEngine: {
          ...state.soloEngine,
          error: payload,
          loading: false,
        },
      };

    case ClusterType.CLEAR_SOLO_ENGINE:
      return {
        ...state,
        soloEngine: {
          ...state.soloEngine,
          data: null,
        },
      };

    case ClusterType.GET_DATAFUSEAI_CONFIG_START:
      return {
        ...state,
        datafuseaiConfig: {
          ...state.datafuseaiConfig,
          loading: true,
        },
      };

    case ClusterType.GET_DATAFUSEAI_CONFIG_SUCCESS:
      return {
        ...state,
        datafuseaiConfig: {
          ...state.datafuseaiConfig,
          data: payload,
          loading: false,
        },
      };

    case ClusterType.GET_DATAFUSEAI_CONFIG_FAILURE:
      return {
        ...state,
        datafuseaiConfig: {
          ...state.datafuseaiConfig,
          error: payload,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default clusterReducer;
