import React from "react";

// third party libraries
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

// project imports
import * as DriverActions from "store/driver/driver.actions";
import * as ConnectionActions from "store/connection/connection.actions";

// styles
export const StyledDiv = styled("div")(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline dotted",
  },
  "&:active": {
    textDecoration: "underline dotted",
  },
}));

// ==============================|| DATE FORMATTER ||============================== //
const DateFormatter = ({ value, type }) => {
  const dispatch = useDispatch();

  const { updateDateToggler: formatIndexDriver } = useSelector(
    (state) => state.driver.driverUses
  );

  const { updateDateToggler: formatIndexConnection } = useSelector(
    (state) => state.connection.connectionUses
  );

  const onToggleFormatDriver = () => {
    dispatch(DriverActions.updateDateFormatToggler("updateDateToggler"));
  };

  const onToggleFormatConnection = () => {
    dispatch(ConnectionActions.updateDateFormatToggler("updateDateToggler"));
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <StyledDiv
        onClick={
          type === "driver" ? onToggleFormatDriver : onToggleFormatConnection
        }
      >
        {type === "driver"
          ? value[formatIndexDriver]
          : value[formatIndexConnection]}
      </StyledDiv>
    </Box>
  );
};

export default DateFormatter;
