export const FETCH_LICENSE_INFO_START = "FETCH_LICENSE_INFO_START";
export const FETCH_LICENSE_INFO_SUCCESS = "FETCH_LICENSE_INFO_SUCCESS";
export const FETCH_LICENSE_INFO_FAILURE = "FETCH_LICENSE_INFO_FAILURE";

export const EXTEND_SERVER_LICENSE_START = "EXTEND_SERVER_LICENSE_START";
export const EXTEND_SERVER_LICENSE_SUCCESS = "EXTEND_SERVER_LICENSE_SUCCESS";
export const EXTEND_SERVER_LICENSE_FAILURE = "EXTEND_SERVER_LICENSE_FAILURE";

export const UPLOAD_LICENSE_START = "UPLOAD_LICENSE_START";
export const UPLOAD_LICENSE_SUCCESS = "UPLOAD_LICENSE_SUCCESS";
export const UPLOAD_LICENSE_FAILURE = "UPLOAD_LICENSE_FAILURE";
