// Redux import
import { combineReducers } from "redux";

// Project imports
import alertReducer from "./alert/alert.reducer";
import authReducer from "./auth/auth.reducer";
import driverReducer from "./driver/driver.reducer";
import licenseReducer from "./license/license.reducer";
import connectionReducer from "./connection/connection.reducer";
import userReducer from "./user/user.reducer";
import fileExplorerReducer from "./fileExplorer/fileExplorer.reducer";
import queryEditorReducer from "./queryeditor/queryeditor.reducer";
import pipelineReducer from "./pipeline/pipeline.reducer";
import auditLogReducer from "./auditLog/auditLog.reducer";
import clusterReducer from "./cluster/cluster.reducer";
import jobsReducer from "./jobs/jobs.reducer";

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  user: userReducer,
  driver: driverReducer,
  license: licenseReducer,
  connection: connectionReducer,
  queryEditor: queryEditorReducer,
  fileExplorer: fileExplorerReducer,
  pipeline: pipelineReducer,
  jobs: jobsReducer,
  auditLog: auditLogReducer,
  cluster: clusterReducer,
});

export default rootReducer;
