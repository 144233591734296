import {
  combinedUsersAndGroups,
  combinedUsersAndGroupsPermission,
  getFormattedTime,
} from "utils/helper";
import * as FileExplorerType from "./fileExplorer.types";

const INITIAL_STATE = {
  error: null,
  loading: false,

  breadcrumb: [],
  breadcrumbNav: [],

  sharedWithUsersAndGroups: {
    list: [],
    loading: false,
  },

  usersAndGroups: {
    list: [],
    loading: false,
    searchParams: "",
  },

  fileExplorerItemsNav: {
    list: [],
    loading: false,
    limit: 50,
    searchParams: "",
  },

  fileExplorerItemsNavBrowse: {
    list: [],
    loading: false,
    limit: 50,
    searchParams: "",
  },

  fileExplorerItems: {
    list: [],
    hasMore: false,
    strippedRows: false,
    loading: false,
    wrapLines: false,
    limit: 50,
    searchParams: "",
    count: null,
    updateCreatedToggler: 0,
    updateModifiedToggler: 0,
  },
};

const fileExplorerReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FileExplorerType.FETCH_FILE_EXPLORER_START:
      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          loading: true,
        },
      };

    case FileExplorerType.FETCH_FILE_EXPLORER_SUCCESS:
      const formattedList = payload?.results?.subfolders?.map((r) => ({
        ...r,
        created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
        updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
      }));
      const formattedListSystemFiles =
        payload?.results?.files?.system_files.map((r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        }));
      const formattedListUserFiles =
        payload?.results?.files?.uploaded_files.map((r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        }));
      return {
        ...state,
        breadcrumb: [
          { id: payload?.results?.id, name: payload?.results?.foldername },
        ],
        breadcrumbNav: [
          { id: payload?.results?.id, name: payload?.results?.foldername },
        ],
        fileExplorerItems: {
          ...state.fileExplorerItems,
          loading: false,
          count: payload?.count,
          hasMore: payload?.next ? true : false,
          list: [
            ...formattedList,
            ...formattedListSystemFiles,
            ...formattedListUserFiles,
          ],
        },
      };

    case FileExplorerType.FETCH_FILE_EXPLORER_FAILURE:
      return {
        ...state,
        fileExplorerItems: {
          loading: false,
          error: payload,
        },
      };

    case FileExplorerType.UPDATE_BREADCRUMB:
      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          loading: true,
        },
        breadcrumb: payload,
        breadcrumbNav: payload,
      };

    case FileExplorerType.DATE_FORMAT_TOGGLER:
      const { column } = action.payload;
      console.log(column, "te");
      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          [column]: (state.fileExplorerItems[column] + 1) % 3,
        },
      };
    //difference between this and   case DriverType.DOWNLOAD_DEFAULT_DRIVER_SUCCESS: is that
    //i the driver part we got the newly created id returned from api so we could check if id existed and update accordingly
    //but in file explorer we dont have the id so on success  wwe make api call again to get the updated list of files
    case FileExplorerType.UPLOAD_NEW_FILE_SUCCESS:
      const newFile = {
        id: payload?.file_id,
        foldername: payload?.foldername,
        file_path: payload?.file_path,
        path: payload?.path,
        file_type: payload?.file_type,
        created_by: payload?.created_by,
        updated_by: payload?.updated_by,
        created_at: payload?.created_at
          ? getFormattedTime(payload.created_at)
          : "-",
        updated_at: payload.updated_at
          ? getFormattedTime(payload.updated_at)
          : "-",
      };

      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          list: [...state.fileExplorerItems.list, newFile],
        },
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: false,
        },
      };

    case FileExplorerType.RENAME_FILE_OR_FOLDER_START:
    case FileExplorerType.RENAME_FILE_OR_FOLDER_FAILURE:
    case FileExplorerType.UPLOAD_NEW_FILE_START:
    case FileExplorerType.UPLOAD_NEW_FILE_FAILURE:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: true,
        },
      };

    case FileExplorerType.CREATE_NEW_FOLDER_START:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: true,
        },
      };
    case FileExplorerType.CREATE_NEW_FOLDER_FAILURE:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: true,
        },
      };

    case FileExplorerType.CREATE_NEW_FOLDER_SUCCESS:
      const newFolder = {
        id: payload.folder_id,
        foldername: payload.foldername,
        path: payload.path,
        file_type: payload.file_type,
        created_by: payload.created_by,
        updated_by: payload.updated_by,
        created_at: payload.created_at
          ? getFormattedTime(payload.created_at)
          : "-",
        updated_at: payload.updated_at
          ? getFormattedTime(payload.updated_at)
          : "-",
      };

      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          list: [newFolder, ...state.fileExplorerItems.list],
        },
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: false,
          list: [newFolder, ...state.fileExplorerItemsNav.list],
        },
      };

    case FileExplorerType.FETCH_CHILDREN_START:
      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          loading: true,
        },
      };

    case FileExplorerType.FETCH_CHILDREN_START_FOLDER_NAVIGATION:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: true,
        },
      };
    case FileExplorerType.FETCH_CHILDREN_START_FOLDER_NAVIGATION_FOR_BROWSE:
      return {
        ...state,
        fileExplorerItemsNavBrowse: {
          ...state.fileExplorerItemsNavBrowse,
          loading: true,
        },
      };

    case FileExplorerType.FETCH_CHILDREN_SUCCESS:
      const formattedListChild = payload?.results?.subfolders?.map((r) => ({
        ...r,
        created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
        updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
      }));
      const formattedListSystemFilesChild =
        payload?.results?.files?.system_files.map((r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        }));
      const formattedListUserFilesChild =
        payload?.results?.files?.uploaded_files.map((r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        }));
      //this logic is to handle double click on a table row to open that folder or
      //navigate between folders using the breadcrumb
      //when navigating between folders...new id comes so that needs to go at the end of breadcrumbs
      //when using breaacrumsb the id is already there so we dont need to add it again
      let updatedBreadcrumb;
      const exisitingIndexBreadcrumb = state.breadcrumb.findIndex(
        (item) => item.id === payload?.results?.id
      );
      if (exisitingIndexBreadcrumb === -1) {
        updatedBreadcrumb = [
          ...state.breadcrumb,
          { id: payload?.results?.id, name: payload?.results?.foldername },
        ];
      } else {
        updatedBreadcrumb = state.breadcrumb.slice(
          0,
          exisitingIndexBreadcrumb + 1
        );
      }
      return {
        ...state,
        breadcrumb: updatedBreadcrumb,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          loading: false,
          count: payload?.count ? payload?.count : null,
          hasMore: payload?.next ? true : false,
          list: [
            ...formattedListChild,
            ...formattedListUserFilesChild,
            ...formattedListSystemFilesChild,
          ],
        },
        fileExplorerItemsNav: {
          ...state.fileExplorerItems,
          loading: false,
        },
      };

    //similar as above case but this is for folder during copy/move operation
    //so when a alrady prenet breadcrub is pressed..the breadcrumbs that come after it arent
    //deleteted...rest is same
    case FileExplorerType.FETCH_CHILDREN_SUCCESS_FOLDER_NAVIGATION:
      const formattedListChildNavMove = payload?.results?.subfolders?.map(
        (r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        })
      );
      const formattedListSystemFilesChildNavMove =
        payload?.results?.files?.system_files.map((r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        }));
      const formattedListUserFilesChildNavMove =
        payload?.results?.files?.uploaded_files.map((r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        }));
      //managing breadcrumb..here unlike in breadcrum nav for main table..when a prev folder
      //is clicked the other foldres should not be removed..rest of the things are same
      let updatedBreadcrumbNav;
      const exisitingIndexBreadcrumbNav = state.breadcrumbNav.findIndex(
        (item) => item.id === payload?.results?.id
      );
      if (exisitingIndexBreadcrumbNav === -1) {
        updatedBreadcrumbNav = [
          ...state.breadcrumbNav,
          { id: payload?.results?.id, name: payload?.results?.foldername },
        ];
      } else {
        updatedBreadcrumbNav = state.breadcrumbNav.slice(
          0,
          exisitingIndexBreadcrumbNav + 1
        );
      }

      return {
        ...state,
        breadcrumbNav: updatedBreadcrumbNav,
        fileExplorerItemsNav: {
          ...state.fileExplorerItems,
          loading: false,
          list: [
            ...formattedListChildNavMove,
            ...formattedListSystemFilesChildNavMove,
            ...formattedListUserFilesChildNavMove,
          ],
        },
      };
    case FileExplorerType.FETCH_CHILDREN_SUCCESS_FOLDER_NAVIGATION_FOR_BROWSE: {
      const formattedListChildNavMove = payload?.results?.subfolders?.map(
        (r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        })
      );
      const formattedListSystemFilesChildNavMove =
        payload?.results?.files?.system_files.map((r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        }));
      const formattedListUserFilesChildNavMove =
        payload?.results?.files?.uploaded_files.map((r) => ({
          ...r,
          created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
          updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
        }));
      //managing breadcrumb..here unlike in breadcrum nav for main table..when a prev folder
      //is clicked the other foldres should not be removed..rest of the things are same
      let updatedBreadcrumbNav;
      const exisitingIndexBreadcrumbNav = state.breadcrumbNav.findIndex(
        (item) => item.id === payload?.results?.id
      );
      if (exisitingIndexBreadcrumbNav === -1) {
        updatedBreadcrumbNav = [
          ...state.breadcrumbNav,
          { id: payload?.results?.id, name: payload?.results?.foldername },
        ];
      } else {
        updatedBreadcrumbNav = state.breadcrumbNav.slice(
          0,
          exisitingIndexBreadcrumbNav + 1
        );
      }

      return {
        ...state,
        breadcrumbNav: updatedBreadcrumbNav,
        fileExplorerItemsNavBrowse: {
          ...state.fileExplorerItems,
          loading: false,
          list: [
            ...formattedListChildNavMove,
            ...formattedListSystemFilesChildNavMove,
            ...formattedListUserFilesChildNavMove,
          ],
        },
      };
    }
    case FileExplorerType.FETCH_CHILDREN_FAILURE:
      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          loading: false,
        },
      };

    case FileExplorerType.COPY_FILE_OR_FOLDER_START:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: true,
        },
      };
    case FileExplorerType.RENAME_FILE_OR_FOLDER_SUCCESS:
    case FileExplorerType.COPY_FILE_OR_FOLDER_SUCCESS:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: false,
        },
      };

    case FileExplorerType.COPY_FILE_OR_FOLDER_FAILURE:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: false,
        },
      };

    case FileExplorerType.MOVE_FILE_OR_FOLDER_START:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: true,
        },
      };

    case FileExplorerType.MOVE_FILE_OR_FOLDER_SUCCESS:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: false,
        },
      };

    case FileExplorerType.MOVE_FILE_OR_FOLDER_FAILURE:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: false,
        },
      };

    case FileExplorerType.DELETE_FILE_OR_FOLDER_START:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: true,
        },
      };

    case FileExplorerType.DELETE_FILE_OR_FOLDER_SUCCESS:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: false,
        },
        fileExplorerItems: {
          ...state.fileExplorerItems,
          loading: false,
        },
      };

    case FileExplorerType.DELETE_FILE_OR_FOLDER_FAILURE:
      return {
        ...state,
        fileExplorerItemsNav: {
          ...state.fileExplorerItemsNav,
          loading: false,
        },
        fileExplorerItems: {
          ...state.fileExplorerItems,
          loading: false,
        },
      };

    case FileExplorerType.FETCH_CHILDREN_FAILURE_FOLDER_NAVIGATION:
      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          filesAndFoldersNavLoading: false,
        },
      };
    case FileExplorerType.FETCH_CHILDREN_FAILURE_FOLDER_NAVIGATION_FOR_BROWSE:
      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          filesAndFoldersNavLoading: false,
        },
      };

    case FileExplorerType.CANCEL_PATH_SELECTION:
      return {
        ...state,
        breadcrumbNav:
          state.breadcrumbNav.length > 0 ? [state.breadcrumbNav[0]] : [],
        breadcrumb: state.breadcrumb.length > 0 ? [state.breadcrumb[0]] : [],
      };

    case FileExplorerType.SAVE_PATH_SELECTION:
      return {
        ...state,
        breadcrumbNav: [...state.breadcrumbNav, payload],
      };

    case FileExplorerType.INITIAL_PATH_EDIT_QUERY:
      console.log(payload, "payloadip2");
      return {
        ...state,
        breadcrumbNav: payload?.initialPath,
        breadcrumb: payload?.initialPath,
      };

    case FileExplorerType.ON_CHANGE_FILE_EXPLORER_FILTER:
      const { keys, values } = action.payload;

      return {
        ...state,
        fileExplorerItems: {
          ...state.fileExplorerItems,
          [keys]: values,
          loading: false,
        },
      };

    case FileExplorerType.FETCH_USER_AND_GROUPS_LIST_START:
      return {
        ...state,
        usersAndGroups: {
          ...state.usersAndGroups,
          loading: true,
        },
      };

    case FileExplorerType.FETCH_USER_AND_GROUPS_LIST_FAILURE:
      return {
        ...state,
        usersAndGroups: {
          ...state.usersAndGroups,
          loading: false,
        },
      };

    case FileExplorerType.ON_CHANGE_FILE_EXPLORER_USERS_AND_GROUPS_FILTER:
      const { key, value } = action.payload;

      return {
        ...state,
        usersAndGroups: {
          ...state.usersAndGroups,
          [key]: value,
          loading: false,
        },
      };

    case FileExplorerType.FETCH_USER_AND_GROUPS_LIST_SUCCESS:
      return {
        ...state,
        usersAndGroups: {
          list: payload,
          loading: false,
        },
      };

    case FileExplorerType.SHARE_FILE_OR_FOLDER_FAILURE:
      return {};

    case FileExplorerType.FETCH_SHARED_WITH_USERS_AND_GROUPS_LIST_START:
      return {
        ...state,
        sharedWithUsersAndGroups: {
          ...state.sharedWithUsersAndGroups,
          loading: true,
        },
      };

    case FileExplorerType.FETCH_SHARED_WITH_USERS_AND_GROUPS_LIST_SUCCESS:
      return {
        ...state,
        sharedWithUsersAndGroups: {
          ...state.sharedWithUsersAndGroups,
          list: combinedUsersAndGroupsPermission(
            payload?.users,
            payload?.groups
          ),
          loading: false,
        },
      };

    case FileExplorerType.FETCH_SHARED_WITH_USERS_AND_GROUPS_LIST_FAILURE:
      return {
        ...state,
        sharedWithUsersAndGroups: {
          ...state.sharedWithUsersAndGroups,
          loading: false,
        },
      };

    case FileExplorerType.FETCH_DEFAULT_PATH_LIST_SUCCESS:
      const transformedFolders = payload.map((folder) => ({
        name: folder.foldername,
        id: folder.id,
      }));
      return {
        ...state,
        breakcrumb: transformedFolders,
        breadcrumbNav: transformedFolders,
      };

    default:
      return state;
  }
};

export default fileExplorerReducer;
