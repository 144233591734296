import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AuditLogActions from "store/auditLog/auditLog.actions";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  FormControl,
  FormGroup,
} from "@mui/material";
import { CustomTextField } from "components/CustomInput";

const AuditLogMainFilter = ({
  auditLogsUsers,
  filterValues,
  setFilterValues,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setFilterValues(event.target.checked ? [value] : []);
    console.log(value, "value-audit");
    dispatch(
      AuditLogActions.updatedSelectedUserId(event.target.checked ? value : null)
    );
    dispatch(AuditLogActions.updatedSelectedAuditLogId(null));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  // const handleSelectAllChange = () => {
  //   if (filterValues.length === auditLogsUsers.list.length) {
  //     setFilterValues([]);
  //   } else {
  //     setFilterValues(auditLogsUsers.list.map((user) => user.username));
  //   }
  // };

  return (
    <FormControl
      component="fieldset"
      style={{
        padding: "10px",
        borderRadius: "4px",
        background: "#fff",
        maxWidth: "200px",
        minWidth: "200px",
        maxHeight: "165px",
        overflow: "scroll",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: "0px",
          height: "0px",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          background: "white",
          margin: "-10px !important",
          padding: "10px 10px 0px",
          maxWidth: "200px",
          zIndex: 10,
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <CustomTextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton aria-label="search-icon">
                  <img
                    src={"/assets/images/icons/searchIconAlt.svg"}
                    alt="search"
                    width={14}
                    height={14}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Search..."
          sx={{
            "& .MuiInputBase-root": {
              paddingLeft: "0px",
              height: "24px",
            },
          }}
        />
      </Box>
      <FormGroup
        sx={{
          marginTop: "40px",
        }}
      >
        {auditLogsUsers
          .filter((user) => user.full_name.toLowerCase().includes(searchValue))
          .map((user) => (
            <FormControlLabel
              key={user.id}
              sx={{
                marginRight: "0px !important",
                marginLeft: "-3px !important",
                minWidth: "180px",
                maxWidth: "180px",
                height: "28px",
                "&:hover": { background: "#367AFF14" },
                "& .MuiFormControlLabel-label": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              control={
                <Checkbox
                  size="small"
                  value={user.email}
                  onChange={handleCheckboxChange}
                  checked={filterValues.includes(user.email)}
                  icon={
                    <img
                      src={"/assets/images/icons/Unchecked.svg"}
                      alt="unchecked"
                    />
                  }
                  checkedIcon={
                    <img
                      src={"/assets/images/icons/Checked.svg"}
                      alt="checked"
                    />
                  }
                />
              }
              label={user.full_name}
            />
          ))}
      </FormGroup>
    </FormControl>
  );
};

export default AuditLogMainFilter;
