// project imports
import { getFormattedTime } from "utils/helper";
import * as AuditLogType from "./auditLog.types";
import AuditLog from "views/Admin/SettingsView/AuditLog";
import { initial } from "lodash";

const INITIAL_STATE = {
  auditLogs: {
    selectedId: null,
    loading: false,
    list: [],
    hasMore: false,
    strippedRows: false,
    wrapLines: false,
    limit: 50,
    searchParams: "",
    selectedDate: null,
    count: null,
    //date time toggler for all the differtn tables inside audit log module is handled from here iself
    //as same aciton dispatch can be used for all
    updateModifiedToggler: 0,
    updateModifiedTogglerContent: 0,
  },

  auditLogsUsers: {
    selectedId: null,
    loading: false,
    list: [],
    hasMore: false,
    strippedRows: false,
    wrapLines: false,
    searchParams: "",
  },

  logContent: {
    selectedId: null,
    loading: false,
    list: [],
    hasMore: false,
    strippedRows: false,
    wrapLines: false,
    limit: 10,

    searchParams: "",
    count: null,
  },

  specificLogDetails: {
    data: {},
    loading: false,
  },
};

const auditLogReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case AuditLogType.GET_AUDIT_LOG_START:
      return {
        ...state,
        auditLogs: {
          ...state.auditLogs,
          loading: true,
        },
      };

    case AuditLogType.GET_AUDIT_LOG_SUCCESS:
      // const formattedList = payload?.results?.map((r) => ({
      //   ...r,
      //   date: r.date
      //     ? getFormattedTime(r.date)
      //     : "-",
      // }));
      return {
        ...state,
        auditLogs: {
          ...state.auditLogs,
          loading: false,
          list: payload?.results ?? [],
          count: payload?.count,
          hasMore: payload?.next ? true : false,
        },
      };

    case AuditLogType.GET_AUDIT_LOG_FAILURE:
      return {
        ...state,
        auditLogs: {
          ...state.auditLogs,
          loading: false,
        },
      };

    //
    case AuditLogType.GET_AUDIT_LOG_USERS_START:
      return {
        ...state,
        auditLogsUsers: {
          ...state.auditLogsUsers,
          loading: true,
        },
      };

    case AuditLogType.GET_AUDIT_LOG_USERS_SUCCESS:
      return {
        ...state,
        auditLogsUsers: {
          ...state.auditLogsUsers,
          loading: false,
          list: payload?.data?.map((user) => ({
            ...user,
            full_name: `${user.first_name || ""} ${
              user.last_name || ""
            }`.trim(),
          })),
        },
      };

    case AuditLogType.GET_AUDIT_LOG_USERS_FAILURE:
      return {
        ...state,
        auditLogsUsers: {
          ...state.auditLogsUsers,
          loading: false,
        },
      };
    //

    case AuditLogType.GET_AUDIT_LOG_CONTENT_START:
      return {
        ...state,
        logContent: {
          ...state.logContent,
          loading: true,
        },
      };

    case AuditLogType.GET_AUDIT_LOG_CONTENT_SUCCESS:
      const formattedListContent = payload?.results?.map((r) => ({
        ...r,
        modified_date: r.modified_date
          ? getFormattedTime(r.modified_date)
          : "-",
      }));
      return {
        ...state,
        logContent: {
          ...state.logContent,
          loading: false,
          list: formattedListContent,
          count: payload?.count,
          hasMore: payload?.next ? true : false,
        },
      };

    case AuditLogType.GET_AUDIT_LOG_CONTENT_FAILURE:
      return {
        ...state,
        logContent: {
          ...state.logContent,
          loading: false,
        },
      };

    case AuditLogType.UPDATE_DATE_TOGGLER:
      const { column } = action.payload;
      console.log(column, "te");
      return {
        ...state,
        auditLogs: {
          ...state.auditLogs,
          [column]: (state.auditLogs[column] + 1) % 3,
        },
      };

    case AuditLogType.SELECTED_AUDIT_LOG_ID:
      const { id, date } = action.payload;
      return {
        ...state,
        auditLogs: {
          ...state.auditLogs,
          selectedId: id,
          selectedDate: date,
        },
      };

    case AuditLogType.SELECTED_AUDIT_LOG_USER_ID:
      const { userEmail } = action.payload;
      return {
        ...state,
        auditLogsUsers: {
          ...state.auditLogsUsers,
          selectedId: userEmail,
        },
        auditLogs: {
          ...state.auditLogs,
          loading: true,
        },
      };

    case AuditLogType.SELECTED_LOG_CONTENT_ID:
      const { logId } = action.payload;
      return {
        ...state,

        logContent: {
          ...state.logContent,
          selectedId: logId,
        },
      };

    case AuditLogType.GET_AUDIT_LOG_DETAILS_START:
      return {
        ...state,
        specificLogDetails: {
          ...state.specificLogDetails,
          loading: true,
        },
      };

    case AuditLogType.GET_AUDIT_LOG_DETAILS_SUCCESS:
      return {
        ...state,
        specificLogDetails: {
          ...state.specificLogDetails,
          loading: false,
          data: payload,
        },
      };

    case AuditLogType.GET_AUDIT_LOG_DETAILS_FAILURE:
      return {
        ...state,
        specificLogDetails: {
          ...state.specificLogDetails,
          loading: false,
        },
      };

    case AuditLogType.ON_CHANGE_AUDIT_LOG_FILTER:
      const { key, value } = action.payload;

      return {
        ...state,
        auditLogs: {
          ...state.auditLogs,
          [key]: value,
          loading: false,
        },
      };

    case AuditLogType.ON_CHANGE_LOG_CONTENT_FILTER:
      const { keys, values } = action.payload;

      return {
        ...state,
        logContent: {
          ...state.logContent,
          [keys]: values,
          loading: false,
        },
      };

    case AuditLogType.RESET_AUDIT_LOG:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default auditLogReducer;
