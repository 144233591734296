import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import { LuSearch } from "react-icons/lu";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _, { replace, set } from "lodash";
import { CustomBtn } from "components/CustomInput";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AuditLogMainTable from "./AuditLogMainTable";
import { useDispatch, useSelector } from "react-redux";
import * as AuditLogActions from "store/auditLog/auditLog.actions";
import DateFormatter from "./DateFormatter";
import AuditLogDetailsTable from "./AuditLogDetailsTable";
import AdditionalDetails from "./AdditionalDetails";
import AdditionalDetailsDialog from "./AdditionalDetailsDialog";
import PreferenceDialog from "components/PreferenceDialog";
import { CSVLink } from "react-csv";
const LogContent = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const { auditLogs } = useSelector((state) => state.auditLog);
  const { logContent } = useSelector((state) => state.auditLog);

  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    dispatch(
      AuditLogActions.onChangeApplyLogContentFilter(
        "offset",
        newPage * logContent?.limit
      )
    );
    dispatch(AuditLogActions.fetchAuditLogsContent(auditLogs.selectedId));
    setPage(newPage);
  };

  const { specificLogDetails } = useSelector((state) => state.auditLog);
  const [searchedValue, setSearchedValue] = useState("");
  const [openPreferenceDialog, setOpenPreferenceDialog] = useState(false);
  const gridRef = useRef(null);

  const [selectedRows, setSelectedRows] = useState([]);

  const onSelectionChanged = useCallback(() => {
    if (gridRef.current) {
      const selectedRows = gridRef.current.getSelectedRows();
      setSelectedRows(selectedRows);
      console.log(selectedRows, "newt");

      dispatch(
        AuditLogActions.fetchAuditLogsContentDetails(
          selectedRows[0]?.id ?? null,
          () => setOpen(true)
        )
      );
    }
  }, [dispatch, setOpen]);

  useEffect(() => {
    if (auditLogs.selectedId !== null) {
      dispatch(AuditLogActions.fetchAuditLogsContent(auditLogs.selectedId));
    }
  }, [auditLogs.selectedId, dispatch]);

  // const { auditLog, loading } = useSelector((state) => state.auditLog);
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "action",
      headerName: "Category",

      width: 200,
      minWidth: 90,
      hide: false,
      cellRenderer: (params) =>
        params?.data?.action ? params?.data?.action : "-",
    },
    {
      field: "sub_action",
      headerName: "Subcategory",

      width: 200,
      minWidth: 90,
      hide: false,
      cellRenderer: (params) =>
        params?.data?.sub_action ? params?.data?.sub_action : "-",
    },
    {
      field: "action",
      headerName: "Action",

      width: 200,
      minWidth: 90,
      hide: false,
      cellRenderer: (params) =>
        params?.data?.action ? params?.data?.action : "-",
    },
    {
      field: "modified_by",
      headerName: "Modified by",

      width: 200,
      minWidth: 90,
      hide: false,
      cellRenderer: (params) =>
        params?.data?.modified_by ? params?.data?.modified_by : "-",
    },
    {
      field: "modified_date",
      headerName: "Modified on",
      width: 180,
      hide: false,
      minWidth: 70,
      cellRenderer: (params) => {
        console.log(params?.data, "sdf00");
        return params?.data?.modified_date.length > 1 ? (
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DateFormatter
              value={params?.data?.modified_date}
              column={"updateModifiedTogglerContent"}
            />
          </Box>
        ) : (
          "-"
        );
      },
    },
    {
      field: "description",
      headerName: "Source",

      width: 200,
      minWidth: 90,
      hide: false,
      cellRenderer: (params) =>
        params?.data?.description ? params?.data?.description : "-",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 200,
      suppressMenu: true,
      icons: {
        check: "tick",
      },
    };
  }, []);

  const icons = useMemo(() => {
    return {
      sortAscending: '<img src="/assets/images/icons/arrow-up.svg" />',
      sortDescending: '<img src="/assets/images/icons/arrow-down.svg" />',
    };
  }, []);

  const handleClearSearch = (value) => {
    setSearchedValue("");
    dispatch(AuditLogActions.onChangeApplyLogContentFilter("searchParams", ""));
    dispatch(AuditLogActions.fetchAuditLogsContent(auditLogs.selectedId));
  };
  const debounceDispatch = useCallback(
    _.debounce((value, callback) => {
      dispatch(
        AuditLogActions.onChangeApplyLogContentFilter("searchParams", value)
      );
      dispatch(
        AuditLogActions.fetchAuditLogsContent(auditLogs.selectedId)
      ).then(() => {
        if (callback) callback();
      });
    }, 1000),
    [dispatch, auditLogs.selectedId]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchedValue(value);
    debounceDispatch(value);
  };

  const [tempColumns, setTempColumns] = useState([...columnDefs]);

  const handleColumnChange = (event, name) => {
    const updatedColumns = tempColumns.map((column) => {
      if (column.headerName === name) {
        return { ...column, hide: !event.target.checked };
      }
      return column;
    });
    setTempColumns(updatedColumns);
  };
  const [pageSize, setPageSize] = useState(50);

  const [wrapLinesToggle, setWrapLinesToggle] = useState(false);
  const [stripedRowsToggle, setStripedRowsToggle] = useState(false);

  const handleDialogSubmit = () => {
    setColumnDefs(tempColumns);
    if (pageSize !== logContent?.limit) {
      dispatch(
        AuditLogActions.onChangeApplyLogContentFilter("limit", pageSize)
      );
      setPage(0);
    }
    if (wrapLinesToggle !== logContent?.wrapLines) {
      dispatch(
        AuditLogActions.onChangeApplyLogContentFilter(
          "wrapLines",
          wrapLinesToggle
        )
      );
    }
    if (stripedRowsToggle !== logContent?.strippedRows) {
      dispatch(
        AuditLogActions.onChangeApplyLogContentFilter(
          "strippedRows",
          stripedRowsToggle
        )
      );
    }

    dispatch(AuditLogActions.fetchAuditLogsContent(auditLogs.selectedId));
    setOpenPreferenceDialog(false);
  };

  const handleAuditLogRowUpdate = (newVal, oldVal) => {
    // dispatch(ConnectionActions.updateConnectionProfile(newVal));
  };

  return (
    <>
      {auditLogs.selectedId === null ? null : (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #E0E0E0E6 ",
              background: "#f0f0f0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: 2,
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: "18px" }}>
                Log Content
              </Typography>
              <CSVLink
                style={{
                  borderRadius: "4px",
                  backgroundColor: "primary",
                  height: "32px",
                  boxShadow: "none",
                  textTransform: "none",
                  fontWeight: 400,
                }}
                data={logContent?.list ?? [[]]}
              >
                <Button
                  variant="contained"
                  color="primary"
                  id="demo-positioned-button"
                  sx={{
                    borderRadius: "4px",
                    height: "32px",
                    boxShadow: "none",
                    textTransform: "none",
                    fontWeight: 400,
                  }}
                >
                  Export Report
                </Button>
              </CSVLink>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: 2,
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Paper
                  component="form"
                  onSubmit={(e) => e.preventDefault()}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    maxHeight: 35,
                    border: "1px solid #0000001A",
                    color: "#FFFFFF",
                    boxShadow: "none",
                    borderRadius: "4px",
                    position: "relative",
                    width: "90%",
                  }}
                >
                  <IconButton sx={{ p: "6px" }} aria-label="search" disabled>
                    <LuSearch
                      style={{
                        height: 16,
                        color: "#636262",
                      }}
                    />
                  </IconButton>
                  <InputBase
                    placeholder="Search..."
                    inputProps={{
                      "aria-label": "search databases",
                      maxLength: 30,
                      width: "100%",
                    }}
                    value={searchedValue}
                    onChange={handleSearchChange}
                  />
                  {searchedValue?.length > 0 && (
                    <IconButton
                      type="button"
                      size="small"
                      onClick={handleClearSearch}
                      sx={{
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      <ClearRoundedIcon
                        style={{ color: "#636262", height: 16 }}
                      />
                    </IconButton>
                  )}
                </Paper>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TablePagination
                  sx={{
                    "& .MuiToolbar-root": { padding: 0, minHeight: "20px" },
                    "& .MuiTablePagination-actions": {
                      marginLeft: 0,
                      marginRight: 1,
                    },
                  }}
                  component="div"
                  rowsPerPageOptions={[]}
                  count={logContent?.count}
                  rowsPerPage={logContent?.limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={() => {}}
                />

                <CustomBtn
                  size="small"
                  sx={{
                    borderRadius: "4px",
                    minWidth: "30px",
                    height: "32px",
                    width: "32px",
                  }}
                  variant="outlined"
                  onClick={() => setOpenPreferenceDialog(true)}
                >
                  <SettingsOutlinedIcon />
                </CustomBtn>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ padding: 2, width: "100%", height: "60vh" }}>
              <AuditLogDetailsTable
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                icons={icons}
                gridRef={gridRef}
                auditLog={logContent}
                onRowClicked={onSelectionChanged}
                handleAuditLogRowUpdate={handleAuditLogRowUpdate}
              />
            </Box>
          </Box>
          <Drawer
            sx={{ zIndex: 9999 }}
            anchor={"right"}
            open={open}
            onClose={toggleDrawer(false)}
          >
            <AdditionalDetailsDialog
              handleDialogClose={toggleDrawer(false)}
              data={specificLogDetails}
            />
          </Drawer>
          <PreferenceDialog
            open={openPreferenceDialog}
            columns={columnDefs}
            handleClose={() => setOpenPreferenceDialog(false)}
            handleColumnChange={handleColumnChange}
            handleDialogSubmit={handleDialogSubmit}
            pageSize={pageSize}
            setPageSize={setPageSize}
            wrapLinesToggle={wrapLinesToggle}
            setWrapLinesToggle={setWrapLinesToggle}
            stripedRowsToggle={stripedRowsToggle}
            setStripedRowsToggle={setStripedRowsToggle}
            needsRowSelection={false}
            pageSizeOptions={[10, 20, 30]}
          />
        </Fragment>
      )}
    </>
  );
};

export default LogContent;
