export const FETCH_USER_INFO_START = "FETCH_USER_INFO_START";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";

export const CHANGE_USER_PASSWORD_START = "CHANGE_USER_PASSWORD_START";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAILURE = "CHANGE_USER_PASSWORD_FAILURE";

export const LIST_USER_GROUPS_START = "LIST_USER_GROUPS_START";
export const LIST_USER_GROUPS_SUCCESS = "LIST_USER_GROUPS_SUCCESS";
export const LIST_USER_GROUPS_FAILURE = "LIST_USER_GROUPS_FAILURE";

export const LIST_USER_LIST_START = "LIST_USER_LIST_START";
export const LIST_USER_LIST_SUCCESS = "LIST_USER_LIST_SUCCESS";
export const LIST_USER_LIST_FAILURE = "LIST_USER_LIST_FAILURE";

export const CREATE_OR_UPDATE_USER_START = "CREATE_OR_UPDATE_USER_START";
export const CREATE_OR_UPDATE_USER_SUCCESS = "CREATE_OR_UPDATE_USER_SUCCESS";
export const CREATE_OR_UPDATE_USER_FAILURE = "CREATE_OR_UPDATE_USER_FAILURE";

export const FETCH_USER_BY_ID_START = "FETCH_USER_BY_ID_START";
export const FETCH_USER_BY_ID_SUCCESS = "FETCH_USER_BY_ID_SUCCESS";
export const FETCH_USER_BY_ID_FAILURE = "FETCH_USER_BY_ID_FAILURE";

export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";

export const USER_DELETE_START = "USER_DELETE_START";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

export const ON_CHANGE_APPLY_USERS_FILTER = "ON_CHANGE_APPLY_USERS_FILTER";

export const FETCH_CUSTOM_GROUPS_START = "FETCH_CUSTOM_GROUPS_START";
export const FETCH_CUSTOM_GROUPS_SUCCESS = "FETCH_CUSTOM_GROUPS_SUCCESS";
export const FETCH_CUSTOM_GROUPS_FAILURE = "FETCH_CUSTOM_GROUPS_FAILURE";

export const CREATE_CUSTOM_GROUP_START = "CREATE_CUSTOM_GROUP_START";
export const CREATE_CUSTOM_GROUP_SUCCESS = "CREATE_CUSTOM_GROUP_SUCCESS";
export const CREATE_CUSTOM_GROUP_FAILURE = "CREATE_CUSTOM_GROUP_FAILURE";

export const FETCH_CUSTOM_GROUP_BY_ID_START = "FETCH_CUSTOM_GROUP_BY_ID_START";
export const FETCH_CUSTOM_GROUP_BY_ID_SUCCESS =
  "FETCH_CUSTOM_GROUP_BY_ID_SUCCESS";
export const FETCH_CUSTOM_GROUP_BY_ID_FAILURE =
  "FETCH_CUSTOM_GROUP_BY_ID_FAILURE";

export const UPDATE_CUSTOM_GROUP_START = "UPDATE_CUSTOM_GROUP_START";
export const UPDATE_CUSTOM_GROUP_SUCCESS = "UPDATE_CUSTOM_GROUP_SUCCESS";
export const UPDATE_CUSTOM_GROUP_FAILURE = "UPDATE_CUSTOM_GROUP_FAILURE";

export const DELETE_CUSTOM_GROUPS_START = "DELETE_CUSTOM_GROUPS_START";
export const DELETE_CUSTOM_GROUPS_SUCCESS = "DELETE_CUSTOM_GROUPS_SUCCESS";
export const DELETE_CUSTOM_GROUPS_FAILURE = "DELETE_CUSTOM_GROUPS_FAILURE";

export const CLEAR_CUSTOM_GROUP_INFO = "CLEAR_CUSTOM_GROUP_INFO";

export const LIST_PERMISSIONS_GROUPED_BY_APPLICATION_LABEL_START =
  "LIST_PERMISSIONS_GROUPED_BY_APPLICATION_LABEL_START";
export const LIST_PERMISSIONS_GROUPED_BY_APPLICATION_LABEL_SUCCESS =
  "LIST_PERMISSIONS_GROUPED_BY_APPLICATION_LABEL_SUCCESS";
export const LIST_PERMISSIONS_GROUPED_BY_APPLICATION_LABEL_FAILURE =
  "LIST_PERMISSIONS_GROUPED_BY_APPLICATION_LABEL_FAILURE";

export const ON_CHANGE_APPLY_CUSTOM_GROUPS_FILTER =
  "ON_CHANGE_APPLY_CUSTOM_GROUPS_FILTER";

export const FETCH_CUSTOM_PERMISSIONS_START = "FETCH_CUSTOM_PERMISSIONS_START";
export const FETCH_CUSTOM_PERMISSIONS_SUCCESS =
  "FETCH_CUSTOM_PERMISSIONS_SUCCESS";
export const FETCH_CUSTOM_PERMISSIONS_FAILURE =
  "FETCH_CUSTOM_PERMISSIONS_FAILURE";

export const CREATE_CUSTOM_PERMISSIONS_START =
  "CREATE_CUSTOM_PERMISSIONS_START";
export const CREATE_CUSTOM_PERMISSIONS_SUCCESS =
  "CREATE_CUSTOM_PERMISSIONS_SUCCESS";
export const CREATE_CUSTOM_PERMISSIONS_FAILURE =
  "CREATE_CUSTOM_PERMISSIONS_FAILURE";

export const DELETE_CUSTOM_PERMISSIONS_START =
  "DELETE_CUSTOM_PERMISSIONS_START";
export const DELETE_CUSTOM_PERMISSIONS_SUCCESS =
  "DELETE_CUSTOM_PERMISSIONS_SUCCESS";
export const DELETE_CUSTOM_PERMISSIONS_FAILURE =
  "DELETE_CUSTOM_PERMISSIONS_FAILURE";

export const ON_CHANGE_APPLY_CUSTOM_PERMISSIONS_FILTER =
  "ON_CHANGE_APPLY_CUSTOM_PERMISSIONS_FILTER";
