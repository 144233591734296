import React, { Fragment, useState } from "react";
import {
  IconButton,
  ListItemButton,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import MuiDrawer from "@mui/material/Drawer";

import { navItems } from "./navItems";
import * as AuthActions from "../../../store/auth/auth.actions";
import AlertDialog from "components/AlertDialog";

export const openedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6.25)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6.25)})`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,

  overflow: "hidden",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  ...theme.mixins.toolbar,
}));

// ==============================|| MOBILE SIDEBAR ||============================== //
export default function MobileSidebar({ open, setOpen }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [settingsToggler, setSettingsToggler] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  if (!open) {
    return null;
  }

  const isNavItemActive = (pathname) => {
    return (
      location.pathname === pathname ||
      location.pathname.startsWith(pathname + "/")
    );
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          width: "100vw",
          backgroundColor: "#1A2055",
          padding: "0px 15px",
        },
      }}
    >
      <DrawerHeader
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: "56px !important",
        }}
      >
        <img
          src={"/assets/images/icons/mainLogo.svg"}
          height="34px"
          width="34px"
          alt="DF"
        />
        <IconButton
          sx={{ height: "34px", width: "34px", padding: "0px", color: "#fff" }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {navItems?.map((nav, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              display: "block",
              borderRadius: "4px",
              backgroundColor: isNavItemActive(nav.pathname)
                ? theme.palette.primary.light // Change this to your preferred selected color
                : "transparent",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark, // Add a hover color
                borderRadius: "4px",
              },
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 43,
                justifyContent: "initial",
                padding: "8px 8px",
              }}
              onClick={() => {
                navigate(nav.pathname);
                setOpen(false);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "6px",
                  justifyContent: "center",
                }}
              >
                {<img src={nav?.icon} alt={nav?.name} />}
              </ListItemIcon>
              <ListItemText
                primary={nav?.name}
                sx={{
                  color: theme.palette.common.white,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
        }}
      >
        <Divider
          sx={{
            background: "#FEFEFE4D",
          }}
        />
        <List sx={{ padding: 0 }}>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor: isNavItemActive("/manage/settings")
                ? theme.palette.primary
                : "transparent",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: theme.palette.primary,
              },
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 43,
                padding: "8px 15px",
              }}
              onClick={() => setSettingsToggler(!settingsToggler)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "6px",
                  justifyContent: "center",
                }}
              >
                <img
                  src={"/assets/images/icons/settingIcon.svg"}
                  alt={"Setting"}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Settings"}
                sx={{
                  color: theme.palette.common.white,
                }}
              />
              <Fragment>
                {settingsToggler ? (
                  <ExpandLess sx={{ color: theme.palette.common.white }} />
                ) : (
                  <ExpandMore sx={{ color: theme.palette.common.white }} />
                )}
              </Fragment>
            </ListItemButton>
            <Collapse in={settingsToggler} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: isNavItemActive(
                      "/manage/settings/user-management"
                    )
                      ? theme.palette.primary.light // Change this
                      : "transparent",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark, // Add a hover color
                    },
                  }}
                  onClick={() => {
                    navigate("/manage/settings/user-management");
                    setOpen(false);
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: "0px" }}>
                    <img
                      src={"/assets/images/icons/user_dash.svg"}
                      alt={"User"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="User"
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: isNavItemActive(
                      "/manage/settings/license-management"
                    )
                      ? theme.palette.primary.light // Change this
                      : "transparent",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark, // Add a hover color
                    },
                    borderRadius: "0px 0px 4px 4px",
                  }}
                  onClick={() => {
                    navigate("/manage/settings/license-management");
                    setOpen(false);
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: "6px" }}>
                    <img
                      src={"/assets/images/icons/license_dash.svg"}
                      alt={"License"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="License"
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                </ListItemButton>{" "}
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: isNavItemActive(
                      "/manage/settings/audit-log"
                    )
                      ? theme.palette.primary.light // Change this
                      : "transparent",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark, // Add a hover color
                    },
                    borderRadius: "0px 0px 4px 4px",
                  }}
                  onClick={() => {
                    navigate("/manage/settings/license-management");
                    setOpen(false);
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: "6px" }}>
                    <img
                      src={"/assets/images/icons/auditLogIcon.svg"}
                      alt={"Audit Log"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Audit Log"
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                </ListItemButton>{" "}
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: isNavItemActive("/manage/settings/cluster")
                      ? theme.palette.primary.light // Change this
                      : "transparent",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark, // Add a hover color
                    },
                    borderRadius: "0px 0px 4px 4px",
                  }}
                  onClick={() => {
                    navigate("/manage/settings/cluster");
                    setOpen(false);
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: "6px" }}>
                    <img
                      src={"/assets/images/icons/clusterIcon.svg"}
                      alt={"Audit Log"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Cluster"
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: isNavItemActive(
                      "/manage/settings/clusterAdmin"
                    )
                      ? theme.palette.primary.light // Change this
                      : "transparent",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark, // Add a hover color
                    },
                    borderRadius: "0px 0px 4px 4px",
                  }}
                  onClick={() => {
                    navigate("/manage/settings/clusterAdmin");
                    setOpen(false);
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: "6px" }}>
                    <img
                      src={"/assets/images/icons/clusterAdmin.svg"}
                      alt={"Audit Log"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Databricks Configuration"
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
        </List>
        <ListItem
          disablePadding
          sx={{
            display: "block",
            borderRadius: "4px",
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 43,
              justifyContent: "initial",
              padding: "8px 15px",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark, // Add a hover color
              },
            }}
            onClick={() => setOpenDialog(true)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: "6px",
                justifyContent: "center",
              }}
            >
              <img src={"/assets/images/icons/logoutIcon.svg"} alt={"Logout"} />
            </ListItemIcon>
            <ListItemText
              primary={"Logout"}
              sx={{
                color: theme.palette.common.white,
              }}
            />
          </ListItemButton>
        </ListItem>
      </Box>
      <AlertDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => dispatch(AuthActions.logout())}
        title="Logout"
        description="Are you sure you want to logout?"
      />
    </Drawer>
  );
}
