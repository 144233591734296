// project imports
import * as PipelineTypes from "./pipeline.types";
import axiosConfig from "config/axiosConfig";
import { store } from "store";
import { openAlert } from "store/alert/alert.actions";

export const renamePipelineName = (id, title) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.RENAME_PIPELINE_NAME_START,
    });

    const { data } = await axiosConfig.patch(`/pipeline/pipeline/${id}`, {
      title,
    });

    dispatch({
      type: PipelineTypes.RENAME_PIPELINE_NAME_SUCCESS,
    });
    dispatch(openAlert("Pipeline renamed successfully", "success"));
  } catch (error) {
    dispatch({
      type: PipelineTypes.RENAME_PIPELINE_NAME_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(openAlert("Failed to rename pipeline.", "error"));
  }
};

export const deletePipeline = (ids) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.DELETE_PIPELINE_START,
    });

    await axiosConfig.delete(`/pipeline/pipeline/delete-pipelines/`, {
      data: { ids },
    });
    dispatch({
      type: PipelineTypes.DELETE_PIPELINE_SUCCESS,
    });

    dispatch(openAlert("Pipeline deleted successfully", "success"));
  } catch (error) {
    dispatch({
      type: PipelineTypes.DELETE_PIPELINE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(openAlert("Failed to delete pipeline", "error"));
  }
};

export const fetchPipelineSource = () => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_SOURCE_START,
    });

    const { data } = await axiosConfig.get(`/pipeline/sources-list/`);
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_SOURCE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_SOURCE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const getPipelineResults = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.GET_PIPELINE_RESULTS_START,
    });

    const { data } = await axiosConfig.get(
      `/pipeline/${id}/retrieve-pipeline-result/`
    );
    dispatch({
      type: PipelineTypes.GET_PIPELINE_RESULTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.GET_PIPELINE_RESULTS_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const onChangeApplyPipelineFilter = (key, value) => ({
  type: PipelineTypes.SET_PIPEPINE_RESULTS_VALUE,
  payload: { key, value },
});

export const fetchPipelineTransformations = () => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_TRANSFORMATIONS_START,
    });

    const { data } = await axiosConfig.get(`/pipeline/transformation-list/`);
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_TRANSFORMATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_TRANSFORMATIONS_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const fetchPipelineSink = () => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_SINK_START,
    });

    const { data } = await axiosConfig.get(`/pipeline/sink-list/`);
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_SINK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_SINK_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};
export const fetchPipelineAdditional = () => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_ADDITIONAL_START,
    });

    const { data } = await axiosConfig.get(`/profiling/profiling-list/`);

    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_ADDITIONAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_ADDITIONAL_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const setNodes = (nodes, callback) => {
  return (dispatch, getState) => {
    // Dispatch the original action
    dispatch({
      type: PipelineTypes.SET_NODES,
      payload: nodes,
    });

    // Call the callback function if provided
    if (callback) {
      const pipelineId = getState().pipeline.pipRes?.pipelineId; // Example of getting pipelineId from state
      const latestNodes = getState().pipeline.nodes; // Example of getting the latest nodes from state
      callback(pipelineId, latestNodes);
    }
  };
};
export const setNodesJoin = (nodes) => ({
  type: PipelineTypes.SET_NODES_JOIN,
  payload: nodes,
});

export const setEdges = (edges) => ({
  type: PipelineTypes.SET_EDGES,
  payload: edges,
});

export const onNodesChange = (changes, callback) => {
  return (dispatch, getState) => {
    // Dispatch the original action
    dispatch({
      type: PipelineTypes.ON_NODES_CHANGE,
      payload: changes,
    });

    // Call the callback function if provided
    if (callback && changes[0].type === "remove") {
      callback(getState());
    }
  };
};
export const onNodesChangeJoin = (changes, orderId) => ({
  type: PipelineTypes.ON_NODES_CHANGE_JOIN,
  payload: { changes, orderId },
});

export const onEdgesChange = (changes, callback) => {
  return (dispatch, getState) => {
    // Dispatch the original action
    dispatch({
      type: PipelineTypes.ON_EDGES_CHANGE,
      payload: changes,
    });

    // Call the callback function if provided
    if (callback) {
      callback(getState());
    }
  };
};
export const onEdgesChangeJoin = (changes, orderId) => ({
  type: PipelineTypes.ON_EDGES_CHANGE_JOIN,
  payload: { changes, orderId },
});

export const onConnect = (connection, callback) => {
  return (dispatch, getState) => {
    // Dispatch the original action
    dispatch({
      type: PipelineTypes.ON_CONNECT,
      payload: connection,
    });

    // Call the callback function if provided
    if (callback) {
      callback(getState());
    }
  };
};

export const onConnectJoin = (connection) => ({
  type: PipelineTypes.ON_CONNECT_JOIN,
  payload: connection,
});
export const onEdgesDelete = (edges, callback) => {
  return (dispatch, getState) => {
    // Dispatch the original action
    dispatch({
      type: PipelineTypes.ON_EDGES_DELETE,
      payload: edges,
    });

    // Call the callback function if provided
    if (callback) {
      callback(getState());
    }
  };
};
export const onEdgesDeleteJoin = (edge) => ({
  type: PipelineTypes.ON_EDGES_DELETE_JOIN,
  payload: edge,
});

export const addNode = (node) => ({
  type: PipelineTypes.ADD_NODE,
  payload: node,
});
export const addNodeJoin = (node) => ({
  type: PipelineTypes.ADD_NODE_JOIN,
  payload: node,
});

export const updateNodesBasedOnEdges = (edges, nodes) => ({
  type: PipelineTypes.UPDATE_NODES_BASED_ON_EDGES,
  payload: { edges, nodes },
});

export const preserveNodeState = (currentNodeState) => ({
  type: PipelineTypes.PRESERVE_NODE_STATE,
  payload: currentNodeState,
});

export const onChangeApplyQueryTabValue = (nodeId, updates) => ({
  type: PipelineTypes.ON_CHANGE_APPLY_QUERY_TAB_VALUE_PIPELINE,
  payload: { nodeId, updates },
});
export const onChangeApplyPipelineResponse = (updates) => ({
  type: PipelineTypes.ON_CHANGE_APPLY_PIPELINE_RESPONSE,
  payload: { updates },
});

export const cleanSocketResponse = () => ({
  type: PipelineTypes.CLEAN_SOCKET_RESPONSE,
});
export const updateSocketResponse = (data) => ({
  type: PipelineTypes.UPDATE_SOCKET_RESPONSE,
  payload: { data },
});

export const fetchConnectionProfileById = (nodeId, id) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.FETCH_CONNECTION_PROFILE_BY_ID_START_PIPELINE,
      payload: { nodeId },
    });

    const { data } = await axiosConfig.get(`/connections/connection/${id}/`);

    dispatch({
      type: PipelineTypes.FETCH_CONNECTION_PROFILE_BY_ID_SUCCESS_PIPELINE,
      payload: { nodeId, data },
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.FETCH_CONNECTION_PROFILE_BY_ID_FAILURE_PIPELINE,
      payload: {
        nodeId,
        error:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      },
    });
  }
};

export const fetchDatabaseOrSchemaFromConnectionProfile =
  (nodeId, id) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START_PIPELINE,
        payload: { nodeId },
      });

      const { data } = await axiosConfig.get(`/queryeditor/${id}/databases/`);

      dispatch({
        type: PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS_PIPELINE,
        payload: { nodeId, data },
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE_PIPELINE,
        payload: {
          nodeId,
          error:
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        },
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const fetchResultsFromDatabaseOrSchema =
  (nodeId, id, name) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START_PIPELINE,
        payload: { nodeId },
      });

      const { data } = await axiosConfig.get(
        `/queryeditor/${id}/database/${name}`
      );

      dispatch({
        type: PipelineTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS_PIPELINE,
        payload: { nodeId, data },
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE_PIPELINE,
        payload: {
          nodeId,
          error:
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        },
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };
export const fetchTableViewFromSchema =
  (nodeId, id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_START_PIPELINE,
        payload: { nodeId },
      });

      const { data } = await axiosConfig.post(
        `/queryeditor/${id}/table-views/`,
        formData
      );

      dispatch({
        type: PipelineTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS_PIPELINE,
        payload: { nodeId, data },
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE_PIPELINE,
        payload: {
          nodeId,
          error:
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        },
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const fetchTableColumnFromTableView =
  (nodeId, id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.FETCH_COLUMN_FROM_TABLE_VIEW_START_PIPELINE,
        payload: { nodeId },
      });

      const { data } = await axiosConfig.post(
        `/queryeditor/${id}/column-from-table/`,
        formData
      );

      dispatch({
        type: PipelineTypes.FETCH_COLUMN_FROM_TABLE_VIEW_SUCCESS_PIPELINE,
        payload: { nodeId, data },
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_COLUMN_FROM_TABLE_VIEW_FAILURE_PIPELINE,
        payload: {
          nodeId,
          error:
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        },
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const fetchTableColumnFromTable =
  (nodeId, id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.FETCH_COLUMN_FROM_TABLE_START_PIPELINE,
        payload: { nodeId },
      });

      const { data } = await axiosConfig.post(
        `/queryeditor/${id}/column-from-table/`,
        formData
      );

      dispatch({
        type: PipelineTypes.FETCH_COLUMN_FROM_TABLE_SUCCESS_PIPELINE,
        payload: { nodeId, data },
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_COLUMN_FROM_TABLE_FAILURE_PIPELINE,
        payload: {
          nodeId,
          error:
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        },
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const runPipeline = (id, engineId, uuid, cb) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.RUN_PIPELINE_START,
    });

    const { data } = await axiosConfig.post(
      `/pipeline/pipeline-run-new/${id}/`,
      { engine_id: engineId, uuid }
    );

    dispatch({
      type: PipelineTypes.RUN_PIPELINE_SUCCESS,
      payload: data,
    });

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: PipelineTypes.RUN_PIPELINE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};
export const createPipeline = (data, cb) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.CREATE_PIPELINE_START,
    });

    const pipResponse = await axiosConfig.post(`/pipeline/pipeline/`, {
      ...data,
    });
    dispatch({
      type: PipelineTypes.CREATE_PIPELINE_SUCCESS,
      payload: pipResponse,
    });

    if (pipResponse.data && pipResponse.data.id) {
      dispatch(fetchPipDataRetention(pipResponse.data.id));
    }
    if (cb) {
      if (pipResponse.data && pipResponse.data.id) {
        cb(pipResponse?.data?.id);
      }
    }
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.error
        ? error.response.data.error
        : error.message;
    dispatch({
      type: PipelineTypes.CREATE_PIPELINE_FAILURE,
      payload: errorMessage,
    });
    console.log("sdlfjsdf", errorMessage);
    if (!errorMessage?.title) {
      dispatch(openAlert(errorMessage, "error"));
    }
  }
};
export const fetchAllPipeline = (data) => async (dispatch, getState) => {
  const {
    pipeline: {
      pipRes: { limit, offset },
    },
  } = getState();

  try {
    dispatch({
      type: PipelineTypes.FETCH_ALL_PIPELINE_START,
    });

    const queryParams = [];
    let url = `/pipeline/pipeline/`;

    if (limit) {
      queryParams.push(`limit=${limit}`);
    }
    if (offset) {
      queryParams.push(`offset=${offset}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    const { data } = await axiosConfig.get(url);

    dispatch({
      type: PipelineTypes.FETCH_ALL_PIPELINE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.FETCH_ALL_PIPELINE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};
export const updatePipeline = (pipelineId, data) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.UPDATE_PIPELINE_START,
    });

    const { id, node_data } = await axiosConfig.put(
      `/pipeline/pipeline/${pipelineId}/`,
      {
        node_data: data,
      }
    );

    dispatch({
      type: PipelineTypes.UPDATE_PIPELINE_SUCCESS,
      payload: { id, node_data },
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.UPDATE_PIPELINE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const fetchRecentPipeline = () => async (dispatch, getState) => {
  const {
    pipeline: {
      pipRes: { limit, offset },
    },
  } = getState();

  try {
    dispatch({
      type: PipelineTypes.FETCH_RECENT_PIPELINE_START,
    });

    let url = "/pipeline/pipeline/recent-pipeline/";
    const queryParams = [];
    if (limit) {
      queryParams.push(`limit=${limit}`);
    }
    if (offset) {
      queryParams.push(`offset=${offset}`);
    }
    if (queryParams.length > 0) {
      url += "?" + queryParams.join("&");
    }
    const { data } = await axiosConfig.get(url);

    dispatch({
      type: PipelineTypes.FETCH_RECENT_PIPELINE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.FETCH_RECENT_PIPELINE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const fetchSelectedPipeline =
  (pipelineId, callback) => async (dispatch, getState) => {
    console.log("pipId", pipelineId);
    try {
      dispatch({
        type: PipelineTypes.FETCH_SELECTED_PIPELINE_START,
      });

      const { data } = await axiosConfig.get(
        `/pipeline/pipeline/${pipelineId}/`
      );
      console.log("payloadd", data);
      dispatch({
        type: PipelineTypes.FETCH_SELECTED_PIPELINE_SUCCESS,
        payload: data,
      });

      if (callback) {
        console.log("fetchselectedpipeline", getState().pipeline.nodes);
        callback(getState());
      }
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_SELECTED_PIPELINE_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });

      dispatch(
        openAlert(
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
          "error"
        )
      );
    }
  };

export const cancelPipelineRun = (runId, engineId) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.CANCEL_PIPELINE_RUN_START,
    });

    const { data } = await axiosConfig.post(`/pipeline/cancel-execution/`, {
      run_id: runId,
      engine_id: engineId,
    });

    dispatch({
      type: PipelineTypes.CANCEL_PIPELINE_RUN_SUCCESS,
      payload: data,
    });

    dispatch(
      openAlert("Pipeline run has been cancelled Successfully", "success")
    );
  } catch (error) {
    dispatch({
      type: PipelineTypes.CANCEL_PIPELINE_RUN_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
        "error"
      )
    );
  }
};

export const fetchPipelineExecutionByDate =
  (pipelineId) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_DATE_START,
      });

      const { data } = await axiosConfig.get(
        `/pipeline/${pipelineId}/pipeline-execution-by-date/`
      );

      dispatch({
        type: PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_DATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_DATE_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const fetchPipelineExecutionBySession =
  (pipelineId, date) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_SESSION_START,
      });

      const { data } = await axiosConfig.get(
        `/pipeline/${pipelineId}/pipeline-execution-by-session/?date=${date}`
      );

      dispatch({
        type: PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_SESSION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_PIPELINE_EXECUTION_BY_SESSION_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const fetchPipelineExecutionLogBySession =
  (uuid, cb) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_START,
      });

      const { data } = await axiosConfig.get(
        `/pipeline/${uuid}/retrieve-pipeline-execution-log-by-session/`
      );
      dispatch({
        type: PipelineTypes.FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_SUCCESS,
        payload: data,
      });
      dispatch(getPipelineResults(data?.node_data?.uuid));
      console.log("logData", data);

      if (cb) {
        cb(data.node_data.node_data);
      }
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const fetchPipDataRetention = (id, cb) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_DATA_RETENTION_START,
    });

    const { data } = await axiosConfig.get(
      `/pipeline/${id}/pipeline-data-setting/`
    );
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_DATA_RETENTION_SUCCESS,
      payload: data,
    });

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: PipelineTypes.FETCH_PIPELINE_DATA_RETENTION_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const onChangeApplyDataRetentionValues = (updates) => ({
  type: PipelineTypes.UPDATE_DATA_RETENTION_PARAMS,
  payload: { updates },
});

export const onChangeApplyDataRetentionValuesData = (keys, values) => ({
  type: PipelineTypes.UPDATE_DATA_RETENTION_PARAMS_DATA,
  payload: { keys, values },
});

export const cancelUpdateDataRetention = () => ({
  type: PipelineTypes.CANCEL_DATA_RETENTION_UPDATE,
});

export const fetchResultsFromDatabaseOrSchemaPipeline =
  (id, name) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START,
      });

      const { data } = await axiosConfig.get(
        `/queryeditor/${id}/database/${name}`
      );

      dispatch({
        type: PipelineTypes.PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };
export const fetchTableViewFromSchemaPipeline =
  (id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.PIP_FETCH_TABLE_VIEWS_FROM_SCHEMA_START,
      });

      const { data } = await axiosConfig.post(
        `/queryeditor/${id}/table-views/`,
        formData
      );

      dispatch({
        type: PipelineTypes.PIP_FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.PIP_FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const fetchAllQueryConnectionListPipeline = () => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_START,
    });

    const { data } = await axiosConfig.get(
      `/connections/connection/query-connection-list/`
    );
    dispatch({
      type: PipelineTypes.PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const fetchDatabaseOrSchemaFromConnectionProfilePipeline =
  (id) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START_PIP,
        payload: { id },
      });

      const { data } = await axiosConfig.get(`/queryeditor/${id}/databases/`);

      dispatch({
        type: PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS_PIP,
        payload: { id, data },
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE_PIP,
        payload: {
          id,
          error:
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        },
      });
      // dispatch(
      //   openAlert(
      //     `${
      //       error.response && error.response.data.error
      //         ? error.response.data.error
      //         : error.response && error.response.data.message
      //         ? error.response.data.message
      //         : error.message ?? "Something went wrong"
      //     }`,
      //     "error"
      //   )
      // );
    }
  };

export const updateDataRetentionData =
  (id, formData, cb) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.UPDATE_DATA_RETENTION_DATA_START,
      });

      const { data } = await axiosConfig.put(
        `/pipeline/${id}/pipeline-data-setting/`,
        formData
      );

      dispatch({
        type: PipelineTypes.UPDATE_DATA_RETENTION_DATA_SUCCESS,
        payload: data,
      });

      if (cb) {
        cb();
      }

      dispatch(
        openAlert("Data retention settings updated successfully", "success")
      );
    } catch (error) {
      dispatch(cancelUpdateDataRetention());

      dispatch({
        type: PipelineTypes.UPDATE_DATA_RETENTION_DATA_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const fetchProfilingResult = (pipelineId, uuid) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.FETCH_PROFILING_RESULT_START,
    });

    const { data } = await axiosConfig.get(
      `/profiling/${pipelineId}/profiling-report-list/${uuid}`
    );

    dispatch({
      type: PipelineTypes.FETCH_PROFILING_RESULT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.FETCH_PROFILING_RESULT_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const fetchProfilingLineChart =
  (pipelineId, columnId, stepId, columnName, filterType, duration) =>
  async (dispatch) => {
    // Build the query parameters

    console.log("stepId", stepId);
    const formData = {
      column_id: columnId,
      step_id: stepId,
      column_name: columnName,
    };
    try {
      dispatch({
        type: PipelineTypes.FETCH_PROFILING_LINE_CHART_START,
      });

      const { data } = await axiosConfig.post(
        `/profiling/${pipelineId}/profiling-report-line-chart/?filter_type=${filterType}&duration=${duration}`,
        formData
      );

      dispatch({
        type: PipelineTypes.FETCH_PROFILING_LINE_CHART_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PipelineTypes.FETCH_PROFILING_LINE_CHART_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const getPipelineSessionId = (cb) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.GET_PIPELINE_SESSION_ID_START,
    });

    const { data } = await axiosConfig.get(`/pipeline/pipeline-session-id`);

    dispatch({
      type: PipelineTypes.GET_PIPELINE_SESSION_ID_SUCCESS,
      payload: data,
    });

    if (cb) {
      cb(data);
    }
  } catch (error) {
    dispatch({
      type: PipelineTypes.GET_PIPELINE_SESSION_ID_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const getSharedList = (pipelineId) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.GET_SHARED_LIST_START,
    });

    const { data } = await axiosConfig.get(
      `/pipeline/${pipelineId}/pipeline-share/`
    );

    dispatch({
      type: PipelineTypes.GET_SHARED_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PipelineTypes.GET_SHARED_LIST_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const sharePipeline = (pipelineId, formData, cb) => async (dispatch) => {
  try {
    dispatch({
      type: PipelineTypes.SHARE_PIPELINE_START,
    });

    const { data } = await axiosConfig.post(
      `/pipeline/${pipelineId}/pipeline-share/`,
      {
        ...formData,
      }
    );

    dispatch({
      type: PipelineTypes.SHARE_PIPELINE_SUCCESS,
      payload: data,
    });

    dispatch(openAlert("Pipeline shared successfully", "success"));
    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: PipelineTypes.SHARE_PIPELINE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};

// update share list
export const updateShareList =
  (pipelineId, formData, cb) => async (dispatch) => {
    try {
      dispatch({
        type: PipelineTypes.UPDATE_SHARE_LIST_START,
      });

      const { data } = await axiosConfig.put(
        `/pipeline/${pipelineId}/pipeline-share/`,

        formData
      );

      dispatch({
        type: PipelineTypes.UPDATE_SHARE_LIST_SUCCESS,
        payload: data,
      });

      dispatch(
        openAlert("Pipeline shared permissions updated successfully", "success")
      );
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({
        type: PipelineTypes.UPDATE_SHARE_LIST_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

// delete shared permissions
export const removeAccess = (pipelineId, formData, cb) => async (dispatch) => {
  console.log("removeAccess", formData);
  try {
    dispatch({
      type: PipelineTypes.REMOVE_ACCESS_START,
    });

    const { data } = await axiosConfig.delete(
      `/pipeline/${pipelineId}/pipeline-share/`,
      { data: formData }
    );

    dispatch({
      type: PipelineTypes.REMOVE_ACCESS_SUCCESS,
      payload: data,
    });

    dispatch(
      openAlert("Pipeline shared permissions removed successfully", "success")
    );
    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: PipelineTypes.REMOVE_ACCESS_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};
