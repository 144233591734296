// project imports
import * as UserType from "./user.types";

const INITIAL_STATE = {
  currentUser: {},
  groups: {
    isLoading: false,
    list: [],
  },
  error: null,
  loading: false,
  soloUser: null,
  users: {
    loading: false,
    searchParams: "",
    limit: 10,
    count: null,
    error: null,
    list: [],
  },
  permissions: {
    loading: false,
    searchParams: "",
    limit: 10,
    count: null,
    error: null,
    list: [],
  },
  customGroups: {
    loading: false,
    searchParams: "",
    limit: 10,
    count: null,
    error: null,
    list: [],
    soloGroup: null,
  },
  permissionByApplicationLabel: [],
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case UserType.FETCH_USER_INFO_START:
    case UserType.CREATE_OR_UPDATE_USER_START:
    case UserType.FETCH_USER_BY_ID_START:
      return {
        ...state,
        loading: true,
      };

    case UserType.LIST_USER_LIST_START:
    case UserType.USER_DELETE_START:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };

    case UserType.FETCH_CUSTOM_GROUPS_START:
    case UserType.CREATE_CUSTOM_GROUP_START:
    case UserType.FETCH_CUSTOM_GROUP_BY_ID_START:
    case UserType.UPDATE_CUSTOM_GROUP_START:
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          loading: true,
        },
      };

    case UserType.LIST_PERMISSIONS_GROUPED_BY_APPLICATION_LABEL_START:
      return {
        ...state,
        loading: true,
      };

    case UserType.CHANGE_USER_PASSWORD_START:
      return {
        ...state,
        loading: true,
      };

    case UserType.LIST_USER_GROUPS_START:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: true,
        },
      };

    case UserType.FETCH_CUSTOM_PERMISSIONS_START:
    case UserType.CREATE_CUSTOM_PERMISSIONS_START:
    case UserType.DELETE_CUSTOM_PERMISSIONS_START:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          loading: true,
        },
      };

    case UserType.FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: payload,
      };

    case UserType.CLEAR_USER_DETAILS:
      return {
        ...state,
        soloUser: null,
      };

    case UserType.CHANGE_USER_PASSWORD_SUCCESS:
    case UserType.CREATE_OR_UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UserType.LIST_USER_GROUPS_SUCCESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          list: payload?.data,
        },
      };

    case UserType.FETCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        soloUser: payload?.data,
      };

    case UserType.LIST_USER_LIST_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          list: payload?.results?.data,
          count: payload?.count,
        },
      };

    case UserType.FETCH_CUSTOM_GROUPS_SUCCESS:
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          loading: false,
          list: payload?.results,
          count: payload?.count,
        },
      };

    case UserType.FETCH_CUSTOM_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          loading: false,
          list: payload?.results?.data,
          count: payload?.count,
        },
      };

    case UserType.CREATE_CUSTOM_PERMISSIONS_SUCCESS:
    case UserType.DELETE_CUSTOM_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          loading: false,
        },
      };

    case UserType.CREATE_CUSTOM_GROUP_SUCCESS:
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          loading: false,
        },
      };

    case UserType.LIST_PERMISSIONS_GROUPED_BY_APPLICATION_LABEL_SUCCESS:
      const filteredList = payload?.data?.filter((d) => {
        return (
          d.name !== "admin" &&
          d.name !== "auth" &&
          d.name !== "contenttypes" &&
          d.name !== "sessions" &&
          d.name !== "silk" &&
          d.name !== "sites"
        );
      });

      return {
        ...state,
        loading: false,
        permissionByApplicationLabel: payload ?? [],
      };

    case UserType.FETCH_CUSTOM_GROUP_BY_ID_SUCCESS:
      // const filteredList1 = payload?.data?.app_permissions?.filter((d) => {
      //   return (
      //     d.name !== "admin" &&
      //     d.name !== "auth" &&
      //     d.name !== "contenttypes" &&
      //     d.name !== "sessions" &&
      //     d.name !== "silk" &&
      //     d.name !== "sites"
      //   );
      // });

      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          loading: false,
          soloGroup: {
            id: payload?.id,
            name: payload?.name,
            users: payload?.users,
            app_permissions: payload?.permission,
          },
        },
      };

    case UserType.UPDATE_CUSTOM_GROUP_SUCCESS:
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          loading: false,
        },
      };

    case UserType.CLEAR_CUSTOM_GROUP_INFO:
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          soloGroup: null,
        },
      };

    case UserType.USER_DELETE_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
        },
      };

    case UserType.ON_CHANGE_APPLY_USERS_FILTER:
      const { key, value } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          [key]: value,
        },
      };

    case UserType.ON_CHANGE_APPLY_CUSTOM_GROUPS_FILTER:
      const { key1, value1 } = action.payload;
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          [key1]: value1,
        },
      };

    case UserType.ON_CHANGE_APPLY_CUSTOM_PERMISSIONS_FILTER:
      const { key2, value2 } = action.payload;
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [key2]: value2,
        },
      };

    case UserType.FETCH_USER_INFO_FAILURE:
    case UserType.CHANGE_USER_PASSWORD_FAILURE:
    case UserType.FETCH_USER_BY_ID_FAILURE:
    case UserType.CREATE_OR_UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UserType.LIST_USER_GROUPS_FAILURE:
      return {
        ...state,
        groups: {
          loading: false,
          error: payload,
        },
      };

    case UserType.LIST_USER_LIST_FAILURE:
    case UserType.USER_DELETE_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: payload,
        },
      };

    case UserType.FETCH_CUSTOM_GROUPS_FAILURE:
    case UserType.CREATE_CUSTOM_GROUP_FAILURE:
    case UserType.FETCH_CUSTOM_GROUP_BY_ID_FAILURE:
    case UserType.UPDATE_CUSTOM_GROUP_FAILURE:
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          loading: false,
          error: payload,
        },
      };

    case UserType.LIST_PERMISSIONS_GROUPED_BY_APPLICATION_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UserType.FETCH_CUSTOM_PERMISSIONS_FAILURE:
    case UserType.CREATE_CUSTOM_PERMISSIONS_FAILURE:
    case UserType.DELETE_CUSTOM_PERMISSIONS_FAILURE:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          loading: false,
          error: payload,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
