//fetch chilren of root
export const FETCH_FILE_EXPLORER_START = "FETCH_FILE_EXPLORER_START";
export const FETCH_FILE_EXPLORER_SUCCESS = "FETCH_FILE_EXPLORER_SUCCESS";
export const FETCH_FILE_EXPLORER_FAILURE = "FETCH_FILE_EXPLORER_FAILURE";

export const DATE_FORMAT_TOGGLER = "DATE_FORMAT_TOGGLER";

//upload file
export const UPLOAD_NEW_FILE_SUCCESS = "UPLOAD_NEW_FILE_SUCCESS";
export const UPLOAD_NEW_FILE_FAILURE = "UPLOAD_NEW_FILE_FAILURE";
export const UPLOAD_NEW_FILE_START = "UPLOAD_NEW_FILE_START";

//create new folder
export const CREATE_NEW_FOLDER_SUCCESS = "CREATE_NEW_FOLDER_SUCCESS";
export const CREATE_NEW_FOLDER_FAILURE = "CREATE_NEW_FOLDER_FAILURE";
export const CREATE_NEW_FOLDER_START = "CREATE_NEW_FOLDER_START";

// Copy File or Folder
export const COPY_FILE_OR_FOLDER_START = "COPY_FILE_OR_FOLDER_START";
export const COPY_FILE_OR_FOLDER_SUCCESS = "COPY_FILE_OR_FOLDER_SUCCESS";
export const COPY_FILE_OR_FOLDER_FAILURE = "COPY_FILE_OR_FOLDER_FAILURE";

// Delete File or Folder
export const DELETE_FILE_OR_FOLDER_START = "DELETE_FILE_OR_FOLDER_START";
export const DELETE_FILE_OR_FOLDER_SUCCESS = "DELETE_FILE_OR_FOLDER_SUCCESS";
export const DELETE_FILE_OR_FOLDER_FAILURE = "DELETE_FILE_OR_FOLDER_FAILURE";

// Rename File or Folder
export const RENAME_FILE_OR_FOLDER_START = "RENAME_FILE_OR_FOLDER_START";
export const RENAME_FILE_OR_FOLDER_SUCCESS = "RENAME_FILE_OR_FOLDER_SUCCESS";
export const RENAME_FILE_OR_FOLDER_FAILURE = "RENAME_FILE_OR_FOLDER_FAILURE";

// Move File or Folder
export const MOVE_FILE_OR_FOLDER_START = "MOVE_FILE_OR_FOLDER_START";
export const MOVE_FILE_OR_FOLDER_SUCCESS = "MOVE_FILE_OR_FOLDER_SUCCESS";
export const MOVE_FILE_OR_FOLDER_FAILURE = "MOVE_FILE_OR_FOLDER_FAILURE";

// Download File or Folder
export const DOWNLOAD_FILE_OR_FOLDER_START = "DOWNLOAD_FILE_OR_FOLDER_START";
export const DOWNLOAD_FILE_OR_FOLDER_SUCCESS =
  "DOWNLOAD_FILE_OR_FOLDER_SUCCESS";
export const DOWNLOAD_FILE_OR_FOLDER_FAILURE =
  "DOWNLOAD_FILE_OR_FOLDER_FAILURE";

// Share File or Folder
export const SHARE_FILE_OR_FOLDER_START = "SHARE_FILE_OR_FOLDER_START";
export const SHARE_FILE_OR_FOLDER_SUCCESS = "SHARE_FILE_OR_FOLDER_SUCCESS";
export const SHARE_FILE_OR_FOLDER_FAILURE = "SHARE_FILE_OR_FOLDER_FAILURE";

// Fetch Children of File or Folder
export const FETCH_CHILDREN_START = "FETCH_CHILDREN_START";
export const FETCH_CHILDREN_SUCCESS = "FETCH_CHILDREN_SUCCESS";
export const FETCH_CHILDREN_FAILURE = "FETCH_CHILDREN_FAILURE";

// Search File or Folder
export const SEARCH_FILE_OR_FOLDER_START = "SEARCH_FILE_OR_FOLDER_START";
export const SEARCH_FILE_OR_FOLDER_SUCCESS = "SEARCH_FILE_OR_FOLDER_SUCCESS";
export const SEARCH_FILE_OR_FOLDER_FAILURE = "SEARCH_FILE_OR_FOLDER_FAILURE";

//folder naviation for copy/move operation
export const FETCH_CHILDREN_SUCCESS_FOLDER_NAVIGATION =
  "FETCH_CHILDREN_SUCCESS_FOLDER_NAVIGATION";
export const FETCH_CHILDREN_FAILURE_FOLDER_NAVIGATION =
  "FETCH_CHILDREN_FAILURE_FOLDER_NAVIGATION";
export const FETCH_CHILDREN_START_FOLDER_NAVIGATION =
  "FETCH_CHILDREN_START_FOLDER_NAVIGATION";

export const UPDATE_BREADCRUMB = "UPDATE_BREADCRUMB";

//save and cancel folder selection/path selection for Save query functionality of Query module
export const SAVE_PATH_SELECTION = "SAVE_PATH_SELECTION";
export const CANCEL_PATH_SELECTION = "CANCEL_PATH_SELECTION";
export const INITIAL_PATH_EDIT_QUERY = "INITIAL_PATH_EDIT_QUERY";

//apply filter to fetched filer/folders
export const ON_CHANGE_FILE_EXPLORER_FILTER = "ON_CHANGE_FILE_EXPLORER_FILTER";

//fetch users and groups for share function
export const FETCH_USER_AND_GROUPS_LIST_START =
  "FETCH_USER_AND_GROUPS_LIST_START";
export const FETCH_USER_AND_GROUPS_LIST_SUCCESS =
  "FETCH_USER_AND_GROUPS_LIST_SUCCESS";
export const FETCH_USER_AND_GROUPS_LIST_FAILURE =
  "FETCH_USER_AND_GROUPS_LIST_FAILURE";
export const ON_CHANGE_FILE_EXPLORER_USERS_AND_GROUPS_FILTER =
  "ON_CHANGE_FILE_EXPLORER_USERS_AND_GROUPS_FILTER";

//fetch users and groups that already have share access to current file/folder
export const FETCH_SHARED_WITH_USERS_AND_GROUPS_LIST_START =
  "FETCH_SHARED_WITH_USERS_AND_GROUPS_LIST_START";
export const FETCH_SHARED_WITH_USERS_AND_GROUPS_LIST_SUCCESS =
  "FETCH_SHARED_WITH_USERS_AND_GROUPS_LIST_SUCCESS";
export const FETCH_SHARED_WITH_USERS_AND_GROUPS_LIST_FAILURE =
  "FETCH_SHARED_WITH_USERS_AND_GROUPS_LIST_FAILURE";

//default path for users
export const FETCH_DEFAULT_PATH_LIST_START = "FETCH_DEFAULT_PATH_LIST_START";
export const FETCH_DEFAULT_PATH_LIST_SUCCESS =
  "FETCH_DEFAULT_PATH_LIST_SUCCESS";
export const FETCH_DEFAULT_PATH_LIST_FAILURE =
  "FETCH_DEFAULT_PATH_LIST_FAILURE";

export const FETCH_CHILDREN_START_FOLDER_NAVIGATION_FOR_BROWSE =
  "FETCH_CHILDREN_START_FOLDER_NAVIGATION_FOR_BROWSE";
export const FETCH_CHILDREN_SUCCESS_FOLDER_NAVIGATION_FOR_BROWSE =
  "FETCH_CHILDREN_SUCCESS_FOLDER_NAVIGATION_FOR_BROWSE";

export const FETCH_CHILDREN_FAILURE_FOLDER_NAVIGATION_FOR_BROWSE =
  "FETCH_CHILDREN_FAILURE_FOLDER_NAVIGATION_FOR_BROWSE";
