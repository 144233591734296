import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import * as AuditLogActions from "store/auditLog/auditLog.actions";
import { StyledDiv } from "components/DateFormatter";

const DateFormatter = ({ value, column }) => {
  const dispatch = useDispatch();

  const formatIndex = useSelector(
    (state) => state.auditLog.auditLogs[column]
  );
  console.log(formatIndex, "co");

  const onToggleFormat = () => {
    console.log(column, "co");
    dispatch(AuditLogActions.updateDateFormatToggler(column));
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <StyledDiv onClick={onToggleFormat}>{value[formatIndex]}</StyledDiv>
    </Box>
  );
};

export default DateFormatter;
