export const openAlert = (message, severity) => {
  const genericMessage = "An unexpected error occurred.";

  // Checking if the message is not a string
  const finalMessage = typeof message === "string" ? message : genericMessage;

  return {
    type: "OPEN_ALERT",
    payload: { message: finalMessage, severity },
  };
};

export const closeAlert = () => ({
  type: "CLOSE_ALERT",
});

//test
