import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function AlertDialog({
  dialogTitle,
  children,
  open,
  handleClose,
  handleAction,
  btnName,
  disabled = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{
        sx: {
          width: "460px",
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          background: "#F3F3F3",
          fontWeight: 700,
        }}
      >
        {dialogTitle ?? "Delete"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 7,
          top: 3,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {children ? (
          children
        ) : (
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontSize: "14px",
              color: "#212121",
            }}
          >
            Are you sure you want to delete?
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          marginY: 1,
          marginX: 2,
          padding: 0,
          "& > button:first-of-type": {
            marginRight: 1,
            border: "1px solid #0000001A",
            color: "#111",
          },
          "& > button": {
            borderRadius: "4px",
            textTransform: "none",
          },
        }}
      >
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleAction} variant="contained" disabled={disabled}>
          {btnName ?? "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
