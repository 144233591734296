// project imports
import _ from "lodash";
import * as QueryEditorTypes from "./queryeditor.types";
import { formatDateAndTime, getFormattedTime } from "utils/helper";

const INITIAL_STATE = {
  error: null,
  loading: false,
  activeTab: 0,
  fetchingTableFromSchema: false,
  offset: 0,
  limit: 10,
  strippedRows: false,
  wrapLines: false,

  recentQueriesSelectedColumns: [],
  savedQueriesSelectedColumns: [],

  recentQueriesSelectedRows: [],
  savedQueriesSelectedRows: [],

  recentUpdateCreatedToggler: 0,
  recentUpdateModifiedToggler: 0,
  recentUpdateStartToggler: 0,
  savedUpdateCreatedToggler: 0,
  savedUpdateModifiedToggler: 0,

  queryConnectionList: {
    loading: false,
    error: null,
    list: [],
  },
  recentQueries: {
    loading: false,
    searchParams: "",
    strippedRows: false,
    hasMore: false,
    wrapLines: false,
    limit: 10,
    count: null,
    error: null,
    list: [],
  },
  savedQueries: {
    loading: false,
    searchParams: "",
    strippedRows: false,
    hasMore: false,
    wrapLines: false,
    limit: 10,
    count: null,
    error: null,
    list: [],
  },
  selectedTable: [],
  queryResults: [
    {
      id: "000000000001",
      queryTab: "Query1",
      connectionProfile: null,
      query: "",
      tables: [],
      isSaved: false,
      runId: null,
      queryDescription: "",
      loading: false,
      isDatabaseFetching: false,
      isTableColumnsFetching: false,
      error: null,
      status: null,
      selectedID: null,
      columns: [],
      rows: [],
      resultMessage: null,
      queryExecutionTime: null,
      resultInCurrentPage: null,
      resultSchema: [],
      resultStatus: "",

      dialect: "",
      count: 0,
      page: 0,
    },
  ],
};

const queryEditorReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  const selectedQueryResult = state.queryResults[state.activeTab];

  switch (type) {
    case QueryEditorTypes.PUSH_QUERY_TO_DATABRICKS_START:
      return {
        ...state,
        loading: true,
        // offset: 0,
        queryResults:
          state.queryResults.length > 0
            ? state.queryResults.map((result, index) =>
                index === state.activeTab
                  ? { ...result, loading: true }
                  : result
              )
            : state.queryResults,
      };

    case QueryEditorTypes.QUERY_EDITOR_CANCEL_EXECUTION_START:
      return {
        ...state,
        loading: true,
      };

    case QueryEditorTypes.UPDATE_QUERY_TABLE_COLUMN:
      const { tableType, columns } = action.payload;
      return {
        ...state,
        [tableType]: columns,
      };

    case QueryEditorTypes.UPDATE_QUERY_TABLE_SELECTED_ROW:
      const { selctedTable, rows } = action.payload;
      return {
        ...state,
        [selctedTable]: rows,
      };

    case QueryEditorTypes.FETCH_ALL_QUERY_CONNECTION_LIST_START:
      return {
        ...state,
        queryConnectionList: {
          ...state.queryConnectionList,
          loading: true,
        },
      };

    case QueryEditorTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START:
      const startQueryToBeUpdated = {
        ...selectedQueryResult,
        isDatabaseFetching: true,
      };
      const updatedStartQuery = state.queryResults.map((result) =>
        result.id === selectedQueryResult?.id ? startQueryToBeUpdated : result
      );
      return {
        ...state,
        queryResults: updatedStartQuery,
      };

    case QueryEditorTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START:
    case QueryEditorTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_START:
      const startUpdateQueryResults = {
        ...selectedQueryResult,
        isResultsFetching: true,
      };
      const updatedStartQueryResults = state.queryResults.map((result) =>
        result.id === selectedQueryResult?.id ? startUpdateQueryResults : result
      );
      return {
        ...state,
        queryResults: updatedStartQueryResults,
      };

    case QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_VIEW_START:
      const selectedTableViewIndex =
        selectedQueryResult?.fetchedTablesView?.findIndex(
          (v) => v.view_name === selectedQueryResult?.selectedTableView
        );

      if (selectedTableViewIndex !== -1) {
        const updatedTable = {
          ...selectedQueryResult.fetchedTablesView[selectedTableViewIndex],
        };
        updatedTable.loading = true;
        const updatedTables = [...selectedQueryResult.fetchedTablesView];
        updatedTables[selectedTableViewIndex] = updatedTable;

        const updatedQueryResult = {
          ...selectedQueryResult,
          fetchedTablesView: updatedTables,
        };
        const updatedQueryResults = state.queryResults.map((result) =>
          result.id === selectedQueryResult?.id ? updatedQueryResult : result
        );
        return {
          ...state,
          queryResults: updatedQueryResults,
        };
      } else {
        return state;
      }

    case QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_START:
      const selectedTableIndex = selectedQueryResult?.fetchedTables?.findIndex(
        (t) => t.table_name === selectedQueryResult?.selectedTable
      );

      if (selectedTableIndex !== -1) {
        const updatedTable = {
          ...selectedQueryResult.fetchedTables[selectedTableIndex],
        };
        updatedTable.loading = true;
        const updatedTables = [...selectedQueryResult.fetchedTables];
        updatedTables[selectedTableIndex] = updatedTable;

        const updatedQueryResult = {
          ...selectedQueryResult,
          fetchedTables: updatedTables,
          isTableColumnsFetching: true,
        };
        const updatedQueryResults = state.queryResults.map((result) =>
          result.id === selectedQueryResult?.id ? updatedQueryResult : result
        );
        return {
          ...state,
          queryResults: updatedQueryResults,
        };
      } else {
        return state;
      }

    case QueryEditorTypes.SAVE_QUERY_ON_DATABRICKS_START:
      return {
        ...state,
        loading: true,
      };

    case QueryEditorTypes.FETCH_RECENT_QUERIES_START:
      return {
        ...state,
        recentQueries: {
          ...state.recentQueries,
          loading: true,
        },
      };

    case QueryEditorTypes.DELETE_RECENT_QUERIES_START:
      return {
        ...state,
        recentQueries: {
          ...state.recentQueries,
          loading: true,
        },
      };

    case QueryEditorTypes.FETCH_SAVED_QUERIES_START:
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          loading: true,
        },
      };

    case QueryEditorTypes.DELETE_SAVED_QUERIES_START:
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          loading: true,
        },
      };

    case QueryEditorTypes.UPDATE_SAVED_QUERIES_START:
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          loading: true,
        },
      };

    case QueryEditorTypes.PUSH_QUERY_TO_DATABRICKS_SUCCESS:
      console.log("runqueryResults", payload);
      return {
        ...state,
        loading: false,
      };

    case QueryEditorTypes.QUERY_EDITOR_CANCEL_EXECUTION_SUCCESS:
      const queryToBeCancelled = {
        ...selectedQueryResult,
        loading: false,
      };

      return {
        ...state,
        loading: false,
        queryResults: state.queryResults?.map((result) =>
          result.id === selectedQueryResult?.id ? queryToBeCancelled : result
        ),
      };

    case QueryEditorTypes.FETCH_ALL_QUERY_CONNECTION_LIST_SUCCESS:
      return {
        ...state,
        queryConnectionList: {
          ...state.queryConnectionList,
          loading: false,
          list: payload?.results,
        },
      };

    case QueryEditorTypes.SAVE_QUERY_ON_DATABRICKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case QueryEditorTypes.ON_CHANGE_APPLY_RECENT_QUERY_FILTER:
      const { key, value } = action.payload;
      return {
        ...state,
        recentQueries: {
          ...state.recentQueries,
          [key]: value,
        },
      };

    case QueryEditorTypes.FETCH_RECENT_QUERIES_SUCCESS:
      const formattedList = payload?.results?.data?.map((r) => ({
        ...r,
        start_time: r.start_time ? getFormattedTime(r.start_time) : "-",
        created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
        updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
      }));
      return {
        ...state,
        recentQueries: {
          ...state.recentQueries,
          loading: false,
          count: payload?.count,
          list: formattedList,
          hasMore: payload?.next ? true : false,
        },
      };

    case QueryEditorTypes.DELETE_RECENT_QUERIES_SUCCESS:
      return {
        ...state,
        recentQueries: {
          ...state.recentQueries,
          loading: false,
        },
      };

    case QueryEditorTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS:
      const successQueryToBeUpdated = {
        ...selectedQueryResult,
        ...(payload?.type === "schema"
          ? {
              schemas: payload?.schema,
              database: [],
              isDatabaseFetching: false,
            }
          : {
              database: payload?.database,
              schemas: [],
              isDatabaseFetching: false,
            }),
      };
      const updatedSuccessQuery = state.queryResults.map((result) =>
        result.id === selectedQueryResult.id ? successQueryToBeUpdated : result
      );
      return {
        ...state,
        queryResults: updatedSuccessQuery,
      };

    case QueryEditorTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS:
    case QueryEditorTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS:
      const successUpdateQueryResults = {
        ...selectedQueryResult,
        ...(payload?.type === "table_view"
          ? {
              fetchedTables: !_.isEmpty(payload?.table)
                ? payload?.table.map((tableName) => ({
                    table_name: tableName,
                    columns: [],
                  }))
                : [],
              fetchedTablesView: !_.isEmpty(payload?.view)
                ? payload?.view.map((viewName) => ({
                    view_name: viewName,
                    columns: [],
                  }))
                : [],
              isResultsFetching: false,
            }
          : payload?.type === "table"
          ? {
              fetchedTables: !_.isEmpty(payload?.table)
                ? payload?.table.map((tableName) => ({
                    table_name: tableName,
                    columns: [],
                  }))
                : [],
              fetchedTablesView: [],
              fetchedSchemas: [],
              isResultsFetching: false,
            }
          : {
              fetchedSchemas: payload?.schema,
              fetchedTables: [],
              fetchedTablesView: [],
              isResultsFetching: false,
              nextType: payload?.next_type ?? "",
            }),
      };
      const updatedSuccessQueryResults = state.queryResults.map((result) =>
        result.id === selectedQueryResult.id
          ? successUpdateQueryResults
          : result
      );
      return {
        ...state,
        queryResults: updatedSuccessQueryResults,
      };

    case QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_VIEW_SUCCESS:
      const selectedTableViewIndex1 =
        selectedQueryResult?.fetchedTablesView?.findIndex(
          (v) => v.view_name === selectedQueryResult?.selectedTableView
        );

      if (selectedTableViewIndex1 !== -1) {
        const updatedTable = {
          ...selectedQueryResult.fetchedTablesView[selectedTableViewIndex1],
        };
        updatedTable.loading = false;
        updatedTable.columns = payload;
        const updatedTables = [...selectedQueryResult.fetchedTablesView];
        updatedTables[selectedTableViewIndex1] = updatedTable;

        const updatedQueryResult = {
          ...selectedQueryResult,
          fetchedTablesView: updatedTables,
        };
        const updatedQueryResults = state.queryResults.map((result) =>
          result.id === selectedQueryResult?.id ? updatedQueryResult : result
        );
        return {
          ...state,
          queryResults: updatedQueryResults,
        };
      } else {
        return state;
      }

    case QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_SUCCESS:
      const selectedTableIndex1 = selectedQueryResult?.fetchedTables?.findIndex(
        (t) => t.table_name === selectedQueryResult?.selectedTable
      );

      if (selectedTableIndex1 !== -1) {
        const updatedTable = {
          ...selectedQueryResult.fetchedTables[selectedTableIndex1],
        };
        updatedTable.loading = false;
        updatedTable.columns = payload;
        console.log("updatedTable", updatedTable);
        const updatedTables = [...selectedQueryResult.fetchedTables];
        updatedTables[selectedTableIndex1] = updatedTable;

        const updatedQueryResult = {
          ...selectedQueryResult,
          fetchedTables: updatedTables,
          isTableColumnsFetching: false,
        };
        const updatedQueryResults = state.queryResults.map((result) =>
          result.id === selectedQueryResult?.id ? updatedQueryResult : result
        );
        return {
          ...state,
          queryResults: updatedQueryResults,
        };
      } else {
        return state;
      }

    case QueryEditorTypes.ON_CHANGE_APPLY_SAVED_QUERY_FILTER:
      const { keys, values } = action.payload;
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          [keys]: values,
        },
      };

    case QueryEditorTypes.FETCH_SAVED_QUERIES_SUCCESS:
      const formattedListSaved = payload?.results?.data?.map((r) => ({
        ...r,
        created_at: r.created_at ? getFormattedTime(r.created_at) : "-",
        updated_at: r.updated_at ? getFormattedTime(r.updated_at) : "-",
      }));
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          loading: false,
          count: payload?.count,
          list: formattedListSaved,
          hasMore: payload?.next ? true : false,
        },
      };

    case QueryEditorTypes.DELETE_SAVED_QUERIES_SUCCESS:
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          loading: false,
        },
      };

    case QueryEditorTypes.UPDATE_SAVED_QUERIES_SUCCESS:
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          loading: false,
        },
      };

    // case QueryEditorTypes.PUSH_QUERY_TO_DATABRICKS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: payload,
    //   };

    case QueryEditorTypes.PUSH_QUERY_TO_DATABRICKS_FAILURE:
      return {
        ...state,
        queryResults: state.queryResults.map((queryResult, index) =>
          index === state.activeTab
            ? { ...queryResult, error: payload, loading: false }
            : queryResult
        ),
        error: payload,
        loading: false,
      };

    case QueryEditorTypes.QUERY_EDITOR_CANCEL_EXECUTION_FAILURE:
      const queryToBeCancelled1 = {
        ...selectedQueryResult,
        loading: false,
      };

      return {
        ...state,
        queryResults: state.queryResults?.map((result) =>
          result.id === selectedQueryResult?.id ? queryToBeCancelled1 : result
        ),
        error: payload,
      };

    case QueryEditorTypes.FETCH_ALL_QUERY_CONNECTION_LIST_FAILURE:
      return {
        ...state,
        queryConnectionList: {
          ...state.queryConnectionList,
          loading: false,
          error: payload,
        },
      };

    case QueryEditorTypes.SAVE_QUERY_ON_DATABRICKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case QueryEditorTypes.FETCH_RECENT_QUERIES_FAILURE:
      return {
        ...state,
        recentQueries: {
          ...state.recentQueries,
          error: action.payload,
          loading: false,
        },
      };

    case QueryEditorTypes.DELETE_RECENT_QUERIES_FAILURE:
      return {
        ...state,
        recentQueries: {
          ...state.recentQueries,
          error: action.payload,
          loading: false,
        },
      };

    case QueryEditorTypes.FETCH_SAVED_QUERIES_FAILURE:
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          error: action.payload,
          loading: false,
        },
      };

    case QueryEditorTypes.DELETE_SAVED_QUERIES_FAILURE:
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          error: action.payload,
          loading: false,
        },
      };

    case QueryEditorTypes.UPDATE_SAVED_QUERIES_FAILURE:
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          error: action.payload,
          loading: false,
        },
      };

    case QueryEditorTypes.FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE:
      const failedQueryToBeUpdated = {
        ...selectedQueryResult,
        isDatabaseFetching: false,
        database: [],
        schemas: [],
      };
      const updatedFailedQuery = state.queryResults.map((result) =>
        result.id === selectedQueryResult?.id ? failedQueryToBeUpdated : result
      );
      return {
        ...state,
        queryResults: updatedFailedQuery,
      };

    case QueryEditorTypes.FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE:
    case QueryEditorTypes.FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE:
      const failedUpdateQueryResults = {
        ...selectedQueryResult,
        fetchedTables: [],
        fetchedTablesView: [],
        fetchedSchemas: [],
        isResultsFetching: false,
      };
      const updatedFailedQueryResults = state.queryResults.map((result) =>
        result.id === selectedQueryResult?.id
          ? failedUpdateQueryResults
          : result
      );
      return {
        ...state,
        queryResults: updatedFailedQueryResults,
      };

    case QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_VIEW_FAILURE:
      const selectedTableViewIndex2 =
        selectedQueryResult?.fetchedTablesView?.findIndex(
          (v) => v.view_name === selectedQueryResult?.selectedTableView
        );

      if (selectedTableViewIndex2 !== -1) {
        const updatedTable = {
          ...selectedQueryResult.fetchedTablesView[selectedTableViewIndex2],
        };
        updatedTable.loading = false;
        updatedTable.columns = [];
        const updatedTables = [...selectedQueryResult.fetchedTablesView];
        updatedTables[selectedTableViewIndex2] = updatedTable;

        const updatedQueryResult = {
          ...selectedQueryResult,
          fetchedTablesView: updatedTables,
        };
        const updatedQueryResults = state.queryResults.map((result) =>
          result.id === selectedQueryResult?.id ? updatedQueryResult : result
        );
        return {
          ...state,
          queryResults: updatedQueryResults,
        };
      } else {
        return state;
      }

    case QueryEditorTypes.FETCH_COLUMN_FROM_TABLE_FAILURE:
      const selectedTableIndex2 = selectedQueryResult?.fetchedTables?.findIndex(
        (t) => t.table_name === selectedQueryResult?.selectedTable
      );

      if (selectedTableIndex2 !== -1) {
        const updatedTable = {
          ...selectedQueryResult.fetchedTables[selectedTableIndex2],
        };
        updatedTable.loading = false;
        updatedTable.columns = [];
        const updatedTables = [...selectedQueryResult.fetchedTables];
        updatedTables[selectedTableIndex2] = updatedTable;

        const updatedQueryResult = {
          ...selectedQueryResult,
          fetchedTables: updatedTables,
          isTableColumnsFetching: false,
        };
        const updatedQueryResults = state.queryResults.map((result) =>
          result.id === selectedQueryResult?.id ? updatedQueryResult : result
        );
        return {
          ...state,
          queryResults: updatedQueryResults,
        };
      } else {
        return state;
      }

    case QueryEditorTypes.ADD_QUERY_EDITOR_TAB:
      return {
        ...state,
        queryResults: [...state.queryResults, payload],
      };

    case QueryEditorTypes.REMOVE_QUERY_EDITOR_TAB:
      return {
        ...state,
        queryResults: state.queryResults.filter(
          (tab) => tab.queryTab !== payload
        ),
      };

    case QueryEditorTypes.ON_CHANGE_APPLY_QUERY_TAB_VALUE:
      const { index, updates } = action.payload;

      if (index >= 0 && index < state.queryResults.length) {
        const updatedTab = { ...state.queryResults[index] };

        // Apply each update
        updates?.forEach(({ key, value }) => {
          updatedTab[key] = value;
        });

        const updatedQueryResults = [...state.queryResults];
        updatedQueryResults[index] = updatedTab;

        return {
          ...state,
          queryResults: updatedQueryResults,
        };
      }
      return state;

    case QueryEditorTypes.ON_CHANGE_QUERY_TAB_ACTIVE:
      return {
        ...state,
        activeTab: payload,
      };

    case QueryEditorTypes.ON_CHANGE_APPLY_QUERY_FILTER:
      const { key3, value3 } = action.payload;
      if (key3 === "pageReset") {
        return {
          ...state,
          queryResults: state.queryResults.map((result, index) =>
            index === state.activeTab ? { ...result, page: 0 } : result
          ),
        };
      } else {
        return {
          ...state,
          [key3]: value3,
        };
      }

    // case QueryEditorTypes.GET_COMPLETED_SAVE_QUERY_SUCCESS:
    //   const { data: completedQueryData, index: completedQueryIndex } =
    //     action.payload;
    //   const ignoreFields = new Set(["_id", "count"]);
    //   const allKeys = new Set();
    //   completedQueryData.forEach((item) => {
    //     Object.keys(item).forEach((key) => {
    //       if (!ignoreFields.has(key)) {
    //         allKeys.add(key);
    //       }
    //     });
    //   });
    //   const sortedKeys = Array.from(allKeys).sort();
    //   const columnData = sortedKeys.map((field, index) => ({
    //     id: index + 1,
    //     field: field,
    //   }));
    //   return {

    //   };

    case QueryEditorTypes.UPDATE_DATE_FORMAT_TOGGLER_QUERY:
      const { column1 } = action.payload;
      console.log(column1, "column name");
      return {
        ...state,
        [column1]: (state[column1] + 1) % 3,
      };

    default:
      return state;
  }
};

export default queryEditorReducer;
