// project imports
import { getFormattedTime } from "utils/helper";
import * as DriverType from "./driver.types";

import _ from "lodash";

const INITIAL_STATE = {
  error: null,
  loading: false,
  categories: null,
  soloDatabase: null,
  isDownloading: false,
  driverById: [],
  driverUses: {
    updateDateToggler: 0,
    searchParams: "",
    connection_check5: null,
    status: null,
    limit: 30,
    strippedRows: false,
    rowClickSelection: false,
    wrapLines: false,
    hasMore: false,
    loading: false,
    error: null,
    count: null,
    list: null,
  },
  sourceDrivers: [],
  databases: {
    searchParams: "",
    activeCategory: "",
    limit: 30,
    hasMore: false,
    loading: false,
    error: null,
    count: null,
    list: null,
  },
  savedQueries: [],
};

const driverReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case DriverType.FETCH_DRIVER_CATEGORIES_START:
      return {
        ...state,
        loading: true,
      };

    case DriverType.FETCH_DRIVER_DATABASES_START:
      return {
        ...state,
        databases: {
          ...state.databases,
          loading: true,
        },
      };

    case DriverType.FETCH_DATABASE_BY_ID_START:
      return {
        ...state,
        loading: true,
      };

    case DriverType.DOWNLOAD_DEFAULT_DRIVER_START:
      return {
        ...state,
        isDownloading: true,
      };

    case DriverType.FETCH_ALL_AVAILABLE_SOURCE_START:
      return {
        ...state,
        loading: true,
      };

    case DriverType.FETCH_DRIVER_USES_START:
      return {
        ...state,
        driverUses: {
          ...state.driverUses,
          loading: true,
        },
      };
    case DriverType.FETCH_DRIVER_CATEGORIES_SUCCESS:
      const updatedCategories = _.isArray(payload)
        ? _(payload)
            .union([
              {
                id: 0,
                name: "All",
              },
            ])
            .orderBy("id", "asc")
            .value()
        : [];

      return {
        ...state,
        loading: false,
        categories: updatedCategories,
      };

    case DriverType.ON_CHANGE_APPLY_DATABASE_FILTER:
      const { key, value } = action.payload;
      return {
        ...state,
        databases: {
          ...state.databases,
          [key]: value,
        },
      };

    case DriverType.RESET_DRIVER_USES:
      return {
        ...state,
        driverUses: {
          ...INITIAL_STATE.driverUses,
          limit: state.driverUses.limit,
          strippedRows: state.driverUses.strippedRows,
          wrapLines: state.driverUses.wrapLines,
          rowClickSelection: state.driverUses.rowClickSelection,
          displayColumns: state.driverUses.displayColumns,
        },
      };

    case DriverType.UPDATE_DATE_FORMAT_TOGGLER:
      const { columnName } = action.payload;
      return {
        ...state,
        driverUses: {
          ...state.driverUses,
          [columnName]: (state.driverUses[columnName] + 1) % 3,
        },
      };

    case DriverType.FETCH_DRIVER_DATABASES_SUCCESS:
      return {
        ...state,
        databases: {
          ...state.databases,
          loading: false,
          count: payload?.count,
          hasMore: payload?.next ? true : false,
          list: payload?.results,
        },
      };

    case DriverType.FETCH_DATABASE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        soloDatabase: payload,
      };

    case DriverType.CLEAR_FETCHED_DATABASE:
      return {
        ...state,
        soloDatabase: null,
      };

    case DriverType.DOWNLOAD_DEFAULT_DRIVER_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        soloDatabase: {
          ...state.soloDatabase,
          driver: state.soloDatabase.driver.map((driver) =>
            driver.id === payload.id ? { ...driver, ...payload } : driver
          ),
        },
      };

    case DriverType.FETCH_ALL_AVAILABLE_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        sourceDrivers: payload?.results,
      };

    case DriverType.DELETE_DRIVER_TO_DATABASE_SUCCESS:
      return {
        ...state,
        soloDatabase: {
          ...state.soloDatabase,
          driver: state.soloDatabase.driver.filter(
            (driver) => driver.id !== payload
          ),
        },
      };

    case DriverType.UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_SUCCESS:
      return {
        ...state,
        soloDatabase: {
          ...state.soloDatabase,
          driver: state.soloDatabase.driver.map((driver) =>
            driver.id === payload.id ? { ...driver, ...payload } : driver
          ),
        },
      };

    case DriverType.UPDATE_STATUS_DRIVER_TO_DATABASE_SUCCESS:
      console.log(payload, "ssw");
      return {
        ...state,
        soloDatabase: {
          ...state.soloDatabase,
          driver: state.soloDatabase.driver.map((driver) =>
            driver.id === payload
              ? { ...driver, enable: !driver.enable }
              : driver
          ),
        },
      };

    case DriverType.FETCH_DRIVER_USES_SUCCESS:
      const formattedList = payload?.results?.map((r) => ({
        ...r,
        updated_at: r.updated_at ? getFormattedTime(r.updated_at) : " ",
      }));
      return {
        ...state,
        driverUses: {
          ...state.driverUses,
          loading: false,
          count: payload?.count,
          hasMore: payload?.next ? true : false,
          list: formattedList,
        },
      };

    case DriverType.ON_CHANGE_DRIVER_USES_FILTER:
      const { keys, values } = action.payload;

      return {
        ...state,
        driverUses: {
          ...state.driverUses,
          [keys]: values,
          loading: false,
        },
      };

    case DriverType.FETCH_DRIVER_DATABASES_FAILURE:
      return {
        ...state,
        ...state.databases,
        databases: {
          ...state.databases,
          loading: false,
          error: payload,
        },
      };

    case DriverType.FETCH_DATABASE_BY_ID_FAILURE:
    case DriverType.FETCH_DRIVER_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case DriverType.DOWNLOAD_DEFAULT_DRIVER_FAILURE:
      return {
        ...state,
        isDownloading: false,
        error: payload,
      };

    case DriverType.FETCH_ALL_AVAILABLE_SOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case DriverType.FETCH_DRIVER_BY_ID_START:
      return {
        ...state,
        loading: true,
      };

    case DriverType.FETCH_DRIVER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        driverById: payload,
      };

    case DriverType.FETCH_DRIVER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case DriverType.FETCH_SAVEDQUERY_BY_ID_START:
      return {
        ...state,
        loading: true,
      };

    case DriverType.FETCH_SAVEDQUERY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        savedQueries: payload,
      };

    case DriverType.FETCH_SAVEDQUERY_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case DriverType.CLEAR_SAVED_QUERIES:
      return {
        ...state,
        savedQueries: [],
      };

    default:
      return state;
  }
};

export default driverReducer;
