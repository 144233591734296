export default function componentStyleOverrides(theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          "&:last-child .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        },
      },
    },
  };
}
