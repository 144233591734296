// project imports
import * as DriverType from "./driver.types";
import axiosConfig from "../../config/axiosConfig";
import { openAlert } from "../alert/alert.actions";
import _ from "lodash";

export const fetchDriverCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: DriverType.FETCH_DRIVER_CATEGORIES_START,
    });

    const { data } = await axiosConfig.get(`/drivers/categories/`);
    dispatch({
      type: DriverType.FETCH_DRIVER_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DriverType.FETCH_DRIVER_CATEGORIES_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const updateDateFormatToggler = (columnName) => ({
  type: DriverType.UPDATE_DATE_FORMAT_TOGGLER,
  payload: { columnName },
});

export const fetchDriverDatabases = () => async (dispatch, getState) => {
  const {
    driver: {
      databases: { searchParams, activeCategory, limit, offset },
    },
  } = getState();

  try {
    dispatch({ type: DriverType.FETCH_DRIVER_DATABASES_START });
    let url = "/drivers/databases/";
    const queryParams = [];

    if (activeCategory) {
      queryParams.push(`category__slug=${activeCategory}`);
    }
    if (limit) {
      queryParams.push(`limit=${limit}`);
    }
    if (offset) {
      queryParams.push(`offset=${offset}`);
    }

    if (searchParams) {
      queryParams.push(`search=${searchParams}`);
    }

    if (queryParams.length > 0) {
      url += "?" + queryParams.join("&");
    }

    const { data } = await axiosConfig.get(url);

    dispatch({
      type: DriverType.FETCH_DRIVER_DATABASES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DriverType.FETCH_DRIVER_DATABASES_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const onChangeApplyDatabaseFilter = (key, value) => ({
  type: DriverType.ON_CHANGE_APPLY_DATABASE_FILTER,
  payload: { key, value },
});

export const fetchDatabaseById =
  (id, enable = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: DriverType.FETCH_DATABASE_BY_ID_START });

      let url = `/drivers/${id}/driver/`;

      const queryParams = [];

      if (enable) {
        queryParams.push(`enable=True`);
      }

      if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
      }

      const { data } = await axiosConfig.get(url);

      dispatch({
        type: DriverType.FETCH_DATABASE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DriverType.FETCH_DATABASE_BY_ID_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Failed to load database and its drivers"
          }`,
          "error"
        )
      );
    }
  };

export const fetchDriverById = (id) => async (dispatch) => {
  try {
    dispatch({ type: DriverType.FETCH_DRIVER_BY_ID_START });

    const { data } = await axiosConfig.get("/drivers/drivers/", {
      params: {
        database: id,
      },
    });

    dispatch({
      type: DriverType.FETCH_DRIVER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DriverType.FETCH_DRIVER_BY_ID_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Failed to load drivers of given database"
        }`,
        "error"
      )
    );
  }
};

export const fetchDriverUses = (id) => async (dispatch, getState) => {
  const {
    driver: {
      driverUses: { status, limit, offset, searchParams },
    },
  } = getState();
  console.log(id, "test1");
  try {
    dispatch({ type: DriverType.FETCH_DRIVER_USES_START });

    let url = `/drivers/driver-connectionprofile/${id}/`;
    const queryParams = [];

    if (status) {
      queryParams.push(`status=${status === "enable" ? true : false}`);
    }

    if (limit) {
      queryParams.push(`limit=${limit}`);
    }

    if (offset) {
      queryParams.push(`offset=${offset}`);
    }

    if (searchParams) {
      queryParams.push(`query=${searchParams}`);
    }

    if (queryParams.length > 0) {
      url += "?" + queryParams.join("&");
    }

    console.log(searchParams, queryParams, "gg");

    const { data } = await axiosConfig.get(url);

    dispatch({
      type: DriverType.FETCH_DRIVER_USES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DriverType.FETCH_DRIVER_USES_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });

    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Failed to load driver uses"
        }`,
        "error"
      )
    );
  }
};

export const onChangeApplyDriverFilter = (keys, values) => ({
  type: DriverType.ON_CHANGE_DRIVER_USES_FILTER,
  payload: { keys, values },
});

export const downloadDefaultDriver =
  (name, enqueueSnackbar, cb) => async (dispatch) => {
    try {
      dispatch({ type: DriverType.DOWNLOAD_DEFAULT_DRIVER_START });

      const { data } = await axiosConfig.post(`/drivers/fetch-from-s3/`, {
        database_name: name,
      });
      dispatch({
        type: DriverType.DOWNLOAD_DEFAULT_DRIVER_SUCCESS,
        payload: data,
      });

      !_.isEmpty(data) &&
        data?.map((d) =>
          enqueueSnackbar(d?.message ?? "", {
            variant: d?.type ?? "success",
          })
        );

      if (cb) {
        cb();
      }
    } catch (error) {
      console.log(error, "error");
      dispatch({
        type: DriverType.DOWNLOAD_DEFAULT_DRIVER_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Failed to download driver"
          }`,
          "error"
        )
      );
    }
  };

export const uploadOrUpdateDriverToDatabase =
  (id, driverId, values, cb) => async (dispatch) => {
    try {
      dispatch({ type: DriverType.UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_START });

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("enable", values.enable);
      formData.append("status", values.status);
      //formData.append("file", values.file);
      if (!driverId) {
        formData.append("file", values.file);
      }

      if (typeof values.file !== "string") {
        formData.append("file", values.file);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let url = driverId
        ? `/drivers/${id}/driver/${driverId}/`
        : `/drivers/${id}/driver/`;
      let method = driverId ? "patch" : "post";
      const { data } = await axiosConfig.request({
        method,
        url,
        data: formData,
        config,
      });
      dispatch({
        type: DriverType.UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_SUCCESS,
        payload: data,
      });

      dispatch(
        openAlert(
          `${
            !driverId
              ? "New driver created successfully"
              : "Driver updated successfully"
          }`,
          "success"
        )
      );

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({
        type: DriverType.UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Failed Driver Operation"
          }`,
          "error"
        )
      );
    }
  };

export const updateDriverStatusToDatabase =
  (id, driverId, status, cb) => async (dispatch) => {
    try {
      dispatch({ type: DriverType.UPDATE_STATUS_DRIVER_TO_DATABASE_START });

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let url = `/drivers/${id}/enable-driver/${driverId}/`;

      let method = "post";
      const { data } = await axiosConfig.request({
        method,
        url,
        data: status,
        config,
      });
      dispatch({
        type: DriverType.UPDATE_STATUS_DRIVER_TO_DATABASE_SUCCESS,
        payload: driverId,
      });

      if (status?.enable === false) {
        if (cb) {
          cb();
        }
      } else {
        dispatch(openAlert(data.message, "success"));
      }
    } catch (error) {
      dispatch({
        type: DriverType.UPDATE_STATUS_DRIVER_TO_DATABASE_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Failed to update status"
          }`,
          "error"
        )
      );
    }
  };

export const deleteDriverFromDatabase =
  (id, driverId, cb) => async (dispatch) => {
    try {
      dispatch({ type: DriverType.DELETE_DRIVER_TO_DATABASE_START });

      let url = `/drivers/${id}/delete-driver/${driverId}/`;

      let method = "delete";
      const { data } = await axiosConfig.request({
        method,
        url,
      });

      fetchDatabaseById(id);

      dispatch({
        type: DriverType.DELETE_DRIVER_TO_DATABASE_SUCCESS,
        payload: driverId,
      });

      dispatch(openAlert(`${"Driver deleted successfully"}`, "success"));

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({
        type: DriverType.DELETE_DRIVER_TO_DATABASE_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Failed to update status"
          }`,
          "error"
        )
      );
    }
  };

export const fetchAvailableSource = () => async (dispatch) => {
  try {
    dispatch({ type: DriverType.FETCH_ALL_AVAILABLE_SOURCE_START });

    const { data } = await axiosConfig.get(`/drivers/databases/?limit=10000`);
    dispatch({
      type: DriverType.FETCH_ALL_AVAILABLE_SOURCE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DriverType.FETCH_ALL_AVAILABLE_SOURCE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Failed to load drivers"
        }`,
        "error"
      )
    );
  }
};

export const clearSavedQueries = () => ({
  type: DriverType.CLEAR_SAVED_QUERIES,
});

export const fetchSavedQueriesById = (id) => async (dispatch) => {
  try {
    dispatch({ type: DriverType.FETCH_SAVEDQUERY_BY_ID_START });
    const { data } = await axiosConfig.get(
      `/drivers/${id}/driver-connectionprofile-exist-check`
    );

    dispatch({
      type: DriverType.FETCH_SAVEDQUERY_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DriverType.FETCH_SAVEDQUERY_BY_ID_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    // dispatch(
    //   openAlert(
    //     `${
    //       error.response && error.response.data.error
    //         ? error.response.data.error
    //         : error.message ?? "Failed to load saved queries of driver."
    //     }`,
    //     "error"
    //   )
    // );
  }
};
