const routeMap = {
  "/manage/connection": "connection-profile-overview",
  "/manage/driver": "driver-management-overview#driver-management-main-page",
  "/manage/driver/:id": "driver-management-overview#driver-management-section",
  "/manage/connection/add-profile":
    "connection-profile-overview#2-adding-a-new-connection-profile",
  "/manage/settings/user-management": "connection-profile-overview",
  "/manage/settings/user-management?tab=1":
    "user-management-overview#2-user-list",
  "/manage/settings/user-management?tab=2":
    "user-management-overview#3-group-management",
};

const docsRoutingMapper = (pathname, isSlug) => {
  console.log(isSlug);
  const pathnameParts = pathname.split("/");

  if (isSlug) {
    pathnameParts.pop();
    pathnameParts.push(":id");
  }

  const pathnameWithoutSlug = pathnameParts.join("/");
  const docsRoute = routeMap[pathnameWithoutSlug];
  return docsRoute ? `https://dev.docs.datafuseai.com/${docsRoute}` : null;
};

export default docsRoutingMapper;
