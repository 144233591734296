import React from "react";

// third party libraries
import { Box, Container, Typography } from "@mui/material";
import { FooterWrapper } from "../index";
import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

// ==============================|| FOOTER ||============================== //
export default function Footer({ open }) {
  return (
    <FooterWrapper open={open}>
      <Box
        sx={{
          minHeight: "30px",
          backgroundColor: "#D9E8F5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              overflow: "hidden",
              "& p": {
                color: "#212121",
              },
            }}
          >
            <Typography>©2024, DatafuseAI</Typography>
            <Box display="flex" gap={2}>
              <MuiLink
                component={RouterLink}
                to="/privacy"
                sx={{
                  textDecoration: "none",
                  color: "inherit",
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
              >
                <Typography>Privacy</Typography>
              </MuiLink>
              <MuiLink
                component={RouterLink}
                to="/terms"
                sx={{
                  textDecoration: "none",
                  color: "inherit",
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
              >
                <Typography>Terms</Typography>
              </MuiLink>
            </Box>
          </Box>
        </Container>
      </Box>
    </FooterWrapper>
  );
}
