import palette from "./palette";
import typography from "./typography";
import componentStyleOverrides from "./compStyleOverride";

import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette,
  typography,
});

theme.components = componentStyleOverrides(theme);

export default theme;
