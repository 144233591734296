import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  Menu,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import { LuSearch } from "react-icons/lu";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CustomBtn } from "components/CustomInput";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AuditLogMainTable from "./AuditLogMainTable";
import { useDispatch, useSelector } from "react-redux";
import * as AuditLogActions from "store/auditLog/auditLog.actions";
import DateFormatter from "./DateFormatter";
import _, { replace, set } from "lodash";
import PreferenceDialog from "components/PreferenceDialog";
import AuditLogMainFilter from "./AuditLogMainFilter";

const AuditLogMain = () => {
  const dispatch = useDispatch();
  const [searchedValue, setSearchedValue] = useState("");
  const [openPreferenceDialog, setOpenPreferenceDialog] = useState(false);
  const gridRef = useRef(null);
  const [page, setPage] = useState(0);
  const [filterValues, setFilterValues] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { auditLogsUsers } = useSelector((state) => state.auditLog);
  const { userId } = useSelector((state) => state.auth);
  let userRowsToSend = [];

  const currentUser = auditLogsUsers?.list.find((user) => user.id === userId);

  if (currentUser) {
    if (currentUser.is_admin) {
      userRowsToSend = auditLogsUsers?.list;
    } else {
      userRowsToSend.push(currentUser);
    }
  }

  const onSelectionChanged = useCallback(() => {
    if (gridRef.current) {
      const newSelectedRows = gridRef.current.getSelectedRows();

      // checking if the same row is selected again
      if (
        selectedRows.length === 1 &&
        newSelectedRows.length === 1 &&
        selectedRows[0].email === newSelectedRows[0].email
      ) {
        // if same row selected again, dispatch null and return
        dispatch(AuditLogActions.updatedSelectedAuditLogId(null));
        setSelectedRows([]);
        localStorage.setItem("selectedRowsAudit", JSON.stringify([]));
        return;
      }

      // different row selected or multiple rows selected
      setSelectedRows(newSelectedRows);
      console.log("sel1", newSelectedRows);

      if (newSelectedRows.length > 0) {
        dispatch(
          AuditLogActions.updatedSelectedAuditLogId(
            newSelectedRows[0].modified_by
              ? newSelectedRows[0].modified_by
              : null,
            newSelectedRows[0].date
          )
        );
      } else {
        dispatch(AuditLogActions.updatedSelectedAuditLogId(null));
      }

      localStorage.setItem(
        "selectedRowsAudit",
        JSON.stringify(newSelectedRows)
      );
    }
  }, [selectedRows, dispatch]);

  const { auditLogs } = useSelector((state) => state.auditLog);

  useEffect(() => {
    if (auditLogsUsers.selectedId !== null) {
      dispatch(AuditLogActions.fetchAuditLogs());
    }
  }, [auditLogsUsers.selectedId, dispatch]);

  useEffect(() => {
    dispatch(AuditLogActions.fetchAuditLogsUsers());
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(
      AuditLogActions.onChangeApplyAuditLogFilter(
        "offset",
        newPage * auditLogs?.limit
      )
    );
    dispatch(AuditLogActions.fetchAuditLogs());
    setPage(newPage);
  };

  // const { auditLog, loading } = useSelector((state) => state.auditLog);
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "date",
      headerName: "Date",
      checkboxSelection: true,
      lockPosition: true,
      width: 180,
      hide: false,
      minWidth: 70,
      // cellRenderer: (params) => {
      //   return params?.data?.date.length > 1 ? (
      //     <Box
      //       onClick={(e) => {
      //         e.stopPropagation();
      //       }}
      //     >
      //       <DateFormatter
      //         value={params?.data?.date}
      //         column={"updateModifiedToggler"}
      //       />
      //     </Box>
      //   ) : (
      //     "-"
      //   );
      // },
    },
    {
      field: "username",
      headerName: "User",

      width: 200,
      minWidth: 90,
      hide: false,
      cellRenderer: (params) => {
        if (params?.data?.username) {
          return params.data.username;
        } else if (params?.data?.modified_by) {
          return params.data.modified_by;
        } else {
          return "-";
        }
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 200,
      suppressMenu: true,
      icons: {
        check: "tick",
      },
    };
  }, []);

  const icons = useMemo(() => {
    return {
      sortAscending: '<img src="/assets/images/icons/arrow-up.svg" />',
      sortDescending: '<img src="/assets/images/icons/arrow-down.svg" />',
    };
  }, []);

  const handleClearSearch = (value) => {
    setSearchedValue("");
    dispatch(AuditLogActions.onChangeApplyAuditLogFilter("searchParams", ""));
    dispatch(AuditLogActions.fetchAuditLogs());
  };

  const debounceDispatch = _.debounce((value) => {
    dispatch(
      AuditLogActions.onChangeApplyAuditLogFilter("searchParams", value)
    );
    dispatch(AuditLogActions.fetchAuditLogs());
  }, 1000);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchedValue(value);
    debounceDispatch(value);
  };
  const [tempColumns, setTempColumns] = useState([...columnDefs]);

  const handleColumnChange = (event, name) => {
    const updatedColumns = tempColumns.map((column) => {
      if (column.headerName === name) {
        return { ...column, hide: !event.target.checked };
      }
      return column;
    });
    setTempColumns(updatedColumns);
  };
  const [pageSize, setPageSize] = useState(50);

  const [wrapLinesToggle, setWrapLinesToggle] = useState(false);
  const [stripedRowsToggle, setStripedRowsToggle] = useState(false);

  const handleDialogSubmit = () => {
    setColumnDefs(tempColumns);
    if (pageSize !== auditLogs?.limit) {
      dispatch(AuditLogActions.onChangeApplyAuditLogFilter("limit", pageSize));
      setPage(0);
    }
    if (wrapLinesToggle !== auditLogs?.wrapLines) {
      dispatch(
        AuditLogActions.onChangeApplyAuditLogFilter(
          "wrapLines",
          wrapLinesToggle
        )
      );
    }
    if (stripedRowsToggle !== auditLogs?.strippedRows) {
      dispatch(
        AuditLogActions.onChangeApplyAuditLogFilter(
          "strippedRows",
          stripedRowsToggle
        )
      );
    }

    dispatch(AuditLogActions.fetchAuditLogs());
    setOpenPreferenceDialog(false);
  };

  const handleAuditLogRowUpdate = (newVal, oldVal) => {
    // dispatch(ConnectionActions.updateConnectionProfile(newVal));
  };
  //
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Fragment>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #f0f0f0",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
              Audit Log
            </Typography>
            <Box sx={{ width: "50%" }}>
              <Paper
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxHeight: 25,
                  border: "1px solid #0000001A",
                  color: "#FFFFFF",
                  boxShadow: "none",
                  borderRadius: "4px",
                  position: "relative",
                }}
              >
                <IconButton sx={{ p: "6px" }} aria-label="search" disabled>
                  <LuSearch
                    style={{
                      height: 16,
                      color: "#636262",
                    }}
                  />
                </IconButton>
                <InputBase
                  placeholder="Search..."
                  inputProps={{
                    "aria-label": "search databases",
                    maxLength: 30,
                  }}
                  value={searchedValue}
                  onChange={handleSearchChange}
                />
                {searchedValue?.length > 0 && (
                  <IconButton
                    type="button"
                    size="small"
                    onClick={handleClearSearch}
                    sx={{
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <ClearRoundedIcon
                      style={{ color: "#636262", height: 16 }}
                    />
                  </IconButton>
                )}
              </Paper>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
              alignItems: "center",
            }}
          >
            <CustomBtn
              onClick={handleClick}
              sx={{
                disabled: true,
                minWidth: "10px",
                width: 40,
                height: 32,
                border: "none",
                "&:hover": {
                  border: "none",
                },
              }}
            >
              <img src={"/assets/images/icons/auditFilter.svg"} alt="copy" />
            </CustomBtn>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <AuditLogMainFilter
                auditLogsUsers={userRowsToSend}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
              />
            </Menu>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {auditLogsUsers.selectedId === null ? (
                <Box></Box>
              ) : (
                <TablePagination
                  sx={{
                    "& .MuiToolbar-root": { padding: 0, minHeight: "20px" },
                    "& .MuiTablePagination-actions": {
                      marginLeft: 0,
                      marginRight: 1,
                    },
                  }}
                  component="div"
                  rowsPerPageOptions={[]}
                  count={auditLogs?.count}
                  rowsPerPage={auditLogs?.limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={() => {}}
                />
              )}
              <CustomBtn
                size="small"
                sx={{
                  borderRadius: "4px",
                  minWidth: "30px",
                  height: "32px",
                  width: "32px",
                }}
                variant="outlined"
                onClick={() => setOpenPreferenceDialog(true)}
              >
                <SettingsOutlinedIcon />
              </CustomBtn>
            </Box>
          </Box>
          <Divider />

          <Box sx={{ padding: 2, width: "100%", height: "60vh" }}>
            {auditLogsUsers.selectedId === null ? (
              <Typography>Please select a user</Typography>
            ) : (
              <AuditLogMainTable
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                icons={icons}
                gridRef={gridRef}
                onSelectionChanged={onSelectionChanged}
                handleAuditLogRowUpdate={handleAuditLogRowUpdate}
              />
            )}
          </Box>
        </Box>
        <PreferenceDialog
          open={openPreferenceDialog}
          columns={columnDefs}
          handleClose={() => setOpenPreferenceDialog(false)}
          handleColumnChange={handleColumnChange}
          handleDialogSubmit={handleDialogSubmit}
          pageSize={pageSize}
          setPageSize={setPageSize}
          wrapLinesToggle={wrapLinesToggle}
          setWrapLinesToggle={setWrapLinesToggle}
          stripedRowsToggle={stripedRowsToggle}
          setStripedRowsToggle={setStripedRowsToggle}
          needsRowSelection={false}
          pageSizeOptions={[50, 80, 100]}
        />
      </Fragment>
    </>
  );
};
export default AuditLogMain;
