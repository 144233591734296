import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const CustomToggler = styled(Switch)(({ theme }) => ({
  padding: 10,
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 14,
    height: 14,
    margin: 3,
    color: "#fff",
  },
}));

export default CustomToggler;
