// third party libraries
import { Height } from "@mui/icons-material";
import { Box, CircularProgress, TableHead, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { alpha, styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import _ from "lodash";
import { useMemo } from "react";
import { BsCaretUpFill } from "react-icons/bs";
import { BsCaretDownFill } from "react-icons/bs";
import { BsCaretDown } from "react-icons/bs";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#367AFF1A",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: "#525252",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: "36px",
  },
}));

export default function TableLoader({ rows }) {
  return (
    <TableRow hover>
      {rows &&
        rows?.map((index) => (
          <TableCell
            align="left"
            component="th"
            scope="row"
            padding="none"
            key={_.uniqueId("KEY_")}
          >
            <Skeleton
              variant="rectangular"
              style={{
                margin: "8px 0px 8px 10px",
              }}
            />
          </TableCell>
        ))}
      <TableCell component="th" scope="row" padding="none">
        <Skeleton
          width={0}
          variant="rectangular"
          style={{
            margin: "9.5px 0px",
          }}
        />
      </TableCell>
    </TableRow>
  );
}

const ODD_OPACITY = 0.2;

export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
  },
  ".MuiDataGrid-sortIcon": {
    opacity: "inherit !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "visible",
    opacity: 1,
    color: "#A5A9B3",
  },
  "& .MuiDataGrid-columnHeader": {
    background: "#367AFF1A",
    color: "#212121",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
    outline: "none !important",
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],

    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },

    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export function SortedDescendingIcon() {
  return <BsCaretDownFill size={"16px"} />;
}

export function SortedAscendingIcon() {
  return <BsCaretUpFill size={"16px"} />;
}

export function UnsortedIcon() {
  return <BsCaretDown size={"16px"} />;
}

export function getRowClass(params, strippedRows) {
  if (params.node.rowIndex % 2 === 0 && strippedRows) {
    return "strippedRows-effect";
  }
}

export function CustomLoadingOverlay(props) {
  return (
    <Box
      className="ag-overlay-loading-center"
      sx={{
        padding: "20px",
        boxShadow: "none !important",
        border: "0px solid transparent !important",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <CircularProgress size={20} />{" "}
      <Typography variant="body1">{props.loadingMessage} </Typography>
    </Box>
  );
}
