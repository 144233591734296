export const FETCH_ALL_CONNECTION_PROFILE_START =
  "FETCH_ALL_CONNECTION_PROFILE_START";
export const FETCH_ALL_CONNECTION_PROFILE_SUCCESS =
  "FETCH_ALL_CONNECTION_PROFILE_SUCCESS";
export const FETCH_ALL_CONNECTION_PROFILE_FAILURE =
  "FETCH_ALL_CONNECTION_PROFILE_FAILURE";

export const ON_CHANGE_APPLY_CONNECTION_PROFILE_FILTER =
  "ON_CHANGE_APPLY_CONNECTION_PROFILE_FILTER";

export const REFRESH_CONNECTION_PROFILE = "REFRESH_CONNECTION_PROFILE";

export const CREATE_CONNECTION_PROFILE_START =
  "CREATE_CONNECTION_PROFILE_START";
export const CREATE_CONNECTION_PROFILE_SUCCESS =
  "CREATE_CONNECTION_PROFILE_SUCCESS";
export const CREATE_CONNECTION_PROFILE_FAILURE =
  "CREATE_CONNECTION_PROFILE_FAILURE";

export const UPDATE_CONNECTION_PROFILE_START =
  "UPDATE_CONNECTION_PROFILE_START";
export const UPDATE_CONNECTION_PROFILE_SUCCESS =
  "UPDATE_CONNECTION_PROFILE_SUCCESS";
export const UPDATE_CONNECTION_PROFILE_FAILURE =
  "UPDATE_CONNECTION_PROFILE_FAILURE";

export const FETCH_CONNECTION_PROFILE_BY_ID_START =
  "FETCH_CONNECTION_PROFILE_BY_ID_START";
export const FETCH_CONNECTION_PROFILE_BY_ID_SUCCESS =
  "FETCH_CONNECTION_PROFILE_BY_ID_SUCCESS";
export const FETCH_CONNECTION_PROFILE_BY_ID_FAILURE =
  "FETCH_CONNECTION_PROFILE_BY_ID_FAILURE";

export const CHANGE_CONNECTION_PROFILE_STATUS_START =
  "CHANGE_CONNECTION_PROFILE_STATUS_START";
export const CHANGE_CONNECTION_PROFILE_STATUS_SUCCESS =
  "CHANGE_CONNECTION_PROFILE_STATUS_SUCCESS";
export const CHANGE_CONNECTION_PROFILE_STATUS_FAILURE =
  "CHANGE_CONNECTION_PROFILE_STATUS_FAILURE";

export const TEST_CONNECTION_PROFILE_START = "TEST_CONNECTION_PROFILE_START";
export const TEST_CONNECTION_PROFILE_SUCCESS =
  "TEST_CONNECTION_PROFILE_SUCCESS";
export const TEST_CONNECTION_PROFILE_FAILURE =
  "TEST_CONNECTION_PROFILE_FAILURE";

export const TEST_CONNECTION_AFTER_CONNECTION_PROFILE_START =
  "TEST_CONNECTION_AFTER_CONNECTION_PROFILE_START";
export const TEST_CONNECTION_AFTER_CONNECTION_PROFILE_SUCCESS =
  "TEST_CONNECTION_AFTER_CONNECTION_PROFILE_SUCCESS";
export const TEST_CONNECTION_AFTER_CONNECTION_PROFILE_FAILURE =
  "TEST_CONNECTION_AFTER_CONNECTION_PROFILE_FAILURE";

export const BULK_DELETE_CONNECTION_PROFILE_START =
  "BULK_DELETE_CONNECTION_PROFILE_START";
export const BULK_DELETE_CONNECTION_PROFILE_SUCCESS =
  "BULK_DELETE_CONNECTION_PROFILE_SUCCESS";
export const BULK_DELETE_CONNECTION_PROFILE_FAILURE =
  "BULK_DELETE_CONNECTION_PROFILE_FAILURE";

export const FETCH_CONNECTION_USES_START = "FETCH_CONNECTION_USES_START";
export const FETCH_CONNECTION_USES_SUCCESS = "FETCH_CONNECTION_USES_SUCCESS";
export const FETCH_CONNECTION_USES_FAILURE = "FETCH_CONNECTION_USES_FAILURE";
export const ON_CHANGE_CONNECTION_USES_FILTER =
  "ON_CHANGE_CONNECTION_USES_FILTER";

export const RESET_CONNECTION_USES = "RESET_CONNECTION_USES";
export const UPDATE_DATE_FORMAT_TOGGLER = "RESET_DATE_FORMAT_TOGGLER";
export const UPDATE_DATE_FORMAT_TOGGLER_CONNECTION = "RESET_DATE_FORMAT_TOGGLER_CONNECTION";
