import React from "react";
import Routes from "./routes";

import { useDispatch } from "react-redux";
import { loadUser } from "./store/auth/auth.actions";

const App = () => {
  const dispatch = useDispatch();
  dispatch(loadUser());
  return <Routes />;
};

export default App;
