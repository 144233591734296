export const FETCH_CLUSTER_LIST_START = "FETCH_CLUSTER_LIST_START";
export const FETCH_CLUSTER_LIST_SUCCESS = "FETCH_CLUSTER_LIST_SUCCESS";
export const FETCH_CLUSTER_LIST_FAILURE = "FETCH_CLUSTER_LIST_FAILURE";

export const UPDATE_SELECTED_PROVIDER_PARAMS =
  "UPDATE_SELECTED_PROVIDER_PARAMS";

export const FETCH_CLUSTER_LIST_POST_START = "FETCH_CLUSTER_LIST_POST_START";
export const FETCH_CLUSTER_LIST_POST_SUCCESS =
  "FETCH_CLUSTER_LIST_POST_SUCCESS";
export const FETCH_CLUSTER_LIST_POST_FAILURE =
  "FETCH_CLUSTER_LIST_POST_FAILURE";

export const GET_JAR_LIBRARIES_START = "GET_JAR_LIBRARIES_START";
export const GET_JAR_LIBRARIES_SUCCESS = "GET_JAR_LIBRARIES_SUCCESS";
export const GET_JAR_LIBRARIES_FAILURE = "GET_JAR_LIBRARIES_FAILURE";

//fetch all clusters
export const FETCH_ALL_CLUSTERS_START = "FETCH_ALL_CLUSTERS_START";
export const FETCH_ALL_CLUSTERS_SUCCESS = "FETCH_ALL_CLUSTERS_SUCCESS";
export const FETCH_ALL_CLUSTERS_FAILURE = "FETCH_ALL_CLUSTERS_FAILURE";

export const DATE_FORMAT_TOGGLER = "DATE_FORMAT_TOGGLER";

//delete engine
export const DELETE_CLUSTER_START = "DELETE_CLUSTER_START";
export const DELETE_CLUSTER_SUCCESS = "DELETE_CLUSTER_SUCCESS";
export const DELETE_CLUSTER_FAILURE = "DELETE_CLUSTER_FAILURE";

//create compute databrick engine
export const CREATE_COMPUTE_DATABRICKS_CLUSTER_START =
  "CREATE_COMPUTE_DATABRICKS_CLUSTER_START";
export const CREATE_COMPUTE_DATABRICKS_CLUSTER_SUCCESS =
  "CREATE_COMPUTE_DATABRICKS_CLUSTER_SUCCESS";
export const CREATE_COMPUTE_DATABRICKS_CLUSTER_FAILURE =
  "CREATE_COMPUTE_DATABRICKS_CLUSTER_FAILURE";

//create new cluster
export const CREATE_NEW_CLUSTER_START = "CREATE_NEW_CLUSTER_START";
export const CREATE_NEW_CLUSTER_SUCCESS = "CREATE_NEW_CLUSTER_SUCCESS";
export const CREATE_NEW_CLUSTER_FAILURE = "CREATE_NEW_CLUSTER_FAILURE";
export const UPDATE_NEW_CLUSTER_SUCCESS = "UPDATE_NEW_CLUSTER_SUCCESS";

//delete cluster
export const DELETE_CLUSTER__COMPUTE_DATABRICKS_START =
  "DELETE_CLUSTER__COMPUTE_DATABRICKS_START";
export const DELETE_CLUSTER__COMPUTE_DATABRICKS_SUCCESS =
  "DELETE_CLUSTER__COMPUTE_DATABRICKS_SUCCESS";
export const DELETE_CLUSTER__COMPUTE_DATABRICKS_FAILURE =
  "DELETE_CLUSTER__COMPUTE_DATABRICKS_FAILURE";

export const ON_CHANGE_ENGINE_FILTER = "ON_CHANGE_ENGINE_FILTER";

//get engine by id
export const GET_ENGINE_BY_ID_START = "GET_ENGINE_BY_ID_START";
export const GET_ENGINE_BY_ID_SUCCESS = "GET_ENGINE_BY_ID_SUCCESS";
export const GET_ENGINE_BY_ID_FAILURE = "GET_ENGINE_BY_ID_FAILURE";
export const CLEAR_SOLO_ENGINE = "CLEAR_SOLO_ENGINE";

//update engine
export const UPDATE_ENGINE_START = "UPDATE_ENGINE_START";
export const UPDATE_ENGINE_SUCCESS = "UPDATE_ENGINE_SUCCESS";
export const UPDATE_ENGINE_FAILURE = "UPDATE_ENGINE_FAILURE";

//play/pause cluster
export const UPDATE_CLUSTER_STATUS_START = "UPDATE_CLUSTER_STATUS_START";
export const UPDATE_CLUSTER_STATUS_SUCCESS = "UPDATE_CLUSTER_STATUS_SUCCESS";
export const UPDATE_CLUSTER_STATUS_FAILURE = "UPDATE_CLUSTER_STATUS_FAILURE";

//create or update datafuseai
export const CREATE_UPDATE_DATAFUSEAI_CONFIG_START =
  "CREATE_UPDATE_DATAFUSEAI_CONFIG_START";
export const CREATE_UPDATE_DATAFUSEAI_CONFIG_SUCCESS =
  "CREATE_UPDATE_DATAFUSEAI_CONFIG_SUCCESS";
export const CREATE_UPDATE_DATAFUSEAI_CONFIG_FAILURE =
  "CREATE_UPDATE_DATAFUSEAI_CONFIG_FAILURE";

//get datafuseai config
export const GET_DATAFUSEAI_CONFIG_START = "GET_DATAFUSEAI_CONFIG_START";
export const GET_DATAFUSEAI_CONFIG_SUCCESS = "GET_DATAFUSEAI_CONFIG_SUCCESS";
export const GET_DATAFUSEAI_CONFIG_FAILURE = "GET_DATAFUSEAI_CONFIG_FAILURE";

//test conneciton
export const TEST_CONNECTION_START = "TEST_CONNECTION_START";
export const TEST_CONNECTION_SUCCESS = "TEST_CONNECTION_SUCCESS";
export const TEST_CONNECTION_FAILURE = "TEST_CONNECTION_FAILURE";
