export const FETCH_PIPELINE_SOURCE_START = "FETCH_PIPELINE_SOURCE_START";
export const FETCH_PIPELINE_SOURCE_SUCCESS = "FETCH_PIPELINE_SOURCE_SUCCESS";
export const FETCH_PIPELINE_SOURCE_FAILURE = "FETCH_PIPELINE_SOURCE_FAILURE";

export const FETCH_PIPELINE_TRANSFORMATIONS_START =
  "FETCH_PIPELINE_TRANSFORMATIONS_START";
export const FETCH_PIPELINE_TRANSFORMATIONS_SUCCESS =
  "FETCH_PIPELINE_TRANSFORMATIONS_SUCCESS";
export const FETCH_PIPELINE_TRANSFORMATIONS_FAILURE =
  "FETCH_PIPELINE_TRANSFORMATIONS_FAILURE";

export const FETCH_PIPELINE_SINK_START = "FETCH_PIPELINE_SINK_START";
export const FETCH_PIPELINE_SINK_SUCCESS = "FETCH_PIPELINE_SINK_SUCCESS";
export const FETCH_PIPELINE_SINK_FAILURE = "FETCH_PIPELINE_SINK_FAILURE";

export const SET_NODES = "SET_NODES";
export const SET_NODES_JOIN = "SET_NODES_JOIN";
export const SET_EDGES = "SET_EDGES";
export const ON_NODES_CHANGE = "ON_NODES_CHANGE";
export const ON_EDGES_CHANGE = "ON_EDGES_CHANGE";
export const ON_NODES_CHANGE_JOIN = "ON_NODES_CHANGE_JOIN";
export const ON_EDGES_CHANGE_JOIN = "ON_EDGES_CHANGE_JOIN";
export const ON_CONNECT = "ON_CONNECT";
export const ON_CONNECT_JOIN = "ON_CONNECT_JOIN";
export const ADD_NODE = "ADD_NODE";
export const ON_EDGES_DELETE = "ON_EDGES_DELETE";
export const ON_EDGES_DELETE_JOIN = "ON_EDGES_DELETE_JOIN";
export const ADD_NODE_JOIN = "ADD_NODE_JOIN";

export const UPDATE_NODES_BASED_ON_EDGES = "UPDATE_NODES_BASED_ON_EDGES";

export const PRESERVE_NODE_STATE = "PRESERVE_NODE_STATE";
export const REMOVE_NODE_STATE = "REMOVE_NODE_STATE";

export const ON_CHANGE_APPLY_QUERY_TAB_VALUE_PIPELINE =
  "ON_CHANGE_APPLY_QUERY_TAB_VALUE_PIPELINE";

export const FETCH_CONNECTION_PROFILE_BY_ID_START_PIPELINE =
  "FETCH_CONNECTION_PROFILE_BY_ID_START_PIPELINE";
export const FETCH_CONNECTION_PROFILE_BY_ID_SUCCESS_PIPELINE =
  "FETCH_CONNECTION_PROFILE_BY_ID_SUCCESS_PIPELINE";
export const FETCH_CONNECTION_PROFILE_BY_ID_FAILURE_PIPELINE =
  "FETCH_CONNECTION_PROFILE_BY_ID_FAILURE_PIPELINE";

export const FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START_PIPELINE =
  "FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START_PIPELINE";

export const FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS_PIPELINE =
  "FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS_PIPELINE";

export const FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE_PIPELINE =
  "FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE_PIPELINE";

export const FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START_PIPELINE =
  "FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START_PIPELINE";
export const FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS_PIPELINE =
  "FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS_PIPELINE";
export const FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE_PIPELINE =
  "FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE_PIPELINE";

export const FETCH_TABLE_VIEWS_FROM_SCHEMA_START_PIPELINE =
  "FETCH_TABLE_VIEWS_FROM_SCHEMA_START_PIPELINE";
export const FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS_PIPELINE =
  "FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS_PIPELINE";
export const FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE_PIPELINE =
  "FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE_PIPELINE";

export const FETCH_COLUMN_FROM_TABLE_VIEW_START_PIPELINE =
  "FETCH_COLUMN_FROM_TABLE_VIEW_START_PIPELINE";
export const FETCH_COLUMN_FROM_TABLE_VIEW_SUCCESS_PIPELINE =
  "FETCH_COLUMN_FROM_TABLE_VIEW_SUCCESS_PIPELINE";
export const FETCH_COLUMN_FROM_TABLE_VIEW_FAILURE_PIPELINE =
  "FETCH_COLUMN_FROM_TABLE_VIEW_FAILURE_PIPELINE";

export const FETCH_COLUMN_FROM_TABLE_START_PIPELINE =
  "FETCH_COLUMN_FROM_TABLE_START_PIPELINE";
export const FETCH_COLUMN_FROM_TABLE_SUCCESS_PIPELINE =
  "FETCH_COLUMN_FROM_TABLE_SUCCESS_PIPELINE";
export const FETCH_COLUMN_FROM_TABLE_FAILURE_PIPELINE =
  "FETCH_COLUMN_FROM_TABLE_FAILURE_PIPELINE";

export const RUN_PIPELINE_START = "RUN_PIPELINE_START";
export const RUN_PIPELINE_SUCCESS = "RUN_PIPELINE_SUCCESS";
export const RUN_PIPELINE_FAILURE = "RUN_PIPELINE_FAILURE";

export const CREATE_PIPELINE_START = "CREATE_PIPELINE_START";
export const CREATE_PIPELINE_SUCCESS = "CREATE_PIPELINE_SUCCESS";
export const CREATE_PIPELINE_FAILURE = "CREATE_PIPELINE_FAILURE";

export const UPDATE_PIPELINE_START = "UPDATE_PIPELINE_START";
export const UPDATE_PIPELINE_SUCCESS = "UPDATE_PIPELINE_SUCCESS";
export const UPDATE_PIPELINE_FAILURE = "UPDATE_PIPELINE_FAILURE";

export const ON_CHANGE_APPLY_PIPELINE_RESPONSE =
  "ON_CHANGE_APPLY_PIPELINE_RESPONSE";

export const UPDATE_SOCKET_RESPONSE = "UPDATE_SOCKET_RESPONSE";
export const CLEAN_SOCKET_RESPONSE = "CLEAN_SOCKET_RESPONSE";

export const FETCH_RECENT_PIPELINE_START = "FETCH_RECENT_PIPELINE_START";
export const FETCH_RECENT_PIPELINE_SUCCESS = "FETCH_RECENT_PIPELINE_SUCCESS";
export const FETCH_RECENT_PIPELINE_FAILURE = "FETCH_RECENT_PIPELINE_FAILURE";

export const FETCH_SELECTED_PIPELINE_START = "FETCH_SELECTED_PIPELINE_START";
export const FETCH_SELECTED_PIPELINE_SUCCESS =
  "FETCH_SELECTED_PIPELINE_SUCCESS";
export const FETCH_SELECTED_PIPELINE_FAILURE =
  "FETCH_SELECTED_PIPELINE_FAILURE";

export const CANCEL_PIPELINE_RUN_START = "CANCEL_PIPELINE_RUN_START";
export const CANCEL_PIPELINE_RUN_SUCCESS = "CANCEL_PIPELINE_RUN_SUCCESS";
export const CANCEL_PIPELINE_RUN_FAILURE = "CANCEL_PIPELINE_RUN_FAILURE";

export const FETCH_PIPELINE_EXECUTION_BY_DATE_START =
  "FETCH_PIPELINE_EXECUTION_BY_DATE_START";
export const FETCH_PIPELINE_EXECUTION_BY_DATE_SUCCESS =
  "FETCH_PIPELINE_EXECUTION_BY_DATE_SUCCESS";
export const FETCH_PIPELINE_EXECUTION_BY_DATE_FAILURE =
  "FETCH_PIPELINE_EXECUTION_BY_DATE_FAILURE";

export const FETCH_PIPELINE_EXECUTION_BY_SESSION_START =
  "FETCH_PIPELINE_EXECUTION_BY_SESSION_START";
export const FETCH_PIPELINE_EXECUTION_BY_SESSION_SUCCESS =
  "FETCH_PIPELINE_EXECUTION_BY_SESSION_SUCCESS";
export const FETCH_PIPELINE_EXECUTION_BY_SESSION_FAILURE =
  "FETCH_PIPELINE_EXECUTION_BY_SESSION_FAILURE";

export const FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_START =
  "FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_START";
export const FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_SUCCESS =
  "FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_SUCCESS";
export const FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_FAILURE =
  "FETCH_PIPELINE_EXECUTION_LOG_BY_SESSION_FAILURE";

export const FETCH_ALL_PIPELINE_START = "FETCH_ALL_PIPELINE_START";
export const FETCH_ALL_PIPELINE_SUCCESS = "FETCH_ALL_PIPELINE_SUCCESS";
export const FETCH_ALL_PIPELINE_FAILURE = "FETCH_ALL_PIPELINE_FAILURE";

export const GET_PIPELINE_RESULTS_START = "GET_PIPELINE_RESULTS_START";
export const GET_PIPELINE_RESULTS_SUCCESS = "GET_PIPELINE_RESULTS_SUCCESS";
export const GET_PIPELINE_RESULTS_FAILURE = "GET_PIPELINE_RESULTS_FAILURE";

export const SET_PIPEPINE_RESULTS_VALUE = "SET_PIPEPINE_RESULTS_VALUE";

export const RENAME_PIPELINE_NAME_START = "RENAME_PIPELINE_NAME_START";
export const RENAME_PIPELINE_NAME_SUCCESS = "RENAME_PIPELINE_NAME_SUCCESS";
export const RENAME_PIPELINE_NAME_FAILURE = "RENAME_PIPELINE_NAME_FAILURE";

export const DELETE_PIPELINE_START = "DELETE_PIPELINE_START";
export const DELETE_PIPELINE_SUCCESS = "DELETE_PIPELINE_SUCCESS";
export const DELETE_PIPELINE_FAILURE = "DELETE_PIPELINE_FAILURE";

export const FETCH_PIPELINE_ADDITIONAL_START =
  "FETCH_PIPELINE_ADDITIONAL_START";
export const FETCH_PIPELINE_ADDITIONAL_SUCCESS =
  "FETCH_PIPELINE_ADDITIONAL_SUCCESS";
export const FETCH_PIPELINE_ADDITIONAL_FAILURE =
  "FETCH_PIPELINE_ADDITIONAL_FAILURE";

export const FETCH_PIPELINE_DATA_RETENTION_START =
  "FETCH_PIPELINE_DATA_RETENTION_START";
export const FETCH_PIPELINE_DATA_RETENTION_SUCCESS =
  "FETCH_PIPELINE_DATA_RETENTION_SUCCESS";
export const FETCH_PIPELINE_DATA_RETENTION_FAILURE =
  "FETCH_PIPELINE_DATA_RETENTION_FAILURE";

export const UPDATE_PIPELINE_DATA_RETENTION_START =
  "UPDATE_PIPELINE_DATA_RETENTION_START";
export const UPDATE_PIPELINE_DATA_RETENTION_SUCCESS =
  "UPDATE_PIPELINE_DATA_RETENTION_SUCCESS";
export const UPDATE_PIPELINE_DATA_RETENTION_FAILURE =
  "UPDATE_PIPELINE_DATA_RETENTION_FAILURE";

export const UPDATE_DATA_RETENTION_PARAMS = "UPDATE_DATA_RETENTION_PARAMS";

export const CANCEL_DATA_RETENTION_UPDATE = "CANCEL_DATA_RETENTION_UPDATE";

export const PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START =
  "PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_START";

export const PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS =
  "PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_SUCCESS";

export const PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE =
  "PIP_FETCH_RESULTS_FROM_DATABASE_OR_SCHEMA_FAILURE";

export const PIP_FETCH_TABLE_VIEWS_FROM_SCHEMA_START =
  "PIP_FETCH_TABLE_VIEWS_FROM_SCHEMA_START";

export const PIP_FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS =
  "PIP_FETCH_TABLE_VIEWS_FROM_SCHEMA_SUCCESS";

export const PIP_FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE =
  "PIP_FETCH_TABLE_VIEWS_FROM_SCHEMA_FAILURE";

export const UPDATE_DATA_RETENTION_PARAMS_DATA =
  "UPDATE_DATA_RETENTION_PARAMS_DATA";

export const PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_START =
  "PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_START";

export const PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_SUCCESS =
  "PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_SUCCESS";

export const PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_FAILURE =
  "PIPELINE_FETCH_ALL_QUERY_CONNECTION_LIST_FAILURE";

export const FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START_PIP =
  "FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_START_PIP";

export const FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS_PIP =
  "FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_SUCCESS_PIP";

export const FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE_PIP =
  "FETCH_DATABASE_OR_SCHEMA_FROM_CONNECTION_PROFILE_FAILURE_PIP";

export const UPDATE_DATA_RETENTION_DATA_START =
  "UPDATE_DATA_RETENTION_DATA_START";
export const UPDATE_DATA_RETENTION_DATA_SUCCESS =
  "UPDATE_DATA_RETENTION_DATA_SUCCESS";
export const UPDATE_DATA_RETENTION_DATA_FAILURE =
  "UPDATE_DATA_RETENTION_DATA_FAILURE";

export const FETCH_PROFILING_RESULT_START = "FETCH_PROFILING_RESULT_START";
export const FETCH_PROFILING_RESULT_SUCCESS = "FETCH_PROFILING_RESULT_SUCCESS";
export const FETCH_PROFILING_RESULT_FAILURE = "FETCH_PROFILING_RESULT_FAILURE";

export const FETCH_PROFILING_LINE_CHART_START =
  "FETCH_PROFILING_LINE_CHART_START";
export const FETCH_PROFILING_LINE_CHART_SUCCESS =
  "FETCH_PROFILING_LINE_CHART_SUCCESS";
export const FETCH_PROFILING_LINE_CHART_FAILURE =
  "FETCH_PROFILING_LINE_CHART_FAILURE";

export const GET_PIPELINE_SESSION_ID_START = "GET_PIPELINE_SESSION_ID_START";
export const GET_PIPELINE_SESSION_ID_SUCCESS =
  "GET_PIPELINE_SESSION_ID_SUCCESS";
export const GET_PIPELINE_SESSION_ID_FAILURE =
  "GET_PIPELINE_SESSION_ID_FAILURE";

export const GET_SHARED_LIST_START = "GET_SHARED_LIST_START";
export const GET_SHARED_LIST_SUCCESS = "GET_SHARED_LIST_SUCCESS";
export const GET_SHARED_LIST_FAILURE = "GET_SHARED_LIST_FAILURE";

export const SHARE_PIPELINE_START = "SHARE_PIPELINE_START";
export const SHARE_PIPELINE_SUCCESS = "SHARE_PIPELINE_SUCCESS";
export const SHARE_PIPELINE_FAILURE = "SHARE_PIPELINE_FAILURE";

export const UPDATE_SHARE_LIST_START = "UPDATE_SHARE_LIST_START";
export const UPDATE_SHARE_LIST_SUCCESS = "UPDATE_SHARE_LIST_SUCCESS";
export const UPDATE_SHARE_LIST_FAILURE = "UPDATE_SHARE_LIST_FAILURE";

export const REMOVE_ACCESS_START = "REMOVE_ACCESS_START";
export const REMOVE_ACCESS_SUCCESS = "REMOVE_ACCESS_SUCCESS";
export const REMOVE_ACCESS_FAILURE = "REMOVE_ACCESS_FAILURE";
