import moment from "moment";
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const generateUniqueID = () => {
  const timestamp = new Date().getTime().toString();

  return timestamp;
};
export const extractNumbersAfterDash = (input) => {
  // Find the last occurrence of '-'
  const lastDashIndex = input.lastIndexOf("-");

  // Extract the substring after the last '-'
  const substringAfterDash = input.substring(lastDashIndex + 1);

  // Regular expression to check if the substring contains only integers
  const integerRegex = /^\d+$/;

  // Check if the substring contains only integers
  if (integerRegex.test(substringAfterDash)) {
    return substringAfterDash;
  } else {
    return null; // Or handle the case where the substring is not purely integers
  }
};
export const filesizes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export function formatDateAndTime(inputDateStr) {
  // Ensure the input is a valid ISO date string
  if (!inputDateStr || typeof inputDateStr !== "string") {
    console.error("Invalid input date string:", inputDateStr);
    return "Invalid date";
  }

  try {
    // Parse the ISO date string
    const date = new Date(inputDateStr);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", inputDateStr);
      return "Invalid date";
    }

    // Get the local timezone of the user's machine
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the date to the local timezone
    const localDate = toZonedTime(date, localTimeZone);

    // Format the date according to the specified format
    const formattedDate = format(localDate, "yyyy/MM/dd hh:mm a");

    // Return the formatted date
    return formattedDate;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Error formatting date";
  }
}

export function formatTime(inputDateStr) {
  // Ensure the input is a valid ISO date string
  if (!inputDateStr || typeof inputDateStr !== "string") {
    console.error("Invalid input date string:", inputDateStr);
    return "Invalid time";
  }

  try {
    // Parse the input date string as an ISO date
    const date = parseISO(inputDateStr);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", inputDateStr);
      return "Invalid time";
    }

    // Get the local timezone of the user's machine
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the UTC date to the local timezone
    const localDate = toZonedTime(date, localTimeZone);

    // Format the time according to the specified format
    const formattedTime = format(localDate, "hh:mm a");

    // Return the formatted time
    return formattedTime;
  } catch (error) {
    console.error("Error formatting time:", error);
    return "Error formatting time";
  }
}
export function generateRandom() {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

//download csv file with only the selected columns
export const csvFilteredData = (filteredColumn, selectedRowId, data) => {
  const csvData = data
    ?.filter(
      (item) => selectedRowId?.length === 0 || selectedRowId?.includes(item.id)
    )
    .map((item) => {
      const filteredItem = {};
      filteredColumn?.forEach((column) => {
        if (column?.field in item) {
          if (column.field === "connection_profile") {
            filteredItem[column.field] = item[column.field]?.profile_name || "";
          } else if (column.field === "folder_path") {
            filteredItem[column.field] =
              item[column.field]?.map((folder) => folder.name).join("/") || "";
          } else {
            filteredItem[column.field] = item[column.field] || "";
          }
        }
      });
      return filteredItem;
    });

  return csvData;
};

//time formatter
export const getFormattedTime = (inputTime) => {
  //date string comes in one of these 4 fortats so handling all of them.. Z means explicity descirbes as UTC time
  const inputFormats = [
    "YYYY-MM-DDTHH:mm:ss.SSSSSSZ", // With 'Z'
    "YYYY-MM-DDTHH:mm:ss.SSSSSS", // Without 'Z'
    "YYYY-MM-DDTHH:mm:ssZ", // With 'Z' and without milliseconds
    "YYYY-MM-DDTHH:mm:ss", // Without 'Z' and without milliseconds
  ];
  if (!moment.utc(inputTime, inputFormats, true).isValid()) {
    return "Invalid date format";
  }
  return [
    moment.utc(inputTime, inputFormats, true).local().fromNow(),
    moment
      .utc(inputTime, inputFormats, true)
      .local()
      .format("MMM D, YYYY h:mm A") + ` (UTC${moment().format("Z")})`,
    moment.utc(inputTime, inputFormats, true).format("MMM D, YYYY h:mm A") +
      " (UTC)",
  ];
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Formats a specified field from an array of objects into a comma-separated string.
 *
 * The array should contain objects with the specified field.
 *
 * @param {Array} array - The array of objects to format.
 * @param {String} fieldName - The field name to extract and format.
 * @returns {String} - The formatted string of the specified field values.
 */
export function formatObjectFieldToString(array, fieldName) {
  const fieldValues = array.map((item) => item[fieldName]);

  // Handle different lengths of the field values array
  if (fieldValues.length === 0) {
    // If there are no values, return an empty string
    return "";
  } else if (fieldValues.length === 1) {
    // If there is only one value, return that value
    return fieldValues[0];
  } else {
    // If there are multiple values, format them as "first, middle, ..., last"
    const first = fieldValues[0]; // First value
    const last = fieldValues[fieldValues.length - 1]; // Last value
    const middle = fieldValues.slice(1, -1).join(", "); // Middle values joined by commas

    // Construct the final formatted string
    return `${first}${middle ? ", " + middle : ""}, ${last}`;
  }
}

//parse breadcrum string and change it to array of objects {id:x, name:y} to populate breadcrumb array of redux
//on page reload
//note objects separeted by & and id and name property of indivial obj selected by __
export const parseQueryParams = (query) => {
  const params = new URLSearchParams(query);
  const breadcrumb = [];
  //key: id1, id2, etc, value: 1__name=folder1, 2__name=foldersf
  params.forEach((value, key) => {
    if (key.startsWith("id")) {
      //here after split idStr will be 1 and name will be folder1 as split is __name=
      //note : __name and idStr
      const [idStr, fName] = value.split("__name=");
      const id = parseInt(idStr, 10);
      if (id && fName) {
        breadcrumb.push({ id, name: decodeURIComponent(fName) });
      }
    }
  });

  return breadcrumb;
};

export function bytesToSize(bytes) {
  if (bytes === undefined) return "";
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

//show seleced path like: root/folder1/folder2/ using breadcrumb array
export const createPathToDisplay = (pathObjArray) => {
  return pathObjArray?.reduce((path, item, index) => {
    if (index === 0) {
      return `/${item.name}/`;
    } else {
      return `${path}${item.name}/`;
    }
  }, "");
};

export const combinedUsersAndGroups = (users, groups) => {
  const combinedArray = [];

  if (users?.data?.data) {
    users.data.data.forEach((user) => {
      combinedArray.push({
        name: `${user.first_name} ${user.last_name}`,
        type: "user",
        id: user.id,
      });
    });
  }

  if (groups?.data?.data) {
    groups.data.data.forEach((group) => {
      combinedArray.push({
        name: group.name,
        type: "group",
        id: group.id,
      });
    });
  }

  return combinedArray;
};

export const combinedUsersAndGroupsPermission = (users, groups) => {
  const combinedArray = [];
  console.log(users, groups, "ssnaz");
  if (users) {
    users.forEach((user) => {
      combinedArray.push({
        name: user.user.username,
        type: "user",
        email: user.user.email,
        permission: user.permissions,
        id: user.user.id,
      });
    });
  }

  if (groups) {
    groups.forEach((group) => {
      combinedArray.push({
        name: group.group.name,
        type: "group",
        permission: group.permissions,
        id: group.group.id,
      });
    });
  }
  return combinedArray;
};

export function extractColumns(firstItem) {
  const ignoreFields = new Set(["_id"]);
  const keys = Object.keys(firstItem).filter((key) => !ignoreFields.has(key));
  const col = keys.map((field, index) => ({
    id: index + 1,
    field: field,
  }));
  console.log(col, "new col data");
  return col;
}

export function getWeekdayOccurrences(weekdayName, month, occurrence) {
  const dayMap = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
  };
  const year = new Date().getFullYear();
  const targetDay = dayMap[weekdayName];
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0); // Last day of the month

  let weekdayOccurrences = [];

  // Loop through all the days in the month
  for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
    const currentDate = new Date(year, month, day);
    if (currentDate.getDay() === targetDay) {
      weekdayOccurrences.push(day); // Collect all the days that match the target weekday
    }
  }

  // Based on occurrence: first, second, third, fourth, last
  switch (occurrence) {
    case "first":
      return weekdayOccurrences[0]; // First occurrence of the weekday
    case "second":
      return weekdayOccurrences[1]; // Second occurrence of the weekday
    case "third":
      return weekdayOccurrences[2]; // Third occurrence of the weekday
    case "fourth":
      return weekdayOccurrences[3]; // Fourth occurrence of the weekday
    case "last":
      return weekdayOccurrences[weekdayOccurrences.length - 1]; // Last occurrence of the weekday
    default:
      return null; // Invalid occurrence
  }
}
