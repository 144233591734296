import * as AuditLogType from "./auditLog.types";

import axiosConfig from "../../config/axiosConfig";

import { openAlert } from "../alert/alert.actions";
import _ from "lodash";

export const updateDateFormatToggler = (column) => ({
  type: AuditLogType.UPDATE_DATE_TOGGLER,
  payload: { column },
});

export const updatedSelectedAuditLogId = (id, date) => ({
  type: AuditLogType.SELECTED_AUDIT_LOG_ID,
  payload: { id, date },
});

export const updatedSelectedUserId = (userEmail) => ({
  type: AuditLogType.SELECTED_AUDIT_LOG_USER_ID,
  payload: { userEmail },
});

export const resetAuditLog = () => ({
  type: AuditLogType.RESET_AUDIT_LOG,
});

// export const updatedSelectedLogContentId = (logId) => ({
//   type: AuditLogType.SELECTED_LOG_CONTENT_ID,
//   payload: { logId },
// });

export const fetchAuditLogs = (cb) => async (dispatch, getState) => {
  const {
    auditLog: {
      auditLogs: { searchParams, limit, offset },
      auditLogsUsers: { selectedId },
    },
  } = getState();
  try {
    dispatch({ type: AuditLogType.GET_AUDIT_LOG_START });

    const queryParams = new URLSearchParams();

    if (selectedId) {
      queryParams.append("modified_by", selectedId);
    }

    if (searchParams) {
      queryParams.append("search", searchParams);
    }
    if (limit) {
      queryParams.append("limit", limit);
    }
    if (offset) {
      queryParams.append("offset", offset);
    }

    let url = `/logs/logdays/`;
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    let method = "get";
    const { data } = await axiosConfig.request({
      method,
      url,
    });
    dispatch({
      type: AuditLogType.GET_AUDIT_LOG_SUCCESS,
      payload: data,
    });

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: AuditLogType.GET_AUDIT_LOG_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Failed to fetch"
        }`,
        "error"
      )
    );
  }
};

export const fetchAuditLogsUsers = (cb) => async (dispatch, getState) => {
  try {
    dispatch({ type: AuditLogType.GET_AUDIT_LOG_USERS_START });

    let url = `/folderstructure/listusers/`;

    let method = "get";
    const { data } = await axiosConfig.request({
      method,
      url,
    });
    dispatch({
      type: AuditLogType.GET_AUDIT_LOG_USERS_SUCCESS,
      payload: data,
    });

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: AuditLogType.GET_AUDIT_LOG_USERS_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Failed to fetch"
        }`,
        "error"
      )
    );
  }
};

export const onChangeApplyLogContentFilter = (keys, values) => ({
  type: AuditLogType.ON_CHANGE_LOG_CONTENT_FILTER,
  payload: { keys, values },
});

export const onChangeApplyAuditLogFilter = (key, value) => ({
  type: AuditLogType.ON_CHANGE_AUDIT_LOG_FILTER,
  payload: { key, value },
});

export const fetchAuditLogsContent =
  (email, cb) => async (dispatch, getState) => {
    const {
      auditLog: {
        logContent: { searchParams, limit, offset },
        auditLogs: { selectedDate },
      },
    } = getState();
    try {
      dispatch({ type: AuditLogType.GET_AUDIT_LOG_CONTENT_START });

      const queryParams = new URLSearchParams();
      queryParams.append("modified_by", email);

      if (searchParams) {
        queryParams.append("search", searchParams);
      }

      if (selectedDate) {
        queryParams.append("from_date", selectedDate);
        queryParams.append("to_date", selectedDate);
      }

      if (limit) {
        queryParams.append("limit", limit);
      }
      if (offset) {
        queryParams.append("offset", offset);
      }

      const url = `/logs/?${queryParams.toString()}`;

      let method = "get";
      const { data } = await axiosConfig.request({
        method,
        url,
      });
      dispatch({
        type: AuditLogType.GET_AUDIT_LOG_CONTENT_SUCCESS,
        payload: data,
      });

      if (cb) cb();
    } catch (error) {
      dispatch({
        type: AuditLogType.GET_AUDIT_LOG_CONTENT_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Failed to fetch"
          }`,
          "error"
        )
      );
    }
  };

export const fetchAuditLogsContentDetails =
  (id, cb) => async (dispatch, getState) => {
    const {
      fileExplorer: {
        fileExplorerItems: { searchParams },
      },
    } = getState();
    try {
      dispatch({ type: AuditLogType.GET_AUDIT_LOG_DETAILS_START });

      let url = `/logs/${id}/`;
      const queryParams = [];
      if (searchParams) {
        queryParams.push(`search=${searchParams}`);
      }
      if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
      }
      let method = "get";
      const { data } = await axiosConfig.request({
        method,
        url,
      });
      dispatch({
        type: AuditLogType.GET_AUDIT_LOG_DETAILS_SUCCESS,
        payload: data,
      });

      // dispatch(openAlert(`${"Fetched successfully"}`, "success"));

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({
        type: AuditLogType.GET_AUDIT_LOG_DETAILS_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Failed to fetch details"
          }`,
          "error"
        )
      );
    }
  };
