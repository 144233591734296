import React from "react";
import { AppBar, Toolbar, IconButton, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";

const TopbarWrapper = styled(AppBar)(({ theme }) => ({
  height: "56px",
  background: "linear-gradient(90deg, #1963AA 0%, #134C84 100%)",
}));

const Logo = styled("img")({
  height: "34px",
  width: "34px",
});

export default function Topbar({ handleDrawerOpen }) {
  return (
    <TopbarWrapper position="fixed">
      <Toolbar
        sx={{
          padding: "0px 15px !important",
          minHeight: "56px !important",
          backgroundColor: "#1A2055",
        }}
      >
        <Box sx={{ flexGrow: 1, maxHeight: "34px" }}>
          <Logo src="/assets/images/icons/mainLogo.svg" alt="Logo" />
        </Box>
        <IconButton
          onClick={handleDrawerOpen}
          sx={{ height: "34px", width: "34px", padding: "0px", color: "#fff" }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </TopbarWrapper>
  );
}
