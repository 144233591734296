// project imports
import * as ConnectionType from "./connection.types";

import axiosConfig from "../../config/axiosConfig";

import { openAlert } from "../alert/alert.actions";
import _ from "lodash";

export const fetchAllConnectionProfiles = () => async (dispatch, getState) => {
  const {
    connection: {
      connectionProfile: {
        searchParams,
        connection_check5,
        status,
        limit,
        offset,
      },
    },
  } = getState();

  try {
    dispatch({
      type: ConnectionType.FETCH_ALL_CONNECTION_PROFILE_START,
    });

    let url = "/connections/connection/";
    const queryParams = [];

    if (connection_check5) {
      queryParams.push(`connection_check5=${connection_check5}`);
    }

    if (status) {
      queryParams.push(`status=${status === "enable" ? true : false}`);
    }

    if (limit) {
      queryParams.push(`limit=${limit}`);
    }
    if (offset) {
      queryParams.push(`offset=${offset}`);
    }

    if (!_.isEmpty(searchParams)) {
      const parsedSearchParams = JSON.parse(searchParams);
      if (!_.isEmpty(parsedSearchParams)) {
        queryParams.push(`search=${parsedSearchParams.toString()}`);
      }
    }

    if (queryParams.length > 0) {
      url += "?" + queryParams.join("&");
    }

    const { data } = await axiosConfig.get(url);

    dispatch({
      type: ConnectionType.FETCH_ALL_CONNECTION_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ConnectionType.FETCH_ALL_CONNECTION_PROFILE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const resetConnectionUses = () => ({
  type: ConnectionType.RESET_CONNECTION_USES,
});

export const fetchConnectionUses = (id) => async (dispatch, getState) => {
  const {
    connection: {
      connectionUses: { searchParams, status, limit, offset },
    },
  } = getState();
  try {
    dispatch({ type: ConnectionType.FETCH_CONNECTION_USES_START });

    let url = `/connections/connection/${id}/connectionprofile-query-relation/`;
    const queryParams = [];

    if (status) {
      queryParams.push(`status=${status === "enable" ? true : false}`);
    }

    if (limit) {
      queryParams.push(`limit=${limit}`);
    }

    if (offset) {
      queryParams.push(`offset=${offset}`);
    }

    if (searchParams) {
      queryParams.push(`query=${searchParams}`);
    }

    if (queryParams.length > 0) {
      url += "?" + queryParams.join("&");
    }

    const { data } = await axiosConfig.get(url);

    dispatch({
      type: ConnectionType.FETCH_CONNECTION_USES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ConnectionType.FETCH_CONNECTION_USES_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });

    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Failed to load connection uses"
        }`,
        "error"
      )
    );
  }
};

export const fetchConnectionProfileById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ConnectionType.FETCH_CONNECTION_PROFILE_BY_ID_START,
    });

    const { data } = await axiosConfig.get(`/connections/connection/${id}/`);

    dispatch({
      type: ConnectionType.FETCH_CONNECTION_PROFILE_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ConnectionType.FETCH_CONNECTION_PROFILE_BY_ID_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const updateDateFormatToggler = (columnName) => ({
  type: ConnectionType.UPDATE_DATE_FORMAT_TOGGLER,
  payload: { columnName },
});

export const updateDateFormatTogglerConnection = (column) => ({
  type: ConnectionType.UPDATE_DATE_FORMAT_TOGGLER_CONNECTION,
  payload: { column },
});

export const onChangeApplyConnectionProfileFilter = (key, value) => ({
  type: ConnectionType.ON_CHANGE_APPLY_CONNECTION_PROFILE_FILTER,
  payload: { key, value },
});

export const onChangeApplyConnectionUsesFilter = (keys, values) => ({
  type: ConnectionType.ON_CHANGE_CONNECTION_USES_FILTER,
  payload: { keys, values },
});

export const createConnectionProfile = (formData, cb) => async (dispatch) => {
  try {
    dispatch({
      type: ConnectionType.CREATE_CONNECTION_PROFILE_START,
    });

    const { data } = await axiosConfig.post(`/connections/connection/`, {
      ...formData,
    });
    dispatch({
      type: ConnectionType.CREATE_CONNECTION_PROFILE_SUCCESS,
      payload: data,
    });

    if (cb) {
      cb();
    }
    dispatch(openAlert("New Connection profile added successfully", "success"));
  } catch (error) {
    dispatch({
      type: ConnectionType.CREATE_CONNECTION_PROFILE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const updateConnectionProfile = (payload, cb) => async (dispatch) => {
  try {
    dispatch({
      type: ConnectionType.UPDATE_CONNECTION_PROFILE_START,
    });

    const { data } = await axiosConfig.patch(
      `/connections/connection/${payload?.id}/`,
      {
        ...payload,
      }
    );
    dispatch({
      type: ConnectionType.UPDATE_CONNECTION_PROFILE_SUCCESS,
      payload: data,
    });
    dispatch(openAlert("Connection Profile updated successfully", "success"));
    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: ConnectionType.UPDATE_CONNECTION_PROFILE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const changeConnectionProfileStatus = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: ConnectionType.CHANGE_CONNECTION_PROFILE_STATUS_START,
    });

    const { data } = await axiosConfig.patch(
      `/connections/connection/${payload?.id}/change-status/`,
      {
        status: payload?.status,
      }
    );
    dispatch({
      type: ConnectionType.CHANGE_CONNECTION_PROFILE_STATUS_SUCCESS,
      payload: { id: payload?.id, data },
    });
    dispatch(
      openAlert("Connection profile status changed successfully", "success")
    );
  } catch (error) {
    dispatch({
      type: ConnectionType.CHANGE_CONNECTION_PROFILE_STATUS_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(openAlert("Failed to change status", "error"));
  }
};

export const testConnectionProfile = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ConnectionType.TEST_CONNECTION_PROFILE_START,
    });

    const { data } = await axiosConfig.post(`/connections/connection/test/`, {
      ...formData,
    });
    dispatch({
      type: ConnectionType.TEST_CONNECTION_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ConnectionType.TEST_CONNECTION_PROFILE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const testConnectionAfterConnectionProfile =
  (formData, cb) => async (dispatch) => {
    try {
      dispatch({
        type: ConnectionType.TEST_CONNECTION_AFTER_CONNECTION_PROFILE_START,
        payload: formData,
      });

      const { data } = await axiosConfig.post(
        `/connections/connection/test_after_connection/`,
        {
          ids: formData,
        }
      );
      dispatch({
        type: ConnectionType.TEST_CONNECTION_AFTER_CONNECTION_PROFILE_SUCCESS,
        payload: data,
      });
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({
        type: ConnectionType.TEST_CONNECTION_AFTER_CONNECTION_PROFILE_FAILURE,
        payload: {
          error:
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
          formData,
        },
      });
      dispatch(openAlert("Something went wrong", "error"));
    }
  };

export const bulkDeleteConnectionProfile =
  (formData, cb) => async (dispatch) => {
    try {
      dispatch({
        type: ConnectionType.BULK_DELETE_CONNECTION_PROFILE_START,
      });

      const { data } = await axiosConfig.post(
        `/connections/connection/bulk-delete/`,
        {
          ids: formData,
        }
      );
      dispatch({
        type: ConnectionType.BULK_DELETE_CONNECTION_PROFILE_SUCCESS,
        payload: data,
      });
      dispatch(openAlert("Connection profile deleted successfully", "success"));
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({
        type: ConnectionType.BULK_DELETE_CONNECTION_PROFILE_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(openAlert("Failed to delete connection profile", "error"));
    }
  };
