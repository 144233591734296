export const navItems = [
  {
    id: 1,
    name: "Global View",
    icon: "/assets/images/icons/homeIcon.svg",
    pathname: "/manage/global-view",
    isDisabled: false,
  },
  // {
  //   id: 2,
  //   name: "Seach Anywhere",
  //   icon: "/assets/images/icons/searchIcon.svg",
  //   pathname: "/manage/search",
  //   isDisabled: false,
  // },
  // {
  //   id: 3,
  //   name: "Add New",
  //   icon: "/assets/images/icons/plusIcon.svg",
  //   pathname: "/manage/add-new",
  //   isDisabled: false,
  // },
  // {
  //   id: 4,
  //   name: "Recent",
  //   icon: "/assets/images/icons/recentIcon.svg",
  //   pathname: "/manage/recent",
  //   isDisabled: false,
  // },
  // {
  //   id: 5,
  //   name: "Designer",
  //   icon: "/assets/images/icons/designerIcon.svg",
  //   pathname: "/manage/designer",
  //   isDisabled: false,
  // },
  {
    id: 6,
    name: "Driver",
    icon: "/assets/images/icons/driverIcon.svg",
    pathname: "/manage/driver",
    isDisabled: false,
  },
  {
    id: 7,
    name: "Connection",
    icon: "/assets/images/icons/connectionIcon.svg",
    pathname: "/manage/connection",
    isDisabled: false,
  },
  {
    id: 8,
    name: "Query",
    icon: "/assets/images/icons/queryIcons.svg",
    pathname: "/manage/query",
    isDisabled: false,
  },
  // {
  //   id: 9,
  //   name: "Catalog",
  //   icon: "/assets/images/icons/catalogIcon.svg",
  //   pathname: "/manage/catalog",
  //   isDisabled: false,
  // },
  // {
  //   id: 10,
  //   name: "Jobs",
  //   icon: "/assets/images/icons/jobsIcon.svg",
  //   pathname: "/manage/jobs",
  //   isDisabled: false,
  // },
  {
    id: 11,
    name: "File Explorer",
    icon: "/assets/images/icons/fileExplorer.svg",
    pathname: "/manage/file-explorer",
    isDisabled: false,
  },
  {
    id: 12,
    name: "Pipeline",
    icon: "/assets/images/icons/connectionPipeline.svg",
    pathname: "/manage/pipeline",
    isDisabled: false,
  },
  {
    id: 13,
    name: "Jobs",
    icon: "/assets/images/icons/jobIcon.svg",
    pathname: "/manage/jobs",
    isDisabled: false,
  },
];
