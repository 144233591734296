import React, { Fragment } from "react";

// third party libraries
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import { styled } from "@mui/material/styles";

// styles
const BackBtn = styled(Button)(({ theme }) => ({
  fontSize: "14px",
  color: "#212121",
  textTransform: "none",
  marginBottom: theme.spacing(4),
}));

// ==============================|| VIEW WRAPPER ||============================== //
const Wrapper = ({ handleClick, children, sx }) => {
  return (
    <Fragment>
      <BackBtn startIcon={<ArrowBackRoundedIcon />} onClick={handleClick}>
        Back
      </BackBtn>
      <RootWrapper sx={sx}>{children}</RootWrapper>
    </Fragment>
  );
};

export const RootWrapper = ({ sx, children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: "1px solid #0000001A",
        background: `${theme.palette.background.default}`,
        padding: `${theme.spacing(3)} 0px`,
        borderRadius: "4px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Wrapper;
