import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import AuditLogMain from "./components/AuditLogMain";
import LogContent from "./components/LogContent";

const { RootWrapper } = require("components/Wrapper");

const AuditLog = () => {
  return (
    <Fragment>
      <RootWrapper
        sx={{
          marginX: 5,
          padding: 0,
          border: "none",
          backgroundColor: "transparent",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            marginBottom: 3,
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            Audit Log
          </Typography>
          <img src="/assets/images/icons/sideIcon.svg" alt="sideIcon" />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4.5}>
            <AuditLogMain />
          </Grid>
          <Grid item xs={12} sm={7.5}>
            <LogContent />
          </Grid>
        </Grid>
      </RootWrapper>
    </Fragment>
  );
};

export default AuditLog;
