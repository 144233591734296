// project imports
import * as AuthType from "./auth.types";
import * as AuditLogActions from "../auditLog/auditLog.actions";

import axiosConfig from "../../config/axiosConfig";

import { openAlert } from "../alert/alert.actions";

export const verifyEmail = (email, cb, cb1) => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.EMAIL_VERIFICATION_START,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axiosConfig.post(`/users/login/`, email, config);
    dispatch({
      type: AuthType.EMAIL_VERIFICATION_SUCCESS,
      payload: data,
    });

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: AuthType.EMAIL_VERIFICATION_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );

    if (
      error?.response?.data?.error ===
        "Email is registered but not verified. First verify the email through OTP" &&
      cb1
    ) {
      cb1();
    }
  }
};

export const login = (formData, cb) => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.SIGN_IN_START,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axiosConfig.post(`/users/login/`, formData, config);

    console.log("data", data);
    dispatch({
      type: AuthType.SIGN_IN_SUCCESS,
      payload: data,
    });

    dispatch(openAlert("User logged in succesfully", "success"));
    dispatch(loadUser());

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: AuthType.SIGN_IN_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};

export const register = (formData, cb) => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.SIGN_UP_START,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axiosConfig.post(
      `/users/registration/`,
      formData,
      config
    );
    dispatch({
      type: AuthType.SIGN_UP_SUCCESS,
      payload: data,
    });
    dispatch(openAlert("Registration Successful", "success"));

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: AuthType.SIGN_UP_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.LOAD_USER_START,
    });

    const { data } = await axiosConfig.get(`/users/get_user_id/`);
    dispatch({
      type: AuthType.LOAD_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AuthType.LOAD_USER_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.LOGOUT_START,
    });

    localStorage.clear();
    dispatch({
      type: AuthType.LOGOUT_SUCCESS,
    });

    dispatch(openAlert("Logout Successful", "success"));
    dispatch(AuditLogActions.resetAuditLog());
  } catch (error) {
    dispatch({
      type: AuthType.LOGOUT_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(openAlert("Logout Failed", "error"));
  }
};

export const resetPassword = (email, cb) => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.RESET_PASSWORD_START,
    });

    const { data } = await axiosConfig.post(`/users/password-reset/`, {
      email,
    });
    dispatch({
      type: AuthType.RESET_PASSWORD_SUCCESS,
      payload: data,
    });

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: AuthType.RESET_PASSWORD_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};

export const resetPasswordConfirmation =
  (uidb, token, values, cb) => async (dispatch) => {
    try {
      dispatch({
        type: AuthType.RESET_PASSWORD_CONFIRMATION_START,
      });

      const { data } = await axiosConfig.post(
        `/users/password-reset-confirm/${uidb}/${token}/`,
        {
          password: values.password,
          confirm_password: values.confirmPassword,
        }
      );
      dispatch({
        type: AuthType.RESET_PASSWORD_CONFIRMATION_SUCCESS,
        payload: data,
      });

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({
        type: AuthType.RESET_PASSWORD_CONFIRMATION_FAILURE,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      });
      dispatch(
        openAlert(
          `${
            error.response && error.response.data.error
              ? error.response.data.error
              : error.response && error.response.data.message
              ? error.response.data.message
              : error.message ?? "Something went wrong"
          }`,
          "error"
        )
      );
    }
  };

export const otpVerify = (email, otp, cb) => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.VERIFY_OTP_START,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axiosConfig.post(
      `/users/OTPverify/`,
      {
        email,
        otp,
      },
      config
    );
    dispatch({
      type: AuthType.VERIFY_OTP_SUCCESS,
      payload: data,
    });
    dispatch(openAlert("OTP verified succesfully", "success"));

    if (cb) {
      cb();
    }
  } catch (error) {
    dispatch({
      type: AuthType.VERIFY_OTP_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};

export const resendOtp = (email) => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.RESEND_VERIFY_OTP_START,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axiosConfig.post(
      `/users/OTPresend/`,
      {
        email: email,
      },
      config
    );
    dispatch({
      type: AuthType.RESEND_VERIFY_OTP_SUCCESS,
      payload: data,
    });
    dispatch(openAlert("OTP resent succesfully", "success"));
  } catch (error) {
    dispatch({
      type: AuthType.RESEND_VERIFY_OTP_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
    dispatch(
      openAlert(
        `${
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message ?? "Something went wrong"
        }`,
        "error"
      )
    );
  }
};
