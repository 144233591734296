import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AdditionalDetailsDialog = ({ handleDialogClose, data }) => {
  const theme = useTheme();
  console.log(data, "dataaston");
  //iff response changes..just change orderedKyes and correspoisind DispalyNames accoridngly
  const orderedKeys = [
    "action",
    "description",
    "id",
    "modified_by",
    "modified_date",
    "sub_action",
  ];

  const displayNames = {
    action: "Action",
    description: "Description",
    id: "ID",
    modified_by: "Last Used By",
    modified_date: "Last Used On",
    sub_action: "Sub Action",
  };

  const renderData = () => {
    if (!data || typeof data !== "object") {
      return <Typography>No data available</Typography>;
    }

    return orderedKeys.map((key, index) => (
      <Box key={key}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 4,
            paddingX: 4,
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              minWidth: "150px",
            }}
          >
            {displayNames[key] || key}
          </Typography>
          <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
            {data.data[key]?.toString() || "-"}
          </Typography>
        </Box>
        {(index === 3 || index === orderedKeys.length - 1) && (
          <Divider sx={{ marginTop: 5, marginBottom: 1 }} />
        )}
      </Box>
    ));
  };

  return (
    <Box
      sx={{
        width: {
          xs: "90vw",
          sm: "80vw",
          md: "60vw",
          lg: "40vw",
          xl: "30vw",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          paddingY: "2px",
          paddingX: 2,
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Typography sx={{ color: "white", fontWeight: 500, fontSize: "18px" }}>
          Additional Details
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {data?.loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", padding: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>{renderData()}</Box>
      )}
    </Box>
  );
};

export default AdditionalDetailsDialog;
