const palette = {
  primary: {
    main: "#1A2055",
  },
  secondary: {
    main: "#BC5090",
  },
  background: {
    default: "#fff",
  },

};

export default palette;
