//get all audit log
export const GET_AUDIT_LOG_START = "GET_AUDIT_LOG_START";
export const GET_AUDIT_LOG_SUCCESS = "GET_AUDIT_LOG_SUCCESS";
export const GET_AUDIT_LOG_FAILURE = "GET_AUDIT_LOG_FAILURE";

//get users
export const GET_AUDIT_LOG_USERS_START = "GET_AUDIT_LOG_USERS_START";
export const GET_AUDIT_LOG_USERS_SUCCESS = "GET_AUDIT_LOG_USERS_SUCCESS";
export const GET_AUDIT_LOG_USERS_FAILURE = "GET_AUDIT_LOG_USERS_FAILURE";

export const UPDATE_DATE_TOGGLER = "UPDATE_DATE_TOGGLE";

export const SELECTED_AUDIT_LOG_ID = "SELECTED_AUDIT_LOG_ID";
export const SELECTED_LOG_CONTENT_ID = "SELECTED_LOG_CONTENT_ID";
export const SELECTED_AUDIT_LOG_USER_ID = "SELECTED_AUDIT_LOG_USER_ID";

//get all log content
export const GET_AUDIT_LOG_CONTENT_START = "GET_AUDIT_LOG_CONTENT_START";
export const GET_AUDIT_LOG_CONTENT_SUCCESS = "GET_AUDIT_LOG_CONTENT_SUCCESS";
export const GET_AUDIT_LOG_CONTENT_FAILURE = "GET_AUDIT_LOG_CONTENT_FAILURE";

//get specific log details
export const GET_AUDIT_LOG_DETAILS_START = "GET_AUDIT_LOG_DETAILS_START";
export const GET_AUDIT_LOG_DETAILS_SUCCESS = "GET_AUDIT_LOG_DETAILS_SUCCESS";
export const GET_AUDIT_LOG_DETAILS_FAILURE = "GET_AUDIT_LOG_DETAILS_FAILURE";

//filter for log content and audit log respectively
export const ON_CHANGE_LOG_CONTENT_FILTER = "ON_CHANGE_LOG_CONTENT_FILTER";
export const ON_CHANGE_AUDIT_LOG_FILTER = "ON_CHANGE_AUDIT_LOG_FILTER";


//reset audit log
export const RESET_AUDIT_LOG = "RESET_AUDIT_LOG"