import { lazy } from "react";

// project imports
import MainLayout from "../layouts/Admin";
import Loadable from "../components/Loadable";
import PrivateRoute from "./PrivateRoute";
import { Navigate } from "react-router-dom";
import BlankLayout from "layouts/Blank";
import PipelineList from "views/Admin/PipelineView/PipelineList";

// dashboard routing
const GlobalView = Loadable(lazy(() => import("../views/Admin/GlobalView")));
const FileExplorerView = Loadable(
  lazy(() => import("views/Admin/FileExplorerView"))
);
const DriverManagementView = Loadable(
  lazy(() => import("views/Admin/DriverManagementView"))
);
const DriverInfoView = Loadable(
  lazy(() => import("views/Admin/DriverManagementView/DriverInfo"))
);

const AddOrUpdateDriverView = Loadable(
  lazy(() => import("views/Admin/DriverManagementView/AddOrUpdateDriver"))
);

const ConnectionProfileView = Loadable(
  lazy(() => import("views/Admin/ConnectionProfileView"))
);

const PipelineView = Loadable(lazy(() => import("views/Admin/PipelineView")));

const AddOrUpdateConnectionProfileView = Loadable(
  lazy(() => import("views/Admin/ConnectionProfileView/AddOrUpdateConnection"))
);

const UserManagementView = Loadable(
  lazy(() => import("views/Admin/SettingsView/UserManagement"))
);

const EditProfileView = Loadable(
  lazy(() =>
    import(
      "views/Admin/SettingsView/UserManagement/components/InfoSection/EditProfileView"
    )
  )
);

const AddOrUpdateUserView = Loadable(
  lazy(() =>
    import(
      "views/Admin/SettingsView/UserManagement/components/UserView/AddOrUpdateUser"
    )
  )
);

const AddOrUpdateGroupView = Loadable(
  lazy(() =>
    import(
      "views/Admin/SettingsView/UserManagement/components/GroupManagement/AddOrUpdateGroup"
    )
  )
);

const AddOrUpdatePermissionView = Loadable(
  lazy(() =>
    import(
      "views/Admin/SettingsView/UserManagement/components/PermissionView/AddOrUpdatePermission"
    )
  )
);

const AuditLogView = Loadable(
  lazy(() => import("views/Admin/SettingsView/AuditLog"))
);
const ClusterView = Loadable(
  lazy(() => import("views/Admin/SettingsView/Cluster"))
);
const AddClusterView = Loadable(
  lazy(() => import("views/Admin/SettingsView/Cluster/AddCluster"))
);
const AddAdminDatabricksCluster = Loadable(
  lazy(() =>
    import("views/Admin/SettingsView/Cluster/AddAdminDatabricksCluster")
  )
);
const QueryView = Loadable(lazy(() => import("views/Admin/QueryView")));
const LicenseManagementView = Loadable(
  lazy(() => import("views/Admin/SettingsView/LicenseManagement"))
);
const ExtendLicenseManagementView = Loadable(
  lazy(() =>
    import("views/Admin/SettingsView/LicenseManagement/ExtendServerLicense")
  )
);

const JobsView = Loadable(lazy(() => import("views/Admin/JobsView")));

const JobRuns = Loadable(lazy(() => import("views/Admin/JobsView/Runs")));

const PrivacyPage = Loadable(lazy(() => import("views/Admin/PrivacyView")));

const TermsPage = Loadable(lazy(() => import("views/Admin/TermsView")));

const NotFoundPage = Loadable(lazy(() => import("views/Client/NotFound")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <PrivateRoute />,
  children: [
    {
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Navigate to="/manage/global-view" replace />,
        },
        {
          path: "/manage/global-view",
          element: <GlobalView />,
        },
        {
          path: "/manage/file-explorer",
          element: <FileExplorerView />,
        },
        {
          path: "/manage/driver",
          element: <DriverManagementView />,
        },

        {
          path: "/manage/driver/:id",
          element: <DriverInfoView />,
        },
        {
          path: "/manage/driver/:id/add",
          element: <AddOrUpdateDriverView />,
        },
        {
          path: "/manage/driver/:id/update/:driverId",
          element: <AddOrUpdateDriverView />,
        },
        {
          path: "/manage/connection",
          element: <ConnectionProfileView />,
        },
        {
          path: "/manage/connection/edit-profile/:connectionId",
          element: <AddOrUpdateConnectionProfileView />,
        },
        {
          path: "/manage/connection/add-profile",
          element: <AddOrUpdateConnectionProfileView />,
        },
        {
          path: "/manage/query",
          element: <QueryView />,
        },
        {
          path: "/manage/query/recent-queries",
          element: <QueryView />,
        },
        {
          path: "/manage/query/saved-queries",
          element: <QueryView />,
        },
        {
          path: "/manage/pipeline",
          element: <PipelineList />,
        },

        {
          path: "/manage/jobs",
          element: <JobsView />,
        },
        {
          path: "/manage/job-runs",
          element: <JobsView />,
        },

        {
          path: "/manage/jobs/runs/:job_id",
          element: <JobRuns />,
        },
        // {
        //   path: "/manage/jobs/tasks",
        //   element: <JobRuns />,
        // },
        {
          path: "/manage/pipeline/query-chain/:id?",
          element: <PipelineView />,
        },
        {
          path: "/manage/settings/user-management",
          element: <UserManagementView />,
        },
        {
          path: "/manage/settings/user-management/info-update",
          element: <EditProfileView />,
        },
        {
          path: "/manage/settings/user-management/user-add",
          element: <AddOrUpdateUserView />,
        },
        {
          path: "/manage/settings/user-management/user-edit/:userId",
          element: <AddOrUpdateUserView />,
        },
        {
          path: "/manage/settings/user-management/group-add",
          element: <AddOrUpdateGroupView />,
        },
        {
          path: "/manage/settings/user-management/group-edit/:groupId",
          element: <AddOrUpdateGroupView />,
        },
        {
          path: "/manage/settings/user-management/permission-add",
          element: <AddOrUpdatePermissionView />,
        },
        {
          path: "/manage/settings/user-management/permission-edit/:permissionId",
          element: <AddOrUpdatePermissionView />,
        },
        {
          path: "/manage/settings/license-management",
          element: <LicenseManagementView />,
        },
        {
          path: "/manage/settings/license-management/extend",
          element: <ExtendLicenseManagementView />,
        },
        {
          path: "/manage/settings/audit-log",
          element: <AuditLogView />,
        },
        {
          path: "/manage/settings/cluster",
          element: <ClusterView />,
        },
        {
          path: "/manage/settings/cluster/add-cluster",
          element: <AddClusterView />,
        },
        {
          path: "/manage/settings/cluster/edit-cluster/:engineId",
          element: <AddClusterView />,
        },
        {
          path: "/manage/settings/clusterAdmin",
          element: <AddAdminDatabricksCluster />,
        },

        {
          path: "*",
          element: <NotFoundPage />,
        },
      ],
    },
    {
      element: <BlankLayout />,
      children: [
        {
          path: "/privacy",
          element: <PrivacyPage />,
        },
        {
          path: "/terms",
          element: <TermsPage />,
        },
      ],
    },
  ],
};

export default MainRoutes;
