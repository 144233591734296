export const FETCH_DRIVER_CATEGORIES_START = "FETCH_DRIVER_CATEGORIES_START";
export const FETCH_DRIVER_CATEGORIES_SUCCESS =
  "FETCH_DRIVER_CATEGORIES_SUCCESS";
export const FETCH_DRIVER_CATEGORIES_FAILURE =
  "FETCH_DRIVER_CATEGORIES_FAILURE";

export const FETCH_DRIVER_DATABASES_START = "FETCH_DRIVER_DATABASES_START";
export const FETCH_DRIVER_DATABASES_SUCCESS = "FETCH_DRIVER_DATABASES_SUCCESS";
export const FETCH_DRIVER_DATABASES_FAILURE = "FETCH_DRIVER_DATABASES_FAILURE";

export const ON_CHANGE_APPLY_DATABASE_FILTER =
  "ON_CHANGE_APPLY_DATABASE_FILTER";

export const FETCH_DATABASE_BY_ID_START = "FETCH_DATABASE_BY_ID_START";
export const FETCH_DATABASE_BY_ID_SUCCESS = "FETCH_DATABASE_BY_ID_SUCCESS";
export const FETCH_DATABASE_BY_ID_FAILURE = "FETCH_DATABASE_BY_ID_FAILURE";
export const CLEAR_FETCHED_DATABASE = "CLEAR_FETCHED_DATABASE";

export const DOWNLOAD_DEFAULT_DRIVER_START = "DOWNLOAD_DEFAULT_DRIVER_START";
export const DOWNLOAD_DEFAULT_DRIVER_SUCCESS =
  "DOWNLOAD_DEFAULT_DRIVER_SUCCESS";
export const DOWNLOAD_DEFAULT_DRIVER_FAILURE =
  "DOWNLOAD_DEFAULT_DRIVER_FAILURE";

export const UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_START =
  "UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_START";
export const UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_SUCCESS =
  "UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_SUCCESS";
export const UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_FAILURE =
  "UPLOAD_OR_UPDATE_DRIVER_TO_DATABASE_FAILURE";

export const FETCH_ALL_AVAILABLE_SOURCE_START =
  "FETCH_ALL_AVAILABLE_SOURCE_START";
export const FETCH_ALL_AVAILABLE_SOURCE_SUCCESS =
  "FETCH_ALL_AVAILABLE_SOURCE_SUCCESS";
export const FETCH_ALL_AVAILABLE_SOURCE_FAILURE =
  "FETCH_ALL_AVAILABLE_SOURCE_FAILURE";

export const FETCH_DRIVER_BY_ID_START = "FETCH_DRIVER_BY_ID_START";
export const FETCH_DRIVER_BY_ID_SUCCESS = "FETCH_DRIVER_BY_ID_SUCCESS";
export const FETCH_DRIVER_BY_ID_FAILURE = "FETCH_DRIVER_BY_ID_FAILURE";

export const FETCH_DRIVER_USES_START = "FETCH_DRIVER_USES_START";
export const FETCH_DRIVER_USES_SUCCESS = "FETCH_DRIVER_USES_SUCCESS";
export const FETCH_DRIVER_USES_FAILURE = "FETCH_DRIVER_USES_FAILURE";
export const ON_CHANGE_DRIVER_USES_FILTER = "ON_CHANGE_DRIVER_USES_FILTER";
export const RESET_DRIVER_USES = "RESET_DRIVER_USES";

export const DELETE_DRIVER_TO_DATABASE_FAILURE =
  "DELETE_DRIVER_TO_DATABASE_FAILURE";
export const DELETE_DRIVER_TO_DATABASE_START =
  "DELETE_DRIVER_TO_DATABASE_START";
export const DELETE_DRIVER_TO_DATABASE_SUCCESS =
  "DELETE_DRIVER_TO_DATABASE_SUCCESS";

export const UPDATE_STATUS_DRIVER_TO_DATABASE_SUCCESS =
  "UPDATE_STATUS_DRIVER_TO_DATABASE_SUCCESS";
export const UPDATE_STATUS_DRIVER_TO_DATABASE_START =
  "UPDATE_STATUS_DRIVER_TO_DATABASE_SSTART";
export const UPDATE_STATUS_DRIVER_TO_DATABASE_FAILURE =
  "UPDATE_STATUS_DRIVER_TO_DATABASE_FAILURE";

export const UPDATE_DATE_FORMAT_TOGGLER = "UPDATE_DATE_FORMAT_TOGGLER";

export const FETCH_SAVEDQUERY_BY_ID_START = "FETCH_SAVEDQUERY_BY_ID_START";
export const FETCH_SAVEDQUERY_BY_ID_SUCCESS = "FETCH_SAVEDQUERY_BY_ID_SUCCESS";
export const FETCH_SAVEDQUERY_BY_ID_FAILURE = "FETCH_SAVEDQUERY_BY_ID_FAILURE";

export const CLEAR_SAVED_QUERIES = 'CLEAR_SAVED_QUERIES';